import {ActionTypes} from './applicantActions';
import config from 'config';

const mergeApplicant = (state, applicant) => {
    const applicants = (state.applicants || []).slice();
    const position = applicants.findIndex(c => c.id === applicant.id);
    const newApplicantPosition = position >= 0 ? position : applicants.length;
    applicants[newApplicantPosition] = applicant;
    return applicants;
}

const appendApplicants = (state, applicantsToMerge) => {
    const applicants = (state.applicants || []).concat(applicantsToMerge || []);
    return applicants;
}

const mergeJobs = (state, newJob) => {
    if (state.appliedJobs.has(newJob)) {
        return state.appliedJobs;
    }
    return new Set([...state.appliedJobs, newJob]);
}
export default (state = {appliedJobs: new Set()}, action) => {
    let nextState;
    const {type, payload} = action;
    switch (type) {
    case ActionTypes.SUBMIT_APPLICATION_FORM_ACTION:
        nextState = {...state, applicant: payload};
        break;
    case ActionTypes.FETCH_APPLICANTS_REQUEST:
        nextState = {...state, fetching: true};
        break;
    case ActionTypes.FETCH_APPLICANTS_FAILURE:
        nextState = {...state, fetching: true};
        break;
    case ActionTypes.FETCH_APPLICANTS_SUCCESS: {
        const {applicants = [], last} = payload;
        nextState = {
            ...state,
            fetching: false,
            applicants, last,
            hasMore: applicants.length === config.applicantsPagination,
        };
        break;
    }
    case ActionTypes.FETCH_MORE_APPLICANTS_SUCCESS: {
        const {applicants = [], last} = payload;
        nextState = {
            ...state,
            fetching: false,
            applicants: appendApplicants(state, applicants),
            last,
            hasMore: applicants.length === config.applicantsPagination,
        };
        break;
    }
    case ActionTypes.FILTER_APPLICANTS_RESULTS:
        nextState = {...state, filter: payload};
        break;
    case ActionTypes.APPLICANTS_UPDATE_APPLICANT_REQUEST:
        nextState = {...state, loading: true, justCreated: false};
        break;
    case ActionTypes.SUBMIT_APPLICATION_FORM_REQUEST:
        nextState = {...state, loading: true};
        break;
    case ActionTypes.APPLICANTS_UPDATE_APPLICANT_SUCCESS:
        nextState = {...state, loading: false, justCreated: true, applicants: mergeApplicant(state, payload), selectedApplicant: payload};
        break;
    case ActionTypes.APPLICANTS_UPDATE_APPLICANT_FAILURE:
    case ActionTypes.SUBMIT_APPLICATION_FORM_FAILURE:
        nextState = {...state, loading: false};
        break;
    case ActionTypes.SUBMIT_APPLICATION_FORM_SUCCESS: {
        nextState = {...state, loading: false, applicant: payload.applicant, appliedJobs: mergeJobs(state, payload.jobId)};
        break;
    }
    case ActionTypes.GENERATE_APPLICANTS_REPORT_REQUEST:
        nextState = {...state, loadingFile: true};
        break;
    case ActionTypes.FETCH_MATCHING_APPLICANT_REQUEST:
        nextState = {...state, selectedApplicant: null, loading: true};
        break;
    case ActionTypes.FETCH_MATCHING_APPLICANT_SUCCESS:
        nextState = {...state, selectedApplicant: payload.applicant, loading: false};
        break;
    case ActionTypes.FETCH_MATCHING_APPLICANT_FAILURE:
        nextState = {...state, loading: false};
        break;
    case ActionTypes.GENERATE_APPLICANTS_REPORT_SUCCESS:
    case ActionTypes.GENERATE_APPLICANTS_REPORT_FAILURE:
        nextState = {...state, loadingFile: false};
        break;
    case ActionTypes.REFERRER_APPLICANTS_CLICK:
        nextState = {...state, filter: {referrerIdentifier: payload.identifier}};
        break;
    case ActionTypes.JOB_APPLICANTS_CLICK:
        nextState = {...state, filter: {jobId: payload.id, jobTitle: payload.title}};
        break;
    case ActionTypes.APPLICANTS_LIST_SORT_CHANGED:
        nextState = {...state, sorting: payload[0]};
        break;
    case ActionTypes.FETCH_APPLICANTS_ACTION:
        nextState = {...state, sorting: null};
        break;
    default:
        nextState = state;
    }
    return nextState;
};
