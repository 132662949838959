import Config from 'config';
import { createSelector } from 'reselect';
import {Selectors as CompanySelectors} from 'modules/companies';
import {Selectors as NavigationSelectors} from 'modules/navigation';

export const sliceSelector = state => state.referrer;
export const linksSelector = createSelector(sliceSelector, slice => slice.links || {});
export const referrersSelector = createSelector(sliceSelector, slice => slice.referrers || []);
export const isLoadingLinkSelector = createSelector(sliceSelector, slice => slice.loadingLink);
export const isLoadingSelector = createSelector(sliceSelector, slice => slice.loading);
export const isLoadingFileSelector = createSelector(sliceSelector, slice => slice.loadingFile);
export const isLoadingReferrerSelector = createSelector(sliceSelector, slice => slice.loadingReferrer);
export const isImportFileLoadingSelector = createSelector(sliceSelector, slice => slice.loadingImportFile);
export const isReferrerStatsLoadingSelector = createSelector(sliceSelector, slice => slice.loadingStats);
export const importReferrersStatusSelector = createSelector(sliceSelector, slice => slice.importReferrersStatus || {});
export const lastReferrerSelector = createSelector(sliceSelector, slice => slice.last);
export const hasMoreReferrersSelector = createSelector(sliceSelector, slice => !!slice.hasMore);
export const sortingSelector = createSelector(sliceSelector, slice => slice.sorting || {id: 'dateRangeShares', desc: true});
export const filterSelector = createSelector(sliceSelector, slice => slice.filter || {});
export const errorSelector = createSelector(sliceSelector, slice => slice.error);
export const referrerStatsSelector = createSelector(sliceSelector, slice => slice.referrerStats || {});
export const wasReferrerJustCreatedSelector = createSelector(sliceSelector, slice => slice.justCreated);

export const matchingReferrerLinkSelector = createSelector(NavigationSelectors.referrerQuerySelector, CompanySelectors.matchingCompanyAliasSelector,
    (referrerQuery, companyAlias) => {
    if (referrerQuery) {
        return referrerQuery;
    }
    const referrerByCompany = JSON.parse(localStorage.getItem(Config.referrerKey) || '{}');
    return referrerByCompany[companyAlias];
});

export const matchingReferrerOwnLinkSelector = createSelector(CompanySelectors.matchingCompanyIdSelector,
    (companyId) => {
    const referrerByCompany = JSON.parse(localStorage.getItem(Config.referrerOwnLinks) || '{}');
    return referrerByCompany[companyId] ? referrerByCompany[companyId].link : null;
});

export const referrerOwnLinkSelector = createSelector(linksSelector, CompanySelectors.matchingCompanyIdSelector, 
    (links, companyId) => {
        return companyId && links[companyId] && links[companyId].link;
    }
)

export const currentReffererSelector = createSelector(linksSelector, CompanySelectors.matchingCompanyIdSelector, 
    (links, companyId) => {
        return companyId && links[companyId];
    }
)

export const referrerNameSelector = createSelector(linksSelector, CompanySelectors.matchingCompanyIdSelector, 
    (links, companyId) => {
        return companyId && links[companyId] && links[companyId].name;
    }
)

export const shareableUrlSelector = createSelector(referrerOwnLinkSelector, NavigationSelectors.publicUrlSelector,
    (link, path) => link && `${path}?r=${link}`);

export const applicantShareableUrlSelector = createSelector(matchingReferrerLinkSelector, NavigationSelectors.publicUrlSelector,
    (link, path) => link && `${path}?r=${link}`);

export const showDateRangeViewsSelector = createSelector(filterSelector, filter => filter.dateField === 'lastActive')