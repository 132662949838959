import {createSelector} from "reselect";
import {Selectors as CompaniesSelectors} from 'modules/companies';

export const sliceSelector = state => state.users;
export const usersSelector = createSelector(sliceSelector, slice => slice.users || []);
export const fetchingSelector = createSelector(sliceSelector, slice => !!slice.fetching);
export const loadingSelector = createSelector(sliceSelector, slice => !!slice.loading);
export const lastUserSelector = createSelector(sliceSelector, slice => slice.last);
export const hasMoreUsersSelector = createSelector(sliceSelector, slice => !!slice.hasMore);
export const sortingSelector = createSelector(sliceSelector, slice => slice.sorting || {id: 'createdAt', desc: true});

export const enhancedUsersSelector = createSelector(usersSelector, CompaniesSelectors.companiesSelector,
    (users, companies) => {
        return users.map(a => {
            const company = companies.find(c => c.id === a.companyId);
            if (!company) {
                return a;
            }
            return {...a, companyName: company.name};
        })
    }
);

export const matchingUserIdSelector = createSelector(
    sliceSelector,
    (slice) => {
        return slice.selectedUser;
    }
);

export const matchingUserSelector = createSelector(
    matchingUserIdSelector, usersSelector,
(id, users) => {
        return users.find(c => c.id === id);
    }
);

export const userHasPhoneSelector = createSelector(matchingUserSelector, (usr) => {
    return usr && usr.phone;
});
