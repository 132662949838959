import React from 'react';
import AbstractInput from 'components/Form/AbstractInput';
import InputWrapper from 'components/Form/InputWrapper';
import {Editor as DraftEditor} from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {injectIntl} from 'react-intl';
import defaultMessages from 'i18n/defaultMessages';

const options = [
    'generic.add',
    'generic.cancel',
    'components.controls.blocktype.h3',
    'components.controls.blocktype.normal',
    'components.controls.emoji.emoji',
    'components.controls.history.history',
    'components.controls.history.undo',
    'components.controls.history.redo',
    'components.controls.textalign.textalign',
    'components.controls.textalign.left',
    'components.controls.textalign.center',
    'components.controls.textalign.right',
    'components.controls.textalign.justify',
    'components.controls.inline.bold',
    'components.controls.inline.italic',
    'components.controls.inline.underline',
    'components.controls.inline.strikethrough',
    'components.controls.inline.monospace',
    'components.controls.inline.superscript',
    'components.controls.inline.subscript',
];
class Editor extends AbstractInput {
    getValidProps() {
        const superProps = super.getValidProps();
        const nonInputProps = InputWrapper.getUnusedProps(superProps);
        const {onChange, ...validProps} = nonInputProps;
        return validProps;
    }

    onChange = (editorState) => {
        this.props.onChange(editorState);
    }
    
    getValue = () => {
        const {value} = this.props;
        return value;
    }

    getEditorTranslations = () => {
        const result = options.reduce((aggr, op) => {
            aggr[op] = this.props.intl.formatMessage(defaultMessages[op]);
            return aggr;
        }, {});
        return result;
    }

    render() {
        const validProps = this.getValidProps();
        const value = this.getValue();
        const translations = this.getEditorTranslations();
        return (
            <InputWrapper {...this.props} className="editor" active={this.state.active} column>
                <DraftEditor
                    toolbar={{
                        options: ['blockType', 'history', 'emoji', 'textAlign', 'inline', 'link'],
                        blockType: {
                            inDropdown: false,
                            options: ['Normal', 'H3'],
                        },
                    }}
                    localization={{translations}}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    editorState={value}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={this.onChange}
                    stripPastedStyles
                    {...validProps}
                />
            </InputWrapper>
        );
    }
}

const exported = injectIntl(Editor);

exported.convertToHTML = (editorState) => {
    const htmlResult = draftToHtml(convertToRaw(editorState.getCurrentContent()), undefined, true);
    return htmlResult;
}

exported.convertToEditorState = (value) => {
    if (!value) {
        return null;
    }
    const blocksFromHtml = htmlToDraft(value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
}

export default exported;