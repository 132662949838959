import React from 'react';
import AbstractInput from 'components/Form/AbstractInput';
import InputWrapper from 'components/Form/InputWrapper';

export default class Checkbox extends AbstractInput {
    getValidProps() {
        const superProps = super.getValidProps();
        const validProps = InputWrapper.getUnusedProps(superProps);
        return validProps;
    }

    getValue(e) {
        return e.target.checked;
    }
    render() {
        const {value} = this.props;
        const validProps = this.getValidProps();

        return (
            <InputWrapper {...this.props} className="checkbox" active={this.state.active} hideBorder hideError noStretchContent>
                <input type="checkbox"
                    {...validProps}
                    checked={!!value}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                />
            </InputWrapper>
        );
    }
}
