import { createSelector } from 'reselect';

export const sliceSelector = (state) => state.auth;

export const customerIdSelector = state => state.auth.ten && state.auth.ten;
export const isLoggedInSelector = state => !!state.auth.isLoggedIn;
export const isLoadingSelector = state => !!state.auth.loading;
export const loginFailureMessageSelector = state => state.auth.loginFailureMessage;
export const limitedToCompanySelector = state => state.auth.companyId;
export const loginScreen = state => state.auth.screen;
export const loginProviders = createSelector(sliceSelector, state => state.loginProviders || [])
export const isForgotPasswordSentSelector = state => !!state.auth.forgotPassSent;
export const isResetPasswordConfirmedSelector = state => !!state.auth.resetPassConfirmed;