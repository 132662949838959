import {ActionTypes} from './integrationsActions';

const mergeIntegrations = (state, integration) => {
    const integrations = (state.integrations || []).slice();
    const position = integrations.findIndex(c => c.id === integration.id);
    const newIntegrationPosition = position >= 0 ? position : integrations.length;
    integrations[newIntegrationPosition] = integration;
    return integrations;
}

const appendIntegrations = (state, integrationsToMerge) => {
    const integrations = (state.integrations || []).concat(integrationsToMerge || []);
    return integrations;
}

export default (state = {}, action) => {
    let nextState;
    const {type, payload} = action;
    switch (type) {
    case ActionTypes.FETCH_INTEGRATIONS_REQUEST:
        nextState = {...state, fetching: true, selectedKey: null};
        break;
    case ActionTypes.FETCH_MATCHING_INTEGRATION_REQUEST:
        nextState = {...state, fetching: true};
        break;
    case ActionTypes.FETCH_INTEGRATIONS_SUCCESS: {
        const {integrations = [], last} = payload;
        nextState = {...state, integrations, last, fetching: false};
        break;
    }
    case ActionTypes.FETCH_MORE_INTEGRATIONS_SUCCESS: {
        const {integrations = []} = payload;
        nextState = {...state, fetching: false, integrations: appendIntegrations(state, integrations)};
        break;
    }
    case ActionTypes.FETCH_INTEGRATIONS_FAILURE:
    case ActionTypes.FETCH_MATCHING_INTEGRATION_FAILURE:
        nextState = {...state, fetching: false};
        break;
    case ActionTypes.FETCH_MATCHING_INTEGRATION_SUCCESS: 
        nextState = {...state, fetching: false, integrations: mergeIntegrations(state, payload)};
        break;
    case ActionTypes.SUBMIT_INTEGRATION_FORM_REQUEST:
    case ActionTypes.DELETE_INTEGRATION_REQUEST:
        nextState = {...state, loading: true};
        break;
    case ActionTypes.SUBMIT_INTEGRATION_FORM_SUCCESS:
        nextState = {...state, loading: false, integrations: mergeIntegrations(state, payload), selectedKey: payload.id};
        break;
    case ActionTypes.SUBMIT_INTEGRATION_FORM_FAILURE:
    case ActionTypes.DELETE_INTEGRATION_FAILURE:
        nextState = {...state, loading: false};
        break;
    case ActionTypes.DELETE_INTEGRATION_SUCCESS:
        nextState = {...state, loading: false, integrations: state.integrations.filter(u => u.id !== payload)};
        break;
    case ActionTypes.INTEGRATIONS_LIST_SORT_CHANGED:
        nextState = {...state, sorting: payload[0]};
        break;
    case ActionTypes.FETCH_INTEGRATIONS_ACTION:
        nextState = {...state, sorting: null};
        break;
    default:
        nextState = state;
    }
    return nextState;
};
