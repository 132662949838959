import React, {Component} from 'react';
import classnames from 'classnames';

export default class InputWrapper extends Component {
    static getUnusedProps = (props) => {
        const {required, error, success, label, small, flex, children, stretch,
            active, className, noFocusOnLabelClick, hideBorder, hideLabel, hideError, labelAsDiv,
            column, style, ltr, errorOnFocus, noStretchContent, adaptive, ...validProps} = props;
        return validProps;
    }

    render() {
        const {required, error, success, label, small, flex, children, stretch,
            active, className, noFocusOnLabelClick, hideBorder, hideLabel, hideError, labelAsDiv,
            column, style, ltr, errorOnFocus, noStretchContent, adaptive, id,
        } = this.props;
        const wrapperClasses = classnames('input-wrapper', className, {
            required, 'has-error': !active && error,
            small, flex, stretch, active, adaptive,
            'input-wrapper--noborder': hideBorder,
        });
        const contentClasses = classnames('input-wrapper__content', {
            'input-wrapper__content--column': column,
            'input-wrapper__content--ltr': ltr,
            'input-wrapper__content--noStretch': noStretchContent,
        })
        const LabelComponent = labelAsDiv ? 'div' :'label';
        const errorLabel = (!active || errorOnFocus) && error;
        return (
            <div className={wrapperClasses} style={style}>
                <LabelComponent className="label" htmlFor={id} onClick={e => noFocusOnLabelClick && e.preventDefault()}>
                    {!hideLabel && <span className="label_text">{label}</span>}
                    <div className={contentClasses}>
                        {children}
                    </div>
                </LabelComponent>
                {!hideError && <span className="text text__indication">{errorLabel || success}</span>}
            </div>
        );
    }
}
