const convertMessages = (messagesObj) => {
    return Object.keys(messagesObj).reduce((final, key) => {
        final[key] = {id: key, defaultMessage: messagesObj[key]};
        return final;
    }, {});
}

const messages = {
    'general_add': 'Add',
    'general_cancel': 'Cancel',
    'general_back': 'Cancel',
    'general_delete': 'Delete',
    'general_close': 'Close',
    'general_save': 'Save',
    'general_': ' ',
    'general_true': 'Yes',
    'general_false': 'No',
    'general_submit': 'Submit',
    'general_filter': 'Filter',
    'general_yes': 'Yes',
    'general_no': 'No',
    'general_approve': 'Approve',
    'general_nextTime': 'Maybe next time',
    general_reset: 'Reset',
    general_redacted: '<Redacted>',

    'clickToCopy_copy': 'Copy',
    'clickToCopy_copied': 'Copied',
    'clickToCopy_not_copied': 'ERROR: Not Copied',

    'loading': 'Loading...',
    'login_header': 'Login Form',
    'login_userLabel': 'Username',
    'login_passwordLabel': 'Password',
    'login_mismatch': 'Email and password do not match',
    'login_blockedUser': 'User is blocked. Please contact support',
    'login_failed': 'Oops, an error occurred',
    reset_failed: 'Oops, an error occurred',
    'pass_expired': 'Password expired! Please reset password',
    'login': 'Log in',
    'login_joinUs': 'Don\'t have Linkatch yet?',
    'login_joinUsLink': 'To contact us and for more details',
    login_resetPass_header: 'Reset Password',
    login_forgotPassword: 'Forgot your password?',
    login_emailLabel: 'Email',
    login_newPassLabel: 'New Password',
    resetPassword_submit: 'Reset Password',

    resetPassword__confirmed_title: 'Password changed successfully',
    resetPassword__sent_title: 'If we found an account associated with that username, we\'ve sent password reset instructions to the email address on the account.',
    resetPassword_invalidToken: 'Invalid token, or token expired',
    resetPassword_weakPassword: 'Password too weak. Please try a harder one',
    resetPassword_samePassword: 'Same password, Please choose a new password',

    password_minLength: 'Minimum {count} characters',
    password_oneUpperCase: 'At least one uppercase letter',
    password_oneLowerCase: 'At least one lowercase letter',
    password_oneNumber: 'At least one number',
    password_oneSpecialChar: 'At least one special character',
    'access_unauthorizedToView': 'You do not have access to this page',

    'company_alias': 'Company ID',
    'company_locale': 'Company locale',
    'company_name': 'Company name',
    'company_logo': 'Logo',
    'company_cover': 'Banner',
    'company_coverMobile': 'Banner for mobile',
    'company_website': 'Company website',
    'company_about': 'About us',
    'company_terms': 'This month\'s benefits',
    'company_location': 'Company headquarters',
    'company_contactName': 'Contact\'s name (for internal use)',
    'company_contactPhone': 'Contact\'s phone number (for internal use)',
    'company_contactEmail': 'Contact\'s email address (for internal use)',
    'company_leadEmails': 'Emails for sending leads',
    'company_rIncentive': 'Incentive statement for employee referral',
    'company_aIncentive': 'Incentive statement for candidate',
    'company_referrerWorthTitle': 'Title',
    'company_referrerWorth': 'Why it\'s worth my while',
    'company_numEmployees': 'Number of employees',
    'company_cover_description': 'Drag banner here, or press to select (jpg, png, mp4)',
    'company_coverMobile_description': 'Drag banner here for mobile devices, or press to select (jpg, png, mp4)',
    'company_logo_description': 'Drag company logo here or press to select (jpg, png)',
    'company_newApplicantEmailTitle': 'Email message subject line for a new candidate',
    company_applicantSavePolicy: 'Applicant persistence policy',
    company_applicantSavePolicy_default: 'Default',
    company_applicantSavePolicy_skipCV: 'Skip CV Persistence',
    company_applicantSavePolicy_redactInfo: 'Redact identifiable information',
    'company_termsPageCover': 'Banner',
    'company_termsPageCoverMobile': 'Banner for mobile',
    'company_termsPageCover_description': 'Drag banner here, or press to select (jpg, png, mp4)',
    'company_termsPageCoverMobile_description': 'Drag banner here for mobile devices, or press to select (jpg, png, mp4)',

    'company_referrerMeta': 'Share link preview - employee referral',
    'company_applicantMeta': 'Share link preview - candidate',
    'company_referrerMetaTitle': 'Title',
    'company_referrerMetaDescription': 'Description',
    'company_referrerMetaImg': 'Image',
    'company_referrerMetaImgFB': 'Facebook/LinkedIn Image',
    'company_referrerMetaImg_description': 'Drag image here (jpg, png)',
    'company_referrerMetaImgFB_description': 'Drag image here (jpg, png)',
    'company_applicantMetaTitle': 'Title',
    'company_applicantMetaDescription': 'Description',
    'company_applicantMetaImg': 'Image',
    'company_applicantMetaImgFB': 'Facebook/LinkedIn Image',
    'company_applicantMetaImg_description': 'Drag image here (jpg, png)',
    'company_applicantMetaImgFB_description': 'Drag image here (jpg, png)',
    company_referrerStatsImg_description: 'Drag image here (jpg, png)',
    company_referrerStatsDescription: 'Description',
    company_referrerStatsImg: 'Image',
    'company_generalPositionTitle': 'General Position / Unassigned CV',
    'company_allowGeneralPosition': 'Allow quick resume submission?',
    'company_generalPositionInternalId': 'General Position Internal ID',
    'company_generalPositionEmail': 'Email address for sending CVs',
    'general_position': 'General Position',

    'company_preview_company': 'View jobs',
    'company_site_title': 'Our website',

    'gen_pos_applicant_send_cv_title': "Submit your resume",
    'gen_pos_applicant_send_cv_subtitle': "And we will search for the next job for you",
    'gen_pos_referrer_send_cv_title': "Wish to submit a resume for a friend?",
    'gen_pos_referrer_send_cv_subtitle': "We will search for the next job for them",
    'gen_pos_description1': 'Found a suitable job? It is recommended to apply directly through it.',
    'gen_pos_description2': 'Not sure which job is the most suitable? You can submit your resume here.',
    'gen_pos_description3': 'It is important to fill in the <b class="application-form__stress">candidate\'s</b> details. We will already know that it came through you.',

    company_jobs_bot_title: 'Positions BOT',
    company_jobs_tracking_title: 'Positions Tracking',
    company_jobs_tracking_bot_title: 'Positions Tracking & Bots',
    company_tracking_ga_id: 'Google Analytics Tracking Id',
    company_tracking_fbq_id: 'Facebook Pixel Tracking Id',
    company_tracking_lintrk_id: 'Linkedin Pixel Partner Id',
    company_tracking_lintrk_page_view_id: 'Linkedin Pixel Page View Conversion Id',

    company_nobot_title: 'This company\'s jobs page doensn\'t have a BOT yet. Click here to ask for a BOT for this company.',
    job_nobot_title: 'This position doensn\'t have a BOT yet. Click here to ask for a BOT for this position.',
    bot_request_button: '+ Add BOT',

    bot_requestbot_confirmation_title: 'Are you sure you want to request a bot?',
    bot_requestbot_confirmation_content: 'We will contact you, regarding your request',

    company_bots_title: 'Title',
    company_bots_description: 'Description',
    company_bots_actionTypes: 'Activated At',
    company_bots_html: 'HTML Script',
    company_bots_designUrl: 'Desgin URL',
    company_bots_dataUrl: 'Data URL',
    company_bots_status: 'Status',

    bot_actionType_candidate_pageload : 'Candidate Page Load',
    bot_actionType_employee_pageload : 'Employee Page Load',
    bot_actionType_cv_submitted : 'CV Submitted',
    
    bot_status_draft: 'Draft',
    bot_status_internalReview: 'Internal Review',
    bot_status_suspended: 'Suspended',
    bot_status_live: 'Live',
    bot_status_terminated: 'Terminated',
    bot_status_hidden: 'Hidden',

    'job_links_title': 'Share job',

    'create_job_submit': 'Create job',
    create_job_submit_and_notify: 'Create job & Notify',
    'edit_job_submit': 'Save job',

    'job_id': 'Job ID',
    'job_shareUrlLink': 'Link for distribution to all the employees',
    'job_title': 'Job Title',
    'job_description': 'Job description',
    'job_internalId': 'Job Internal Code (internal ID, optional)',
    'job_image': 'Image',
    'job_requirements': 'Requirements',
    'job_type': 'Job type',
    'job_schedule': 'Job Type',
    'job_location': 'Address',
    job_locationTitle: 'Location Title',
    'job_posted': 'Created',
    'job_salary': 'Salary',
    'job_leadEmails': 'Emails for sending leads',    
    'job_contactName': 'Contact\'s name (for internal use)',
    'job_contactPhone': 'Contact\'s phone number (for internal use)',
    'job_contactEmail': 'Contact\'s email address (for internal use)',
    'job_active': 'This job is active',
    'job_inactive': 'This job is inactive',
    'job_rIncentive': 'Incentive statement for employee referral',
    'job_aIncentive': 'Incentive statement for candidate',
    'job_referrerWorthTitle': 'Title',
    'job_referrerWorth': 'Why it\'s worth my while',
    'job_cover': 'Job banner',
    'job_coverMobile': 'Job banner for mobile',
    'job_order': 'Priority',
    'job_innerRecruiter': 'Recruiter',
    'job_innerRecruiterManager': 'Professional Manager',
    'job_section': 'Department',
    'job_aiInternalDescription': 'Internal Description AI',

    'job_innerFields_title': 'Fields for internal use',

    'job_recruiter_title': 'Let\'s Meet',
    'job_recruiterImg': 'Recruiter\'s photo',
    'job_recruiterName': 'Recruiter\'s name',
    'job_recruiterHeadline': 'Title',
    'job_recruiterDescription': 'Brief description',

    'job_referrerMeta': 'Share link preview - employee referral',
    'job_applicantMeta': 'Share link preview - candidate',
    'job_referrerMetaTitle': 'Title',
    'job_referrerMetaDescription': 'Description',
    'job_referrerMetaImg': 'Image',
    'job_referrerMetaImgFB': 'Facebook/LinkedIn Image',
    'job_referrerMetaImg_description': 'Drag image here (jpg, png)',
    'job_referrerMetaImgFB_description': 'Drag image here (jpg, png)',
    'job_applicantMetaTitle': 'Title',
    'job_applicantMetaDescription': 'Description',
    'job_applicantMetaImg': 'Image',
    'job_applicantMetaImgFB': 'Facebook/LinkedIn Image',
    'job_applicantMetaImg_description': 'Drag image here (jpg, png)',
    'job_applicantMetaImgFB_description': 'Drag image here (jpg, png)',

    'job_cover_description': 'Drag banner here that will replace the company banner for this job (jpg, png, mp4)',
    'job_coverMobile_description': 'Drag banner here that will replace the company banner on mobile devices for this job (jpg, png, mp4)',
    'job_notSpecified': 'Not specified',
    'job_preview_job': 'Preview',

    'job_schedule_full': 'Full-time job',
    'job_schedule_part': 'Part-time job',
    'job_schedule_shifts': 'Shiftwork',
    'job_schedule_freelance': 'Freelance',
    'job_schedule_relocation': 'Relocation',

    job_bot_title: 'Position BOT',
    job_tracking_title: 'Position Tracking',
    job_tracking_bot_title: 'Position Tracking & Bots',
    job_tracking_ga_id: 'Google Analytics 4 Tracking Id',
    job_tracking_fbq_id: 'Facebook Pixel Tracking Id',
    job_tracking_lintrk_id: 'Linkedin Pixel Partner Id',
    job_tracking_lintrk_page_view_id: 'Linkedin Pixel Page View Conversion Id',
    job_tracking_lintrk_cv_submitted_id: 'Linkedin Pixel CV Submitted Conversion Id',

    job_bots_title: 'Title',
    job_bots_description: 'Description',
    job_bots_actionTypes: 'Activated At',
    job_bots_html: 'HTML Script',
    job_bots_designUrl: 'Desgin URL',
    job_bots_dataUrl: 'Data URL',
    job_bots_status: 'Status',

    'jobList_header_order': 'Order',
    'jobList_header_title': 'Job Title',
    'jobList_header_active': 'Active job',
    'jobList_header_createdAt': 'Date created',
    'jobList_header_applicants': 'Candidates',
    'jobList_header_views': 'Views',
    'jobList_header_shares': 'Shares',
    'jobList_header_actions': 'Actions',
    'jobList_header_innerRecruiter': 'Recruiter',
    'jobList_header_innerRecruiterManager': 'Recruitment manager',
    'jobList_header_section': 'Department',
    'jobList_header_internalId': 'Internal Code',

    'jobList_botData': 'Bot Data',
    'jobList_sendWaLabel': 'Send Whatsapp',
    'jobList_sendWaTitle': 'Send Message on Whatsapp',
    'jobList_sendLabel': 'Send',
    'jobList_promoteLabel': 'Push',
    'jobList_pushTitle': 'Push job',
    'jobList_pushConfirmationBold': 'Are you sure that you want to request Push "{name}" Job?',
    'jobList_pushConfirmationReg': '{customerName} will push the job in the digital media for you',
    'jobList_pushLabel': 'PUSH!',
    jobList_sendNotification: 'Sent notification',
    jobList_copyJob: 'Copy Job Link',
    jobList_copyAllJob: 'Copy All Jobs Link',
    jobList_showQr: 'Show QR Code',
    jobList_qrcode_title: 'QR Code for {jobTitle}',
    jobList_qrcode_all_jobs_title: 'QR Code for all jobs page',
    jobList_recruitmentMessage: 'Recruitment Team Message',
    jobList_recruitmentMessageDetails: 'Write a free-text message here that will reach all employees',

    jobList_waRecipient: 'Who will receive the message through Whatsapp?',
    jobList_waRecipient_user: 'Myself',
    jobList_waRecipient_referrers: 'All referrers',
    jobList_waRecipient_group: 'Selected Group',
    jobList_waRecipient_choose_group: 'Choose a Group',

    jobsFilter_filter_title: 'Filter Jobs',
    'jobsFilter_job': 'Job Title',
    jobsFilter_title: 'Job Title',
    'jobsFilter_internalId': 'Job internal code',
    'jobsFilter_innerRecruiter': 'Recruiter',
    'jobsFilter_innerRecruiterManager': 'Recruitment manager',
    jobsFilter_section: 'Department',
    jobsFilter_locationTitle: 'Location',
    jobsFilter_freeSearch: 'Free Search',

    'jobList_actions_duplicate': 'Replicate job',

    'jobs_fullInfo_title': 'For full details',
    'job_inactive_title': 'Sorry, this job is no longer active',

    'referrer_internalId': 'Employee no.',
    'referrer_name': 'Full name',
    'referrer_phone': 'Phone',
    'referrer_identifier': 'Phone',
    'referrer_email': 'Email',
    'referrer_section': 'Department',
    'referrer_birthday': 'Birthday',
    'referrer_comment': 'Field 1',
    'referrer_comment2': 'Field 2',
    'referrer_comment3': 'Field 3',
    'referrer_applicants': 'Candidates',
    'referrer_views': 'Views',
    'referrer_shares': 'Shares',
    'referrer_dateRangeApplicants': 'Candidates',
    'referrer_dateRangeViews': 'Views',
    'referrer_dateRangeShares': 'Shares',
    'referrer_gdpr': 'Agree to Terms',
    'referrer_createdAt': 'Registration Date',
    'referrer_lastActive': 'Recently active',
    'referrer_activityDate': 'Activity Date',

    'create_new_referrer': 'Create a new employee',
    'error_referrerAlreadyExists': 'An employee with the present identifier already exists',

    'group_groupName': 'Name',
    'group_createdAt': 'Date created',
    'group_count': 'Referrers',
    'group_actions': 'Actions',
    'group_referrer': 'Name',
    'group_phone': 'Phone',
    'group_email': 'E-Mail',
    'group_action_edit': 'Edit Group',
    'group_action_remove': 'Remove Group',
    'group_action_referrers': 'Manage Referrers',
    'group_action_create': 'Create Group',
    'group_action_referrer_remove': 'Remove Referrer from Group',
    'group_modal_title_create': 'Create New Group',
    'group_modal_title_edit': 'Edit Group',
    'group_deleteConfirmationTitle': 'Delete {name}',
    'group_deleteConfirmationBold': 'Are you sure you want to delete {name} group and all of its referrers?',
    'group_deleteConfirmationReg': 'This group will be permanently deleted',

    //menu
    'menu_menuItem_companies': 'Companies',
    'menu_menuItem_referrers': 'Employees',
    'menu_menuItem_referrer_groups': 'Groups',
    'menu_menuItem_details': 'Company details',
    'menu_menuItem_newCompany': 'Add company',
    menu_menuItem_apiKeys: 'Api Keys',
    menu_menuItem_general: 'General',
    menu_menuItem_notifications: 'Notifications',
    menu_menuItem_notifHistory: 'Notifications History',
    menu_menuItem_cmpSettings: 'Company Settings',
    menu_menuItem_cmpAdminSettings: 'Admin Settings',
    menu_menuItem_cmpIntegrations: 'Integrations',
    'menu_menuItem_users': 'User management',
    'menu_menuItem_userList': 'List of users',
    'menu_menuItem_newJob': 'New job',
    'menu_menuItem_applicants': 'Candidates',
    'menu_menuItem_dashboard': 'Dashboard',
    'menu_menuItem_companyJobs': 'Jobs',
    'menu_menuItem_newUser': 'New user',
    'menu_menuItem_allUsers': 'All users',
    'menu_menuItem_settings': 'Settings',
    'menu_menuItem_logout': 'Log out',
    'companyNotFound': 'Company not found.',

    'navigation_addNewJob': 'Add new job',

    'shareJobForm_title': 'Enter your details. a special link will be created for you to share with your friends',
    'shareJobForm_identifier': 'Phone',
    'shareJobForm_identifier_phone': 'Phone',
    'shareJobForm_identifier_email': 'Company Email',
    'shareJobForm_name': 'Full name',
    'shareJobForm_gdpr': 'I agree to receiving messages under the {terms}',
    'shareJobForm_gdpr-link': 'terms',
    'shareJobForm_submitLabel': 'Create a unique link',

    'generatedLink_name': 'Hi {name} ',
    'generatedLink_linkMessage': 'Select how you want to share the job',
    'generatedLink_linkMessage_jobs': 'Share our jobs',
    generatedLink_greeting_back: 'welcome back! ',

    'applicantsFilter_submit': 'Filter',
    'applicantsFilter_referrerIdentifier': 'Employee Identifier',
    'applicantsFilter_date': 'Date',
    'applicantsFilter_companyId': 'Company',
    'applicantsFilter_name': 'Applicant Name',
    'applicantsFilter_jobTitle': 'Job Title',
    'applicantsFilter_status': 'Status',
    
    'applicant_status_new': 'New',
    'applicant_status_irrelevant': 'Not applicable',
    'applicant_status_recruited': 'Hired',
    'applicant_status_inProgress': 'In process',
    applicant_status_interviewScheduled: 'Interview scheduled',
    applicant_status_interviewed: 'Interviewed',
    'applicant_status_withdrawed': 'Withdrawal of Application',

    applicant_application_name: 'Full name',
    applicant_application_phone: 'Phone',
    applicant_application_email: 'Email',
    applicant_application_profileURL: 'Link to my LinkedIn profile',
    applicant_application_cv: 'Resume',
    applicant_application_aboutMe: 'Candidate information',
    applicant_application_gdpr: 'Terms screens',

    'applicant_name': 'Full name',
    'applicant_referrerId': 'Employee referral',
    'applicant_companyId': 'Company',
    'applicant_jobId': 'Job no.',
    'applicant_status': 'Status',
    'applicant_email': 'Email',
    'applicant_phone': 'Phone',
    'applicant_createdAt': 'Date',
    'applicant_cv': 'Resume',
    'applicant_cvFile': 'Resume file',
    'applicant_aboutMe': 'About me',
    'applicant_profileURL': 'Link to my LinkedIn profile',
    'applicant_gdpr': 'Terms screens',
    applicant_botIntegrations: 'Bot Integrations',
    'applicant_recruiterSummary': 'Summary of the recruitement team',
    'applicant_aiSummary': 'AI Integration',

    applicant_redacted_warning: 'Some information was redacted. This setting can be changed in "Company Details"',

    'applicant_save': 'Save Details',

    'applicantList_notFound': 'No candidates yet :(',
    'applicantList_cvFile': 'File',

    'createApplicationForm_showMore': 'Additional options',
    'createApplicationForm_submit': 'Submit candidacy',
    'createApplicationForm_applied': 'Your application has been registered!',
    'createApplicationForm_goodLuck': 'Good luck!',
    'createApplicationForm_moreJobsTitle': 'Have you taken a look at our other jobs?',
    'createApplicationForm_moreJobsSubtitle': 'Maybe there\'s a job that would fit a friend of yours',
    'createApplicationForm_cv': 'Upload your resume (PDF/docx)',
    'createApplicationForm_gdpr': 'I agree to receiving messages under the {terms}',
    'createApplicationForm_gdpr-link': 'terms',

    'user_role_cmpAdmin': 'Company Admin',
    'user_role_admin': 'Admin',

    'users_usersNotFound': 'You have still not created any users',
    userList_header_mfaStatus: 'MFA',
    'userList_header_displayName': 'Name',
    'userList_header_username': 'Email',
    'userList_header_companyId': 'Company',
    'userList_header_role': 'Role',
    'userList_header_updatedAt': 'Last Update',
    'userList_header_phone': 'Phone',
    'userList_header_active': 'Active',
    'userList_deleteTItle': 'Delete user',
    'userList_deleteConfirmationBold': 'Are you sure you want to delete {name}?',
    'userList_deleteConfirmationReg': 'This user will no longer be able to log in to the system',

    'create_user_submit': 'Create user',
    'edit_user_submit': 'Update user',
    'createEditUser_label_displayName': 'Name',
    'createEditUser_label_username': 'Email',
    'createEditUser_label_companyId': 'Company',
    'createEditUser_label_password': 'Password',
    'createEditUser_label_phone': 'Phone',
    'createEditUser_label_mfaStatus': 'Require MFA',
    'createEditUser_label_role': 'Role',
    'createEditUser_label_active': 'Active',

    apiKeysList_header_name: 'Name',
    apiKeysList_header_enabled: 'Enabled',
    apiKeysList_header_companyId: 'Company',
    apiKeysList_deleteTitle: 'Delete Api Key',
    apiKeysList_deleteConfirmationBold: 'Are you sure you want to delete {name}?',
    apiKeysList_deleteConfirmationReg: 'This action cannot be undone',

    create_apiKey_submit: 'Create Api Key',
    edit_apiKey_submit: 'Update Api Key',
    createEditApiKey_label_name: 'Name',
    createEditApiKey_label_email: 'Email',
    createEditApiKey_label_enabled: 'Active',
    createEditApiKey_label_companyId: 'Company',
    createEditApiKey_label_key: 'Access Key',

    'actions_edit': 'Edit',
    'actions_cancel': 'Cancel',
    'actions_create': 'Create',

    'fileUpload_fileSelected': 'Selected file',
    'imageUpload_replace': 'Drag another image (or press) to replace image',
    'imageUpload_prefered': 'Recommended size: {preferedDimentions} (up to {maxSize})',

    'form_requiredField': 'Required field',
    'form_unsavedChanges': 'There are changes that you haven\'t saved Do you want to leave this page?',

    'contact_title': 'Contact us and Notifications',
    'incentives_title': 'Incentives',
    referrer_stats_title: 'Referrer Stats',

    settings_title: 'This month\'s benefits and terms of use',
    settings_companyId: 'Company',
    settings_terms: 'Terms',
    settings_reset_title: 'Reset Company',
    settings_reset_subtitle: 'Will delete all jobs, employees, applicants, notifications and associated media and counters',

    'referrer_explanation_title': 'Explanation for employee referral',
    'jobs_link_sub_cmp': 'Jobs page',
    'job_location_and_map_title': 'Job location',
    'company_location_and_map_title': 'Company location',

    'general_details_title': 'General details',

    'validation_errors_present': 'There are errors on the form',
    'validation_required': 'Required field',
    'validation_minLength': 'Minimum of {minLength} characters',
    'validation_exactLength': 'Must be {length} characters long',
    'validation_email': 'Invalid email address',
    'validation_identification_unavailable': 'This ID is already taken',
    'validation_identification_available': 'This ID is free',
    'validation_cv': 'Upload PDF/docx file up to 5MB only',
    'validation_invalid_media_file': 'Invalid file size/ type',
    validation_insecure_pass: 'Insecure password',
    validation_min: 'Minimum is {min}',
    validation_invalidRegex: 'Invalid RegExp',
    validation_invalidPattern: 'Invalid Pattern',
    
    'operation_success': 'Operation successful',
    'operation_failure': 'Operation failed',

    'dashboard_totalJobs': 'Jobs in the system',
    'dashboard_activeJobs': 'Active jobs',
    'dashboard_inactiveJobs': 'Inactive jobs',
    'dashboard_totalCompanies': 'Companies',
    'dashboard_referrers': 'Registered Employees',
    'dashboard_activeEmployees': '% Registered Employees',
    'dashboard_shares': 'Shares',

    'dashboard_column_title': 'Job Title',
    'dashboard_column_views': 'Views',
    'dashboard_column_applicants': 'Candidates',
    'dashboard_column_shares': 'Shares',
    'dashboard_column_name': 'Name',
    'dashboard_column_email': 'Email',
    'dashboard_column_phone': 'Phone',

    'dashboard_title_totalViews': 'Views',
    'dashboard_title_totalApplicants': 'Candidates',

    'admin_exportCSV': 'Export to CSV',
    
    'referrerWorth_title': 'Why is it worth my while?',
    'return_to_alljobs': 'Check more jobs',
    'our_jobs': 'OUR JOBS >',

    // draftjs
    // Generic
    'generic.add': 'Add',
    'generic.cancel': 'Cancel',
    // BlockType
    'components.controls.blocktype.h3': 'Title',
    'components.controls.blocktype.normal': 'Regular',
    // Emoji
    'components.controls.emoji.emoji': 'Emoji',
    // History
    'components.controls.history.history': 'History',
    'components.controls.history.undo': 'Cancel',
    'components.controls.history.redo': 'Back',
    // TextAlign
    'components.controls.textalign.textalign': 'Text alignment',
    'components.controls.textalign.left': 'Left align',
    'components.controls.textalign.center': 'Center',
    'components.controls.textalign.right': 'Right align',
    'components.controls.textalign.justify': 'Justified',
    // Inline
    'components.controls.inline.bold': 'Bold',
    'components.controls.inline.italic': 'Italics',
    'components.controls.inline.underline': 'Underline',
    'components.controls.inline.strikethrough': 'Strikethrough',
    'components.controls.inline.monospace': 'Even spacing',
    'components.controls.inline.superscript': 'Upper text',
    'components.controls.inline.subscript': 'Lower text',

    //react-dates
    'date_startDate': 'From date',
    'date_endDate': 'To date',
    'date_startTime': 'From time',
    'date_endTime': 'To time',

    'shareableLinks_fb': 'Facebook',
    'shareableLinks_ln': 'LinkedIn',
    'shareableLinks_wa': 'WhatsApp',
    'shareableLinks_url': 'Copy link',
    'shareableLinks_coppied': 'Copied',
    'shareableLinks_manualCopy': 'To copy, press CTRL+C',

    'unsubscribe_message': 'Are you sure you would like to stop receiving messages?',
    'unsubscribe': 'Unsubscribe',

    'footer_terms': 'Terms',
    'footer_rights': 'All rights reserved to PRO-MAN',
    'footer_developed': 'Development: SCOTTY',
    
    'cropModal_title': 'Crop image',
    'cropModal_leaveUncropped': 'Do not crop',
    'cropModal_crop': 'Crop image',
    'cropModal_explain': 'Drag the image to the desired location. Use wheel to zoom',
    'cropModal_notSupported': 'Video cutting is not supported',
    'cropModal_previewWeb': 'This is how the image will appear on a computer:',
    'cropModal_previewMobile': 'This is how the image will appear on a phone:',
    'cropModal_previews_title': 'Previews',

    'sharesList_header_referrer': 'Employee',
    'sharesList_header_date': 'Date',
    'sharesList_header_share': 'Type of share',
    'sharesList_header_job': 'Job shared',
    'sharesList_share_wa': 'WhatsApp',
    'sharesList_share_fb': 'Facebook',
    'sharesList_share_ln': 'LinkedIn',
    'sharesList_share_url': 'Copy link',
    'sharesList_jobListShare': '<Jobs page>',

    'importCsv_title': 'Import employees from csv file',
    'importCsv_fileUploadField': 'Drag Excel/CSV file of employees here',
    'importCsv_fileUploadExample': 'Sample csv file',
    'importCsv_advanced_toggle': 'Advanced settings',
    'importCsv_status_success': 'Import from Date {date} was successful.',
    'importCsv_status_inProgress': 'Import from Date {date} is in process',
    'importCsv_status_failure': 'Import from Date {date} failed.',
    'importCsv_importInProgress': 'Importing of employees has started. This may take several minutes...',
    'importCsv_failureReason_invalidIdentifier': 'Employee identifier in line {line} is not valid (phone/email regex)',
    'importCsv_failureReason_invalidEmail': 'The "Email" field in line {line} is not valid.',
    'importCsv_failureReason_invalidGdpr': 'The "I agree to the terms" field in line {line} is not valid. (Enter true/false/TRUE/FALSE)',
    'importCsv_failureReason_invalidBirthday': 'The "Birthday" field in line {line} is not valid. (Enter in the format 27/10/2001)',
    'importCsv_failureReason_contactAdmin': 'Contact your system administrator.',
    'importCsv_overrideWarning': 'Note: importing a file of employees deletes the current table of employees in the system.',
    'importCsv_ignoreFirstLine': 'Ignore first line',

    'referrersFilter_findReferrer': 'Find an employee',
    'referrersFilter_segmentReferrers': 'Filter employees for exporting',
    'referrersFilter_fieldChooser_title': 'Date field',

    'template_companyName': 'Company name',
    'template_jobInternalId': 'Job ID',
    'template_jobTitle': 'Job title',
    'template_referrerIdentifier': 'Employee Identifier',
    'template_referrerName': 'Employee Name',
    'template_applicantName': 'Applicant Name',

    'locale_en': 'English',
    'locale_he': 'Hebrew',

    'not_supported': 'The management panel is not available in Internet Explorer Use a more advanced web browser',

    notifications_enabled: 'Notifications enabled',
    notifications_disabled: 'Notifications disabled',
    notifications_whatsapp_status_title: 'WhatsApp notifications for employees about new candidates',
    notifications_whatsapp_new_emp_title: 'WhatsApp notifications for new employees',
    notifications_status_title: 'Global Push Notifications status',
    notifications_disabled_warning: 'Your employees do not get notified of new jobs and referrees! Want in? - Contact us!',
    notifications_quota_warning: 'It seems like your notification bank has depleted. Contact us to recharge!',
    
    notifications_email_templates_title: 'Email Notifications',
    notif_template_newApplicantEmail_title: 'Employee New Referral Email Notification',
    'templates.newApplicantEmail.config.imageHeader': 'Email header',

    notif_amountLeft: 'Amount left',
    notif_monthlyBank: 'Monthly pack',
    notif_monthlyBank_explain: 'Every 1st of the month, notification balance increases back to your Monthly Bank',
    notifications_templates_title: 'Push notifications templates',
    notif_template_newJob_title: 'New job template',
    notif_template_newApplicant_title: 'New applicant template',
    notif_template_title: 'Notification title template',
    notif_template_body: 'Notificaiton body template',
    notif_amountWaLeft: 'Amount whatsapps left',

    create_notif_title: 'Notification title',
    create_notif_body: 'Notification body',
    create_notif_img: 'Notification image',
    create_notif_approximate: 'Notification will be sent to approximately {number} employees',
    create_notif_disabled: 'Notification is disabled. Please enable in Notifications menu',

    send_job_notification: 'Send job notification',
    notif_request_register_title: 'Get notified when someone you refer applies or new jobs are listed!',
    notif_revoke: 'Revoke notification',
    notif_revoked: 'Notifications revoked',
    notif_cantSendNotification: 'You don\'t have notifications. Want it? contact us',

    notifHistory_header_type: 'Type',
    notifHistory_header_title: 'Title',
    notifHistory_header_createdAt: 'Created',
    notifHistory_header_received: 'Sent',
    notifHistory_header_opened: 'Opened',

    notif_type_newJob: 'New Job',
    notif_type_newApplicant: 'New Applicant',

    referrersList_disableRevokeNotifTitle: 'Revoke notification',
    referrersList_disableRevokeNotifBold: 'Are you sure you want to disable notifications for employee {name}?',
    referrersList_disableRevokeNotifReg: 'This action cannot be undone',
    referrersList_revokeLabel: 'Revoke Notification',

    previewRatio_fb: 'Facebook',
    previewRatio_wa: 'Whatsapp',

    mfa_scanQRCode: 'Please scan the QR code in {authenticator} and insert the code shown in the app',
    mfa_openAppAndInsertCode: 'Open {authenticator} and insert the presented code',
    mfa_codeLabel: 'Code in Authenticator',
    mfa_invalidCodeError: 'Invalid Code, please try again',

    image_preview_clear_cache: 'After any change of images please clear the cache on your device in order to verify that you will see the change. <a class="link" href="https://linkatch.com/SupportCenter/Clear-Cache.html" target="_blank">You can see instructions on this link</a>',

    confirmation_modal_insert_text: 'In order to perform this operation, please enter "{text}" in the box below',
    settings_reset_confirm_title: 'Reset Company',
    settings_reset_confirm_contentBold: 'Are you sure you want to reset company {name}?',
    settings_reset_confirm_contentReg: 'This action will delete all jobs, employees, applicants, notifications and associated media and counters, and cannot be undone',

    company_admin_settings_title: 'Company Admin Settings',
    company_admin_settings_limits_title: 'Account Limits',
    company_admin_settings_activeJobsLimit: 'Active Jobs Limit (0 = no limit)',
    company_admin_settings_jobs_ai_title: 'Applicants AI Feedback',
    applicants_ai_enabled: 'AI Enabled',
    applicants_ai_disabled: 'AI Disabled',

    company_settings_title: 'Company Settings',
    company_settings_list_values_department_title: 'Department Values',
    company_settings_list_values_location_title: 'Location Values',

    company_settings_list_values_selector_label: 'Select a value list',
    company_settings_list_values_selector_department: 'Department value list',
    company_settings_list_values_selector_location: 'Location value list',

    company_settings_identifier_section_title: 'Employee identifier',
    company_settings_referrerConfig_nameLabel: 'Name Label',
    company_settings_identifierType: 'Identifier Type',
    company_settings_referrerConfig_identifierLabel: 'Identifier Label',
    company_settings_referrerConfig_identifierRegex: 'Identifier Regex',
    company_settings_referrerConfig_identifierRegexErrorMessage: 'Identifier Regex Error Message',
    
    identifierType_email: 'Email',
    identifierType_phone: 'Phone',

    toaster_activeJobsLimitReached: 'Active jobs limit reached. Disable another job, or reach support for a limit increase',
    toaster_contact_support: 'Contact Support with incident id:',

    form_values_list_new_placeholder: 'New value',
    form_values_list_new_button: 'Add',
    form_values_list_duplicate_error: 'List already contains this value',

    referrer_stats_modal_title: 'Your stats',
    referrer_stats_modal_welcome: 'Hey {name},',
    referrer_stats_my_settings: "My Settings",
    referrer_stats_modal_default_description: 'Here are your stats so far. You can check your stats for different period by change the following dropdown, or you can logout by clicking on the log out button below',
    referrer_stats_modal_period: 'Timeframe',
    referrer_stats_modal_period_allTime: 'All Time',
    referrer_stats_modal_period_lastWeek: 'Last Week',
    referrer_stats_modal_period_lastMonth: 'Last Month',
    referrer_stats_modal_stat_applicants: 'Candidates',
    referrer_stats_modal_stat_shares: 'Shares',
    referrer_stats_modal_stat_views: 'Views',
    referrer_stats_modal_logout: 'Log Out',
    referrer_stats_modal_trigger_desc: 'My Stats',

    integration_kind_label: 'Integration Kind',
    'integration_kind_option_importJobs:comeet': 'Comeet Pull Jobs',
    'integration_kind_option_importJobs:adam': 'Adam Pull Jobs',
    integrattion_modal_create_title: 'Create Integration: {kind}',
    integrattion_modal_edit_title: 'Edit Integration: {kind}',
    'integration_importJobs:comeet_config.employerUID': 'Comeet Employer Id',
    'integration_importJobs:adam_config.token': 'Adam Employer Token',
    'integration_importJobs:adam_config.refferalFilter': 'Filter Code',
    integration_deleteTitle: 'Delete Integration',
    integration_deleteConfirmationBold: 'Are you sure you want to delete integration: "{kind}"?',
    integration_deleteConfirmationReg: 'The integration will be removed and not work anymore',
};

const preparedMessages = convertMessages(messages);
export default preparedMessages;

export const getIntlMessage = (id, intl, values) => {
    let messageVal = messages[id]
    if (!messageVal) {
        console.warn(`Message ${id} not found..`);
        messageVal = id;
    }
    const message = {id, defaultMessage: messageVal}
    return intl ? intl.formatMessage(message, values) : message;
} 