import {Component} from 'react';

export default class AbstructInput extends Component {
    constructor(props) {
        super(props);
        this.state = {active: false, value: this.getInitialValue(props)};
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getFormattedValue = this.getFormattedValue.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            const updatedValue = this.getFormattedValue(this.props.value);
            this.setState({value: updatedValue});
        }
    }

    getInitialValue = (props)  => {
        if (props.value !== null && props.value !== undefined) {
            return props.value;
        }
        if (props.defaultValue !== null && props.defaultValue !== undefined) {
            return props.defaultValue;
        }
        return '';
    }

    getValidProps() {
        const {regex, enforce, ...restProps} = this.props; // eslint-disable-line no-unused-vars
        return restProps;
    }

    getValue(e) {
        return e.target.value;
    }

    getFormattedValue(value) {
        const {enforce} = this.props;

        let regex;
        switch (enforce) {
        case 'numeric':
            regex = /[^\d]/g;
            break;
        case 'int':
            regex = /^0.+|[^\d]/g;
            break;
        // TODO: Needs work:
        // case 'numericFloat':
        //     regex = /^([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/g;
        //     break;
        case 'alphaNumeric':
            regex = /[^\w-]/g;
            break;
        case 'hourmin':
            regex = /[^\d:]/g;
            break;
        default:
            return value;
        }
        const nonNull = (value === undefined || value === null) ? '' : value;
        const finalValue = String(nonNull).replace(regex, '') || '';
        if (enforce === 'int' && finalValue) {
            return parseInt(finalValue);
        }
        return finalValue;
    }

    handleFocus(e) {
        this.setState({active: true});
        const {onFocus} = this.props;
        if (onFocus) {
            onFocus(e);
        }
    }

    handleBlur(e) {
        this.setState({active: false});
        const {onBlur} = this.props;
        if (onBlur) {
            onBlur(e);
        }
    }

    handleChange(e) {
        const {onChange} = this.props;
        const value = this.getValue(e);
        const formattedValue = this.getFormattedValue(value);

        this.setState({value: formattedValue});
        if (onChange) {
            onChange(e, formattedValue);
        }
    }
}
