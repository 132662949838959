import classNames from 'classnames';
import Flex from 'components/Flex';
import { memo } from 'react';
import { useDrag, useDrop } from 'react-dnd';

export const DraggableItem = memo(function DraggableItem({ id, className, moveCard, item, findCard, renderItemContent, itemActions}) {
	const originalIndex = findCard(id).index;
	const [{ isDragging }, drag, preview] = useDrag(() => ({
		type: 'card',
		item: { id, originalIndex },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
		end: (item, monitor) => {
			const { id: droppedId, originalIndex } = item;
			const didDrop = monitor.didDrop();
			if (!didDrop) {
				moveCard(droppedId, originalIndex);
			}
		},
	}), [id, originalIndex, moveCard]);
	const [, drop] = useDrop(() => ({
		accept: 'card',
		hover({ id: draggedId }) {
			if (draggedId !== id) {
				const { index: overIndex } = findCard(id);
				moveCard(draggedId, overIndex);
			}
		},
	}), [findCard, moveCard]);
	const opacity = isDragging ? 0.5 : 1;
	return (
		<div
			ref={preview}
			className={classNames('drag-list__item', className)}
			style={{opacity}}
		>
			<Flex horizontal gap={30} stretch>
				<Flex stretch>
					{renderItemContent(item)}
				</Flex>
				<Flex horizontal gap={10} className="item-actions">
					{itemActions.map((ia, index) => (
						<button
							className={ia.className}
							key={index}
							type="button"
							style={{cursor: 'pointer'}}
							title={ia.label}
							aria-label={ia.label}
							onClick={() => ia.onClick(item)}
						>
							<i className={ia.icon}></i>
						</button>
					))}
					<span className='handle-wrapper' ref={(node) => drag(drop(node))}>
						<button
							className="handle-btn"
							title="Drag"
							aria-label="Drag"
						>
							<i className="fas fa-grip-lines"></i>
						</button>
					</span>
				</Flex>
			</Flex>
		</div>
	);
});
