import {createActionTypes, createActionCreators} from 'utils/reduxUtils';
import queryString from 'query-string';

const typesArray = [
    'LOGIN_ACTION',
    'LOGIN_REQUEST',
    'LOGIN_SUCCESS',
    'LOGIN_FAILURE',

    'LOGOUT_ACTION',
    'LOGOUT_SUCCESS',

    'LOGIN_COMPANY_SELECTED',
    'LOGIN_PROVIDERS_REQUEST',
    'LOGIN_PROVIDERS_SUCCESS',
    'LOGIN_PROVIDERS_FAILURE',

    'ACTIVITY_DETECTED',

    'LOGIN_MFA_REQUIRED',
    'LOGIN_MFA_SETUP_REQUIRED',
    'MFA_GET_TOKEN_REQUEST',
    'MFA_GET_TOKEN_SUCCESS',
    'MFA_GET_TOKEN_FAILURE',

    'MFA_CODE_INSERTED',
    'MFA_SEND_CODE_REQUEST',
    'MFA_SEND_CODE_SUCCESS',
    'MFA_SEND_CODE_FAILURE',

    'LOGIN_FORGOT_PASSWORD_ACTION',
    'LOGIN_RESET_PASSWORD_ACTION',

    'LOGIN_FORGOT_PASSWORD_REQUEST',
    'LOGIN_FORGOT_PASSWORD_SUCCESS',
    'LOGIN_FORGOT_PASSWORD_FAILURE',

    'LOGIN_RESET_PASSWORD_REQUEST',
    'LOGIN_RESET_PASSWORD_SUCCESS',
    'LOGIN_RESET_PASSWORD_FAILURE',
    'LOGIN_RESET_PASSWORD_REQUIRED',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);

export const fetchRelevantUserData = async () => {
    const query = queryString.parse(window.location.search, {ignoreQueryPrefix: true})
    if (query.access_token) {
        sessionStorage.setItem('access_token', query.access_token);
        return query.access_token;
    }
    const accessToken = sessionStorage.getItem('access_token');
    return accessToken;
}


export const watchAuthentication = async (dispatch) => {
    const accessToken = await fetchRelevantUserData()
    if (!accessToken) {
        dispatch(Actions.LOGOUT_SUCCESS());
    } else {
        dispatch(Actions.LOGIN_ACTION({accessToken}));
    }
    return accessToken;
};
