import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'FETCH_NOTIFS_HISTORY',
    'FETCH_MORE_NOTIFS_HISTORY',

    'NOTIF_HISTORY_LIST_SORT_CHANGED',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
