import {delay, eventChannel, buffers} from 'redux-saga';
import {replace} from 'react-router-redux';
import {ActionTypes as CmpActionTypes, Selectors as CmpSelectors} from 'modules/companies';
import {put, call, takeLatest, select, race, fork, take, cancel} from 'redux-saga/effects';
import {ActionTypes as AuthActionTypes, Actions as AuthActions, Selectors as AuthSelectors} from 'modules/auth';
import {Selectors as NavSelectors} from 'modules/navigation';
import axios from 'services/axios';
import config from 'config';
import jwtDecode from 'jwt-decode';

// export function* heartbit() {
//     try {
//         yield delay(config.heartbitInitialDelay);
//         yield call(axios.get, 'ping');
//         while (true) {
//             yield delay(config.heartbitPollingTime);
//             yield call(axios.get, 'ping');
//             yield call(axios.get, window.location.origin);
//         }
//     } catch (err) {
//         console.log(err);
//     }
// }

export function* login(action) {
    const {user, password, accessToken} = action.payload;
    yield put(AuthActions.LOGIN_REQUEST());
    try {
        let validAT = accessToken;
        if (!accessToken) {
            const {data} = yield call(axios.post, 'login', {username: user, password});
            if(data.url) {
                yield put(AuthActions.LOGIN_RESET_PASSWORD_REQUIRED());
                return yield put(replace(data.url));
            }
            sessionStorage.setItem('access_token', data.access_token);
            validAT = data.access_token;
        }
        const claims = jwtDecode(validAT);
        if (claims.mfa === 'ns') {
            return yield put(AuthActions.LOGIN_MFA_SETUP_REQUIRED());
        } else if (claims.mfa === 's') {
            return yield put(AuthActions.LOGIN_MFA_REQUIRED());
        }
        yield put(AuthActions.LOGIN_SUCCESS(claims));
    } catch (err) {
        yield put(AuthActions.LOGIN_FAILURE(err));
        // yield call(axios.post, 'loginFailure', {user, errorCode: err.code});
    }
};

export function* logout(action) {
    const isLoggedIn = yield select(AuthSelectors.isLoggedInSelector);
    if (isLoggedIn) {
        yield race({
            action: call(axios.post, 'logout', action.payload),
            timeout: delay(20)
        });
    }
    sessionStorage.removeItem('access_token')
    yield put(AuthActions.LOGOUT_SUCCESS());
};

function* updateOnMouseOrKeyboardTouch() {
    const channel = eventChannel(emit => {
        document.addEventListener('click', emit);
        document.addEventListener('keydown', emit);
        return () => {
            document.removeEventListener('click', emit);
            document.removeEventListener('keydown', emit);
        }
    }, buffers.dropping(1));
    try {
        while(true) {
            yield delay(config.idleThrottle * 1000);
            yield take(channel);
            yield put(AuthActions.ACTIVITY_DETECTED());
        }
    } finally {
        channel.close();
    }
}

function* initiateMFAFlow() {
    yield put(AuthActions.MFA_GET_TOKEN_REQUEST());
    try {
        const {data} = yield call(axios.post, '/mfa/generateToken');
        yield put(AuthActions.MFA_GET_TOKEN_SUCCESS(data));
    } catch (err) {
        console.error(err);
        yield put(AuthActions.MFA_GET_TOKEN_FAILURE(err));
    }
}

function* validateMFACode(action) {
    yield put(AuthActions.MFA_SEND_CODE_REQUEST());
    try {
        const {code} = action.payload;
        const {data} = yield call(axios.post, '/mfa/verifyCode', {code});
        sessionStorage.setItem('access_token', data.access_token);

        const claims = jwtDecode(data.access_token);

        yield put(AuthActions.MFA_SEND_CODE_SUCCESS(data));
        yield put(AuthActions.LOGIN_SUCCESS(claims));
    } catch (err) {
        console.error(err);
        yield put(AuthActions.MFA_SEND_CODE_FAILURE(err.response ? err.response.data : err));
    }
}

export function* monitorActivity() {
    const task = yield fork(updateOnMouseOrKeyboardTouch);
    yield take(AuthActionTypes.LOGOUT_SUCCESS);
    yield cancel(task);
}

function* fetchCompanyProviders(action) {
    yield put(AuthActions.LOGIN_PROVIDERS_REQUEST());
    try {
        const {company} = action.payload;
        const {data} = yield call(axios.get, `/companies/${company}/loginProviders`);
        yield put(AuthActions.LOGIN_PROVIDERS_SUCCESS(data));
    } catch (err) {
        console.error(err);
        yield put(AuthActions.LOGIN_PROVIDERS_FAILURE(err));
    }
}

function* redirectToAdmin() {
    const pathname = yield select(NavSelectors.pathnameSelector);
    const query = yield select(NavSelectors.querySelector);
    if (query.return) {
        yield put(replace(query.return));
    } else if (pathname === '/') {
        yield put(replace('/admin'));
        
        const companyId = yield select(AuthSelectors.limitedToCompanySelector)
        if (companyId) {
            yield take(CmpActionTypes.FETCH_COMPANIES_SUCCESS)
            const cmps = yield select(CmpSelectors.companiesSelector)
            const company = cmps.find(c => c.id === companyId)
            if (company) {
                yield put(replace(`/admin/companies/${company.alias}`));
            }
        }
    }
}

export function* forgotPassword(action) {
    try {
        const {email} = action.payload;
        yield put(AuthActions.LOGIN_FORGOT_PASSWORD_REQUEST());
        
        yield call(axios.post, `/forgotPassword`, {username: email});
        yield put(AuthActions.LOGIN_FORGOT_PASSWORD_SUCCESS());
    } catch (err) {
        yield put(AuthActions.LOGIN_FORGOT_PASSWORD_FAILURE(err));
    }
};

export function* resetPassword(action) {
    try {
        const {newPass} = action.payload;
        yield put(AuthActions.LOGIN_RESET_PASSWORD_REQUEST());
        const {token, username} = yield select(NavSelectors.resetPassQuerySelector);
        yield call(axios.post, `/resetPassword`, {username, token, password: newPass});
        yield put(AuthActions.LOGIN_RESET_PASSWORD_SUCCESS());
    } catch (err) {
        yield put(AuthActions.LOGIN_RESET_PASSWORD_FAILURE(err));
    }
};

export default [
    takeLatest(AuthActionTypes.LOGIN_ACTION, login),
    takeLatest(AuthActionTypes.LOGOUT_ACTION, logout),
    // takeLatest([AuthActionTypes.LOGOUT_SUCCESS, AuthActionTypes.LOGIN_SUCCESS], heartbit),
    takeLatest(AuthActionTypes.LOGIN_SUCCESS, monitorActivity),
    takeLatest(AuthActionTypes.LOGIN_SUCCESS, redirectToAdmin),
    takeLatest(AuthActionTypes.LOGIN_MFA_SETUP_REQUIRED, initiateMFAFlow),
    takeLatest(AuthActionTypes.MFA_CODE_INSERTED, validateMFACode),
    takeLatest(AuthActionTypes.LOGIN_COMPANY_SELECTED, fetchCompanyProviders),
    takeLatest(AuthActionTypes.LOGIN_FORGOT_PASSWORD_ACTION, forgotPassword),
    takeLatest(AuthActionTypes.LOGIN_RESET_PASSWORD_ACTION, resetPassword),
];
