import { createSelector } from 'reselect';

export const sliceSelector = state => state.group;
export const groupsSelector = createSelector(sliceSelector, slice => slice.groups || []);
export const availableReferrersSelector = createSelector(sliceSelector, slice => slice.available || []);
export const groupReferrersSelector = createSelector(sliceSelector, slice => slice.groupReferrers || []);
export const wasGroupJustCreatedSelector = createSelector(sliceSelector, slice => slice.justCreated);
export const lastGroupSelector = createSelector(sliceSelector, slice => slice.last);
export const hasMoreReferrersSelector = createSelector(sliceSelector, slice => !!slice.hasMore);
export const sortingSelector = createSelector(sliceSelector, slice => slice.sorting || {id: 'createdAt', desc: true});
export const filterSelector = createSelector(sliceSelector, slice => slice.filter || {});
export const errorSelector = createSelector(sliceSelector, slice => slice.error);