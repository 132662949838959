import queryString from 'query-string';
import {ActionTypes as AuthActionTypes} from 'modules/auth';
import {push, replace, LOCATION_CHANGE} from 'react-router-redux';
import {Selectors as NavigationSelectors} from 'modules/navigation';
import Config from 'config';
import {ActionTypes as ApplicantActionTypes} from 'modules/applicant';
import {ActionTypes as JobsActionTypes} from 'modules/companyJobs';
import {ActionTypes as CompaniesActionTypes, Selectors as CompanySelectors} from 'modules/companies';
import {ActionTypes as ReferrerActionTypes} from 'modules/referrer';
import {ActionTypes as NotifActionTypes} from 'modules/notifications';
import {ActionTypes as NotifModalActionTypes} from 'containers/createNotifModal/createNotifModalActions';

const handleQueryUpdates = (store, action, companyLink) => {
    if (!companyLink) {
        return;
    }
    const {search = '', pathname} = action.payload;
    const parsedQuery = queryString.parse(search);
    
    /*if(pathname.startsWith('/r/')) {
        const myself = JSON.parse(localStorage.getItem(Config.referrerOwnLinks) || '{}');
        const referrers = Object.values(myself);
        const referrerKey = referrers.length === 1 ? referrers[0].link : null;
        let updatedPayload = {...action.payload, search: ''};
        if (referrerKey) {
            parsedQuery.r = referrers[0].link;
            const updatedQuery = queryString.stringify(parsedQuery);
            updatedPayload.search = updatedQuery;
        }
        store.dispatch(replace(updatedPayload));
    }  else */if (pathname.startsWith('/companies')) {
        const referrerByCompany = JSON.parse(localStorage.getItem(Config.referrerKey) || '{}');
        if (parsedQuery.r) {
            referrerByCompany[companyLink] = parsedQuery.r;
            localStorage.setItem(Config.referrerKey, JSON.stringify(referrerByCompany));
        } else {
            const referrerKey = referrerByCompany[companyLink];
            if (referrerKey) {
                parsedQuery.r = referrerKey;
                const updatedQuery = queryString.stringify(parsedQuery);
                const updatedPayload = {...action.payload, search: updatedQuery};
                store.dispatch(replace(updatedPayload));
            }
        }
    }
}

export default store => next => action => {
    const result = next(action);

    const {payload, type, meta = {}} = action;

    switch (type) {
    case LOCATION_CHANGE:
        handleQueryUpdates(store, action, CompanySelectors.matchingCompanyAliasSelector(store.getState()));
        break;
    case AuthActionTypes.LOGIN_MFA_REQUIRED:
    case AuthActionTypes.LOGIN_MFA_SETUP_REQUIRED:
    case AuthActionTypes.LOGOUT_SUCCESS: {
        const pathname = NavigationSelectors.pathnameSelector(store.getState());
        if (pathname.startsWith('/admin')) {
            if (process.env.NODE_ENV === 'development') {
                store.dispatch(push(`/?return=${encodeURIComponent(pathname)}`));
            } else {
                store.dispatch(push(`/`));
            }
        }
    }
    break;
    case ApplicantActionTypes.APPLICANTS_MODAL_CLOSING:
    case ApplicantActionTypes.REFERRER_APPLICANTS_CLICK:
    case ApplicantActionTypes.JOB_APPLICANTS_CLICK:
        store.dispatch(push(`/admin/applicants`));
        break;
    case ReferrerActionTypes.CREATE_EDIT_REEFERRER_MODAL_CLOSING: {
        const cmpAlias = CompanySelectors.matchingCompanyAliasSelector(store.getState());
        store.dispatch(push(`/admin/companies/${cmpAlias}/referrers`));
        break;
    }
    case JobsActionTypes.CREATE_JOB_SUCCESS:
        const notifSuffix = meta.sendNotif ? '/notif' : '';
        const company = CompanySelectors.matchingCompanySelector(store.getState());
        const path = `/admin/companies/${company.alias}/jobs/${payload.alias}${notifSuffix}`
        store.dispatch(push(path));
        break;
    case CompaniesActionTypes.ADD_COMPANY_SUCCESS:
        store.dispatch(push(`/admin/companies/${payload.alias}`));
        break;
    case NotifModalActionTypes.CREATE_NOTIF_MODAL_CLOSED: {
        const cmpAlais = CompanySelectors.matchingCompanyAliasSelector(store.getState());
        store.dispatch(push(`/admin/companies/${cmpAlais}/jobs`));
        break;
    }
    case NotifActionTypes.CREATE_JOB_NOTIF_SUCCESS: {
        const cmpAlais = CompanySelectors.matchingCompanyAliasSelector(store.getState());
        store.dispatch(push(`/admin/companies/${cmpAlais}/notif-history`));
        break;
    }
    case AuthActionTypes.LOGIN_COMPANY_SELECTED: {
        if (!payload.skipNavigation) {
            store.dispatch(push(`?company=${payload.company}`));
        }
        break;
    }
    default:
        break;
    }
    return result;
}
