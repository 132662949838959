import axios from 'services/axios';
import {put, call, takeLatest, select, take, all} from 'redux-saga/effects';
import {ActionTypes as AdminActionTypes} from 'containers/admin/adminActions';
import {ActionTypes as CompanyRouteActionTypes} from 'containers/CompanyRoute/companyRouteActions';
import {Actions, ActionTypes as CompaniesActionTypes} from './companiesActions';
import {Selectors as CompaniesSelectors} from 'modules/companies';
import {ActionTypes as NotifAT} from 'modules/notifications/notifsActions';
import {Selectors as NavigationSelectors}  from 'modules/navigation';

function* addCompany(action) {
    yield put(Actions.ADD_COMPANY_REQUEST());
    try {
        const result = yield call(axios.post, 'companies', action.payload);
        yield put(Actions.ADD_COMPANY_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.ADD_COMPANY_FAILURE(err));
    }
}

function* updateCompany(action) {
    yield put(Actions.EDIT_COMPANY_REQUEST());
    const company = yield select(CompaniesSelectors.matchingCompanySelector);
    try {
        const result = yield call(axios.patch, `companies/${company.id}`, action.payload);
        yield put(Actions.EDIT_COMPANY_SUCCESS({...company, ...action.payload, ...result.data}));
    } catch (err) {
        yield put(Actions.EDIT_COMPANY_FAILURE(err));
    }
}

function* updateCompanyList(action) {
    yield put(Actions.COMPANY_LIST_UPDATE_REQUEST());
    const company = yield select(CompaniesSelectors.matchingCompanySelector);
    try {
        const {values, listType} = action.payload;
        const result = yield call(axios.put, `companies/${company.id}/lists/${listType}`, {values});
        yield put(Actions.COMPANY_LIST_UPDATE_SUCCESS({[`${listType}List`]: result.data.items}));
    } catch (err) {
        console.error(err);
        yield put(Actions.COMPANY_LIST_UPDATE_FAILURE(err));
    }
}

function* fetchUserCompanies() {
    yield put(Actions.FETCH_COMPANIES_REQUEST());
    try {
        const response = yield call(axios.get, 'companies');
        
        yield put(Actions.FETCH_COMPANIES_SUCCESS(response.data.items));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_COMPANIES_FAILURE(err));
    }
}

function* fetchMatchingCompany() {
    yield put(Actions.FETCH_MATCHING_COMPANY_REQUEST());
    try {
        const cmpAlias = yield select(CompaniesSelectors.matchingCompanyAliasSelector);
        const cmpRes = yield call(axios.get, `companies/byAlias?alias=${cmpAlias}`)

        yield put(Actions.FETCH_MATCHING_COMPANY_SUCCESS(cmpRes.data));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_MATCHING_COMPANY_FAILURE(err));
    }
}

function* fetchCompanyTerms() {
    try {
        let company = yield select(CompaniesSelectors.matchingCompanySelector);
        if (!company) {
            const action = yield take(CompaniesActionTypes.FETCH_MATCHING_COMPANY_SUCCESS);
            company = action.payload;
        }
        if (!company.termsUrl) {
            return;
        }
        yield put(Actions.FETCH_COMPANY_TERMS_REQUEST());
        const response = yield fetch(company.termsUrl);
        const terms = yield response.text();
        yield put(Actions.FETCH_COMPANY_TERMS_SUCCESS({terms, id: company.id}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_COMPANY_TERMS_FAILURE(err));
    }
}

function* requestBot() {
    yield put(Actions.BOT_REQUEST_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const path = yield select(NavigationSelectors.fullPathSelector);
        yield call(axios.post, `companies/${companyId}/requestBot`, {path});
        yield put(Actions.BOT_REQUEST_SUCCESS());
    } catch (err) {
        console.log(err);
        yield put(Actions.BOT_REQUEST_FAILURE(err));
    }
}

function* resetCompany(action) {
    yield put(Actions.RESET_COMPANY_REQUEST());
    try {
        yield call(axios.post, `companies/${action.payload.companyId}/reset`);
        yield put(Actions.RESET_COMPANY_SUCCESS());
    } catch (err) {
        console.error(err);
        yield put(Actions.RESET_COMPANY_FAILURE(err));
    }
}

function* getCompanyLists() {
    yield put(Actions.FETCH_COMPANY_LISTS_REQUEST());
    try {
        let companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        if (!companyId) {
            const action = yield take(CompaniesActionTypes.FETCH_MATCHING_COMPANY_SUCCESS);
            companyId = action.payload.id;
        }
        const [departmentRes, locationRes] = yield all([
            call(axios.get, `companies/${companyId}/lists/department`),
            call(axios.get, `companies/${companyId}/lists/location`),
        ])
        yield put(Actions.FETCH_COMPANY_LISTS_SUCCESS({
            companyId,
            departmentList: departmentRes.data.items,
            locationList: locationRes.data.items,
        }));
    } catch (err) {
        console.error(err);
        yield put(Actions.FETCH_COMPANY_LISTS_FAILURE(err));
    }
}

export default [
    takeLatest([AdminActionTypes.ADMIN_MOUNTING, CompaniesActionTypes.ADD_COMPANY_SUCCESS], fetchUserCompanies),
    takeLatest(CompaniesActionTypes.ADD_COMPANY_ACTION, addCompany),
    takeLatest([
        CompaniesActionTypes.EDIT_COMPANY_ACTION,
        CompaniesActionTypes.ADMIN_COMPANY_ADMIN_SETTINGS_SUBMITTED,
        CompaniesActionTypes.COMPANY_IDENTIFIER_FIELDS_UPDATED,
    ], updateCompany),
    takeLatest(CompaniesActionTypes.COMPANY_LIST_UPDATED, updateCompanyList),
    takeLatest([
        CompanyRouteActionTypes.ADMIN_COMPANY_ROUTE_MOUNTING,
        CompanyRouteActionTypes.ADMIN_COMPANY_SETTINGS_MOUNTING,
        CompanyRouteActionTypes.ADMIN_COMPANY_ADMIN_SETTINGS_MOUNTING,
        NotifAT.UPDATE_NOTIFS_SETTINGS_SUCCESS,
    ], fetchMatchingCompany, true),
    takeLatest(CompanyRouteActionTypes.PUBLIC_COMPANY_ROUTE_MOUNTING, fetchMatchingCompany),
    takeLatest([
        CompaniesActionTypes.FETCH_COMPANY_TERMS_ACTION,
        CompaniesActionTypes.EDIT_COMPANY_MOUNTING
    ], fetchCompanyTerms),
    takeLatest([
        CompaniesActionTypes.BOT_REQUEST_ACTION,
    ], requestBot),
    takeLatest(CompaniesActionTypes.RESET_COMPANY_ACTION, resetCompany),
    takeLatest([
        CompaniesActionTypes.ADMIN_COMPANY_SETTINGS_MOUNTING,
        CompanyRouteActionTypes.PUBLIC_COMPANY_ROUTE_MOUNTING,
        CompanyRouteActionTypes.ADMIN_COMPANY_ROUTE_MOUNTING,
    ], getCompanyLists),
]