import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'ADD_COMPANY_REQUEST',
    'ADD_COMPANY_SUCCESS',
    'ADD_COMPANY_FAILURE',
    'ADD_COMPANY_ACTION',

    'EDIT_COMPANY_REQUEST',
    'EDIT_COMPANY_SUCCESS',
    'EDIT_COMPANY_FAILURE',
    'EDIT_COMPANY_ACTION',

    'FETCH_COMPANIES_REQUEST',
    'FETCH_COMPANIES_SUCCESS',
    'FETCH_COMPANIES_FAILURE',

    'FETCH_COMPANY_TERMS_REQUEST',
    'FETCH_COMPANY_TERMS_SUCCESS',
    'FETCH_COMPANY_TERMS_FAILURE',

    'FETCH_MATCHING_COMPANY_REQUEST',
    'FETCH_MATCHING_COMPANY_SUCCESS',
    'FETCH_MATCHING_COMPANY_FAILURE',

    'FETCH_COMPANY_TERMS_ACTION',
    'FETCH_COMPANY_TERMS_REQUEST',
    'FETCH_COMPANY_TERMS_SUCCESS',
    'FETCH_COMPANY_TERMS_FAILURE',

    'BOT_REQUEST_ACTION',
    'BOT_REQUEST_REQUEST',
    'BOT_REQUEST_SUCCESS',
    'BOT_REQUEST_FAILURE',

    'EDIT_COMPANY_MOUNTING',
    'ADMIN_COMPANY_SETTINGS_MOUNTING',
    'ADMIN_COMPANY_ADMIN_SETTINGS_MOUNTING',
    'ADMIN_COMPANY_ADMIN_SETTINGS_SUBMITTED',

    'RESET_COMPANY_ACTION',
    'RESET_COMPANY_REQUEST',
    'RESET_COMPANY_SUCCESS',
    'RESET_COMPANY_FAILURE',

    'COMPANY_LIST_UPDATED',
    'COMPANY_LIST_UPDATE_REQUEST',
    'COMPANY_LIST_UPDATE_SUCCESS',
    'COMPANY_LIST_UPDATE_FAILURE',
    
    'FETCH_COMPANY_LISTS_REQUEST',
    'FETCH_COMPANY_LISTS_SUCCESS',
    'FETCH_COMPANY_LISTS_FAILURE',
    
    'COMPANY_IDENTIFIER_FIELDS_UPDATED',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
