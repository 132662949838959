import React, {Component} from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames';

export default class Modal extends Component {
    renderCloseButton = () => {
        const {onRequestClose, showCloseButton} = this.props;
        if (!showCloseButton) {
            return null;
        }
        return (
            <button className="ReactModal__close" onClick={onRequestClose}><i className="fa fa-times" /></button>
        );
    }

    render = () => {
        const {overlayClassName, className, children, noPadding, hideOverlay, ...rest} = this.props;
        const closeButton = this.renderCloseButton();
        return (
            <ReactModal
                isOpen
                overlayClassName={classnames(' ', overlayClassName, {'ReactModal__Overlay--hidden': hideOverlay})}
                className={classnames(className, ' ', {'ReactModal--noPadding': noPadding})}
                ariaHideApp={false}
                {...rest}
            >
                {closeButton}
                {children}
            </ReactModal>
        );
    }
}
