import axios from 'axios';

const baseURL = `${window.location.origin}/app`;

const axiosInstance = axios.create({baseURL});

axiosInstance.interceptors.request.use(config => {
    const token = sessionStorage.getItem('access_token');
    if (token) {
        config.headers.authorization = `Bearer ${token}`;
    }
    return config;
});
export default axiosInstance;
