import {createSelector} from "reselect";
import {Selectors as CompaniesSelectors} from 'modules/companies';

export const sliceSelector = state => state.apiKeys;
export const apiKeysSelector = createSelector(sliceSelector, slice => slice.apiKeys || []);
export const fetchingSelector = createSelector(sliceSelector, slice => !!slice.fetching);
export const loadingSelector = createSelector(sliceSelector, slice => !!slice.loading);
export const lastApiKeySelector = createSelector(sliceSelector, slice => slice.last);
export const hasMoreApiKeysSelector = createSelector(sliceSelector, slice => !!slice.hasMore);
export const sortingSelector = createSelector(sliceSelector, slice => slice.sorting || {id: 'createdAt', desc: true});

export const enhancedApiKeysSelector = createSelector(apiKeysSelector, CompaniesSelectors.companiesSelector,
    (apiKeys, companies) => {
        return apiKeys.map(a => {
            const company = companies.find(c => c.id === a.companyId);
            if (!company) {
                return a;
            }
            return {...a, companyName: company.name};
        })
    }
);

export const matchingApiKeyIdSelector = createSelector(sliceSelector,
    (slice) => {
        return slice.selectedKey;
    }
);

export const matchingApiKeySelector = createSelector(
    matchingApiKeyIdSelector, apiKeysSelector,
(id, keys) => {
        return keys.find(c => c.id === id);
    }
);
