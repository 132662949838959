import React from 'react';
import AbstractInput from 'components/Form/AbstractInput';

export default class BasicTextInput extends AbstractInput {
    render() {
        const validProps = this.getValidProps();
        const {value} = this.state;
        return (
            <input
                dir="auto"
                type="text"
                className="input input__text"
                {...validProps}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                ref={ref => this.ref = ref}
                value={value}
            />
        );
    }
}
