import config from 'config';

const {firebase} = window;
firebase.initializeApp({
    apiKey: "AIzaSyAg43SszHZ7PyGuizJ-pH8XeGSXFNzUkFU",
    authDomain: "linkatch-eu.firebaseapp.com",
    databaseURL: "https://linkatch-eu.firebaseio.com",
    projectId: "linkatch-eu",
    storageBucket: "linkatch-eu.appspot.com",
    messagingSenderId: "1068004132842",
    appId: "1:1068004132842:web:97e6c0d777c35de3551493",
    measurementId: "G-6VR3VWD0CH"
})
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
if (firebase.messaging.isSupported()) {
    firebase.messaging().usePublicVapidKey(config.fcmVapidKey);
}

export default firebase;
