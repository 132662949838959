import axios from 'services/axios';
import {put, call, select, takeLatest} from 'redux-saga/effects';
import {Selectors as CompaniesSelectors} from 'modules/companies';
import {ActionTypes as GroupActionTypes, Actions as GroupActions, Selectors as GroupSelectors} from 'modules/group'
import config from 'config';

const fetchGroupsLogic = async (companyId, {sorting, startAfter, fetchAll, filter = {}} = {}) => {
    const limit = fetchAll ? 9999 : config.groupsPagination;
    const offset = startAfter || 0;
    
    const orderBy = `group.${sorting.id}`;
    const order = sorting.desc ? 'desc' : 'asc';

    if (filter.id) {
        const response = await axios.get(`/companies/${companyId}/groups/${filter.id}`)
        return {groups: [response.data]}
    }

    const query = {companyId};

    const response = await axios.post(`/companies/${companyId}/groups/query?offset=${offset}&limit=${limit}&orderBy=${orderBy}&order=${order}`, {query})
    const groups = response.data.items;
    const last = offset + response.data.items.length;
    return {groups, last};
}

const fetchGroupReferrersLogic = async (companyId, groupId, {sorting, startAfter, fetchAll, filter = {}} = {}) => {
    const limit = fetchAll ? 9999 : config.groupsPagination;
    const offset = startAfter || 0;
    
    const orderBy = `referrer.name`;
    const order = 'asc';

    const query = {companyId, groupId};

    const response = await axios.post(`/companies/${companyId}/groups/${groupId}/referrers/query?offset=${offset}&limit=${limit}&orderBy=${orderBy}&order=${order}`, {query})
    const data = response.data;
    const last = offset + response.data.items.length;
    return {data, last};
}

function* fetchGroups(action) {
    yield put(GroupActions.FETCH_GROUPS_REQUEST(action.payload));
    try {
        const filter = yield select(GroupSelectors.filterSelector);
        const sorting = yield select(GroupSelectors.sortingSelector);
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const {groups, last} = yield fetchGroupsLogic(companyId, {sorting, filter});
        yield put(GroupActions.FETCH_GROUPS_SUCCESS({groups, last}));
    } catch (err) {
        console.log(err);
        yield put(GroupActions.FETCH_GROUPS_FAILURE(err));
    }
}

function* fetchMoreGroups(action) {
    yield put(GroupActions.FETCH_MORE_GROUPS_REQUEST());
    try {
        const sorting = yield select(GroupSelectors.sortingSelector);
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const startAfter = yield select(GroupSelectors.lastGroupSelector);
        const filter = yield select(GroupSelectors.filterSelector);
        const {groups, last} = yield fetchGroupsLogic(companyId, {sorting, filter, startAfter});
        yield put(GroupActions.FETCH_MORE_GROUPS_SUCCESS({groups, last}));
    } catch (err) {
        console.log(err);
        yield put(GroupActions.FETCH_MORE_GROUPS_FAILURE(err));
    }
}

function* updateGroup(action) {
    const groupId = action.payload.groupId;
    yield put(groupId ? GroupActions.UPDATE_GROUP_REQUEST() : GroupActions.CREATE_GROUP_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        if(groupId) {
            const result = yield call(axios.patch, `/companies/${companyId}/groups/${groupId}`, action.payload.group);
            yield put(GroupActions.CREATE_GROUP_SUCCESS(result.data));
        } else {
            const result = yield call(axios.post, `/companies/${companyId}/groups`, action.payload.group);
            yield put(GroupActions.CREATE_GROUP_SUCCESS(result.data));
        }
    } catch (err) {
        console.log(err);
        yield put(GroupActions.CREATE_GROUP_FAILURE(err));
    }
}

function* fetchGroupReferrers(action) {
    yield put(GroupActions.FETCH_GROUP_REFERRERS_REQUEST(action.payload));
    try {
        const filter = yield select(GroupSelectors.filterSelector);
        const sorting = yield select(GroupSelectors.sortingSelector);
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const {data, last} = yield fetchGroupReferrersLogic(companyId, action.payload.groupId, {sorting, filter, fetchAll:true});
        yield put(GroupActions.FETCH_GROUP_REFERRERS_SUCCESS({groupReferrers: data.items, available: data.available, last}));
    } catch (err) {
        console.log(err);
        yield put(GroupActions.FETCH_GROUP_REFERRERS_FAILURE(err));
    }
}

function* assignReferrers(action) {
    yield put(GroupActions.ASSIGN_REFERRER_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        let data = {...action.payload, companyId};
        const response = yield call(axios.post, `/companies/${companyId}/groups/${action.payload.groupId}/referrers/assign`, data);
        yield put(GroupActions.ASSIGN_REFERRER_SUCCESS(response));
    } catch (err) {
        console.log(err);
        yield put(GroupActions.ASSIGN_REFERRER_FAILURE(err));
    }
}

function* unassignReferrer(action) {
    yield put(GroupActions.DELETE_GROUP_REFERRER_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const response = yield call(axios.delete, `/companies/${companyId}/groups/${action.payload.groupId}/referrers/${action.payload.id}`);
        yield put(GroupActions.DELETE_GROUP_REFERRER_SUCCESS(response));
    } catch (err) {
        console.log(err);
        yield put(GroupActions.DELETE_GROUP_REFERRER_FAILURE(err));
    }
}

function* removeGroup(action) {
    yield put(GroupActions.DELETE_GROUP_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        yield call(axios.delete, `/companies/${companyId}/groups/${action.payload.groupId}`);
        yield put(GroupActions.DELETE_GROUP_SUCCESS({id: action.payload.groupId}));
    } catch (err) {
        console.log(err);
        yield put(GroupActions.DELETE_GROUP_FAILURE(err));
    }
}

export default [
    takeLatest(GroupActionTypes.FETCH_GROUPS_ACTION, fetchGroups),
    takeLatest(GroupActionTypes.FETCH_MORE_GROUPS_ACTION, fetchMoreGroups),
    takeLatest([GroupActionTypes.UPDATE_GROUP_ACTION, GroupActionTypes.CREATE_GROUP_ACTION], updateGroup),
    takeLatest(GroupActionTypes.FETCH_GROUP_REFERRERS_ACTION, fetchGroupReferrers),
    takeLatest(GroupActionTypes.ASSIGN_REFERRER_ACTION, assignReferrers),
    takeLatest(GroupActionTypes.DELETE_GROUP_REFERRER_ACTION, unassignReferrer),
    takeLatest(GroupActionTypes.DELETE_GROUP_ACTION, removeGroup),
];
