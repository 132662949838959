import {takeLatest, select, fork, put, take} from 'redux-saga/effects';
import {Selectors as CompanySelectors, ActionTypes as CompanyActionTypes} from 'modules/companies';
import {Selectors as JobsSelectors, ActionTypes as JobsActionTypes} from 'modules/companyJobs';
import {Actions} from './trackingActions';
import {Selectors as AuthSelectors} from 'modules/auth';
import {getTracker} from './trackingFactory';
import {LOCATION_CHANGE} from 'react-router-redux';
import {ActionTypes as ApplicantActionTypes} from 'modules/applicant';
import _ from 'lodash';
import { Selectors as ApplicantsSelector } from 'modules/applicant';

// eslint-disable-next-line no-unused-vars
function* trackAction(tracker, actionKey, entity) {
    if (!tracker || !tracker.opts) {
        console.warn('missing tracker or tracker opts')
        return;
    }
    const isLoggedIn = yield select(AuthSelectors.isLoggedInSelector);
    if (isLoggedIn && !tracker.opts.trackAdmins) {
        return;
    }
    
    yield put(Actions.TRACKING_ACTION({tracker, action: actionKey}));
    const trackerInstance = getTracker(tracker.name, tracker.opts);
    trackerInstance.track(actionKey);
}

function* trackEntityActions(entity, actionKeys) {
    try {
        if (!entity) {
            console.warn(`Will not track [${actionKeys.join(', ')}]. Entity not found`);
        }
        // tracking
        const {tracking = {}} = entity;
        const trackers = Object.keys(tracking);

        for (let i = 0; i < trackers.length; i++) {
            const trackerName = trackers[i];
            for (let j = 0; j < actionKeys.length; j++) {
                const actionKey = actionKeys[j];
                const tracker = {name: trackerName, opts: tracking[trackerName]}
                yield fork(trackAction, tracker, actionKey, entity);
            }
        }

        // bots
        const bots = _.filter(entity.bots, b => b.status === 'live' && _.intersection(b.actionTypes, actionKeys).length);

        // eslint-disable-next-line no-new-func
        bots.map(b => new Function("self", "'use strict';" + b.html).call(null));
    } catch (err) {
        console.error(err);
    }
}

function* trackCompanyActions(actionKeys) {
    try {
        let company = yield select(CompanySelectors.matchingCompanySelector);
        if (!company) {
            yield take([CompanyActionTypes.FETCH_COMPANIES_SUCCESS, CompanyActionTypes.FETCH_MATCHING_COMPANY_SUCCESS]);
        }
        company = yield select(CompanySelectors.matchingCompanySelector);
        yield fork(trackEntityActions, company, actionKeys);
    } catch (err) {
        console.error(err);
    }
}

function* trackJobActions(actionKeys) {
    try {
        let job = yield select(JobsSelectors.matchingJobSelector);
        if (!job) {
            yield take([JobsActionTypes.FETCH_MATCHING_JOB_SUCCESS]);
        }
        let applicant = yield select(ApplicantsSelector.applicantFormSelector)
        job = yield select(JobsSelectors.matchingJobSelector);
        if(applicant && applicant.id && actionKeys.includes('cv_submitted')) {
            const fname = applicant.name.split(' ')[0];
            const lname = applicant.name.replace(fname, '').trim();
            let params = "?fname="+encodeURI(fname) + "&lname=" + encodeURI(lname) + "&applicantid="+applicant.id + "&phone=" + applicant.phone;
            job.bots.map(b => b.html = b.html.replace(/((http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-]))/, "$1"+params));
        }

        yield fork(trackEntityActions, job, actionKeys);
    } catch (err) {
        console.error(err);
    }
}

function* trackPageView(action) {
    // jobs page load
    if (/^\/r\/companies\/.*\/jobs\/?$/.test(action.payload.pathname)) {
        yield fork(trackCompanyActions, ['init', 'employee_pageload']);
    }
    if (/^\/companies\/.*\/jobs\/?$/.test(action.payload.pathname)) {
        yield fork(trackCompanyActions, ['init', 'candidate_pageload']);
    }
    
    // single job page load
    if (/^\/r\/companies\/.*\/jobs\/.+/.test(action.payload.pathname)) {
        yield fork(trackJobActions, ['init', 'employee_pageload']);
    }
    if (/^\/companies\/.*\/jobs\/.+/.test(action.payload.pathname)) {
        yield fork(trackJobActions, ['init', 'candidate_pageload']);
    }
}

export default [
    takeLatest(LOCATION_CHANGE, trackPageView),
    // takeLatest(ApplicantActionTypes.SUBMIT_APPLICATION_FORM_SUCCESS, trackCompanyActions, ['cv_submitted']),
    takeLatest(ApplicantActionTypes.SUBMIT_APPLICATION_FORM_SUCCESS, trackJobActions, ['cv_submitted']),
]