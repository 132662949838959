import { createSelector } from 'reselect';
import {Selectors as NavigationSelectors} from 'modules/navigation';
import _ from 'lodash';

export const sliceSelector = (state) => state.companies;
export const companiesSelector = (state) => state.companies.companies || [];
export const isLoadingSelector = state => !!state.companies.loading;
export const wasJustCreatedSelector = state => !!state.companies.justCreated;
export const isFetchingDataSelector = state => !!state.companies.fetching;

export const listsSelector = createSelector(sliceSelector, slice => slice.lists);

export const matchingCompanyAliasSelector = createSelector(
    NavigationSelectors.pathnameSelector,
    (pathname) => {
        const matchedRegex = (pathname || '').match(/\/companies\/([^/?]*)/) || [];
        const companyAlias = matchedRegex[1];
        return companyAlias;
    }
);

export const matchingCompanySelector = createSelector(
    matchingCompanyAliasSelector, companiesSelector, listsSelector,
(alias, companies, lists) => {
        const matching = companies.find(c => c.alias === alias);
        if (!matching || !lists || matching.id !== lists.companyId) {
            return matching;
        }

        const onlyLists = _.omit(lists, ['companyId'])
        return {...matching, ...onlyLists};
    }
);

export const matchingCompanyIdSelector = createSelector(
    matchingCompanySelector,
(matchingCompany) => {
        return matchingCompany && matchingCompany.id;
    }
);


export const referrerCompanyUrlSelector = createSelector(matchingCompanyAliasSelector,
    (companyAlias) => {
        return `/r/companies/${companyAlias}/jobs`;
    }
);

export const termsUrlSelector = createSelector(matchingCompanyAliasSelector, NavigationSelectors.isReferrerViewSelector,
    (companyId, isReffererView) => {
        const termsUrl =  `/companies/${companyId}/terms`;
        if (isReffererView) {
            return `/r${termsUrl}`;
        }
        return termsUrl;

    }
)

export const notifEnabledSelector = createSelector(matchingCompanySelector, (cmp) => {
    return !!(cmp && cmp.notifEnabled && cmp.notifLeft > 0);
});

export const publicNotifEnabledSelector = createSelector(matchingCompanySelector, (cmp) => {
    return !!(cmp && cmp.notifEnabled);
});

export const companyIdentifierTypeSelector = createSelector(matchingCompanySelector, (cmp) => {
    return cmp.identifierType;
});

export const companyAllowAiSelector = createSelector(matchingCompanySelector, (cmp) => {
    return cmp.allowJobsAi;
});

export const notifAmountSelector = createSelector(matchingCompanySelector, (cmp) => {
        if (cmp.notifEnabled) {
            return cmp.notifLeft
        }
        return false;
    }
);

