import React from 'react';
import AbstractInput from 'components/Form/AbstractInput';
import InputWrapper from 'components/Form/InputWrapper';
import ReactPlacesAutoComplete, {geocodeByPlaceId} from 'react-places-autocomplete'
import Flex from 'components/Flex';

export default class PlacesAutoComplete extends AbstractInput {
    constructor(props) {
        super(props);
        this.state.autoComplete = '';
    }
    getValidProps() {
        const superProps = super.getValidProps();
        const validProps = InputWrapper.getUnusedProps(superProps);
        return validProps;
    }

    getValue = () => {
        const {value} = this.props;
        const {active, autoComplete} = this.state;
        if (active) {
            return autoComplete;
        }
        return value;
    }

    handleSelect = (place, placeId) => {
        geocodeByPlaceId(placeId)
        .then(results => {
            const firstResult = results[0].geometry.location;
            const geo = {lat: firstResult.lat(), lng: firstResult.lng()};
            this.setState({autoComplete: place});
            const {onChange} = this.props;
            onChange && onChange(place, geo);
        }).catch();
    }

    handleBlur = () => {
        super.handleBlur();
        if (!this.state.autoComplete) {
            this.props.onChange(null, null);
        }
        this.setState({autoComplete: ''});
    }

    handleFocus = () => {
        super.handleFocus();
        this.setState({autoComplete: this.props.value});
    }
    
    renderSuggestion = ({ suggestion }) => {
        
        return (
            <Flex horizontal noWrap>
                <i className="fa fa-map-marker-alt"/>
                <span>{suggestion}</span>
            </Flex>
        )
    }
    render() {
        const value = this.getValue();
        return (
            <InputWrapper {...this.props} className="PlacesAutocomplete" active={this.state.active}>
                <ReactPlacesAutoComplete
                    inputProps={{
                        value,
                        onChange: val => this.setState({autoComplete: val}),
                        onBlur: this.handleBlur,
                        onFocus: this.handleFocus,
                    }}
                    classNames={{
                        root: 'PlacesAutocomplete__root',
                        autocompleteContainer: 'PlacesAutocomplete__container',
                        input: 'input input__text',
                        autocompleteItem: 'PlacesAutocomplete__item'
                    }}
                    onSelect={this.handleSelect}
                    renderSuggestion={this.renderSuggestion}
                />
            </InputWrapper>
        );
    }
}
