import React from 'react';
import Select from 'components/Form/Select';
import _ from 'lodash';

export default class AutoComplete extends React.Component {
    static defaultProps = {
        limit: 5,
    }
    constructor(props) {
        super(props);
        this.debouncedFetchOptions = _.debounce(this.fetchOptions, 1000, {leading: true});
    }
    fetchOptions = async (input) => {
        if (!input) {
            return Promise.resolve({options: []});
        }
        const {query} = this.props;
        const options = await query(input)
        return {options};
    }

    render() {
        return (
            <Select
                cache={false}
                placeholder=""
                {...this.props}
                loadOptions={this.debouncedFetchOptions}
                async
                valueKey="id"
                labelKey={this.props.queryField}
                ignoreCase={false}
            />
        );
    }
}
