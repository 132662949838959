import axios from 'services/axios';
import {put, select, takeLatest, fork} from 'redux-saga/effects';
import {ActionTypes, Actions, Selectors} from 'modules/shares';
import {Selectors as ReferrerSelectors} from 'modules/referrer';
import {Selectors as JobsSelectors} from 'modules/companyJobs';
import {Selectors as CompaniesSelectors} from 'modules/companies';
import {fetchReferrersByIds} from 'modules/referrer/referrerSagas';
import {fetchJobsByIds} from 'modules/companyJobs/companyJobsSagas';
import config from 'config';
import moment from 'moment';

function* fetchSharesReferrers(shares) {
    const referrers = yield select(ReferrerSelectors.referrersSelector);
    const unique = shares.filter((share) => {
        const firstShareReferrerId = shares.find(inner => inner.referrerId === share.referrerId);
        return (firstShareReferrerId === share) && !referrers.find(r => r.id === share.referrerId);
    }).map(s => s.referrerId);
    yield fork(fetchReferrersByIds, unique);
}

function* fetchSharesJobs(shares, companyId) {
    const jobs = yield select(JobsSelectors.companyJobsSelector);
    const unique = shares.filter((share) => {
        const firstShareJobId = shares.filter(s => s.jobId).find(inner => inner.jobId === share.jobId);
        return (firstShareJobId === share) && !jobs.find(r => r.id === share.jobId);
    });
    const jobIds = unique.map(share => share.jobId);
    yield fork(fetchJobsByIds, jobIds);
}

const fetchSharesLogic = async (companyId, filter, fetchAll) => {
    const {startAfter} = filter;

    const limit = fetchAll ? 9999 : config.referrerPagination;
    const offset = startAfter || 0;

    const simpleFilters = ['referrerId', 'jobId'];
    const query = simpleFilters.reduce((builder, key) => {
        if (filter[key]) {
            builder[key] = {op: '=', value: filter[key]}
        }
        return builder;
    }, {companyId});
    
    if (filter.shareId) {
        query.type = {op: '=', value: filter.shareId}
    }
    
    if(filter.date) {
        query.date = {
            op: 'between',
            startVal: filter.date.startDate,
            endVal: filter.date.endDate
        }
    }

    const response = await axios.post(`companies/${companyId}/shares/query?offset=${offset}&limit=${limit}`, {query})
    const shares = response.data.items;
    const last = offset + response.data.items.length;
    return {shares, last};
}

function* fetchShares(filter) {
    yield put(Actions.FETCH_SHARES_REQUEST(filter));
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const filter = yield select(Selectors.queryDataSelector);
        const referrerFilter = yield select(ReferrerSelectors.filterSelector);
        if(referrerFilter.dateField === "activityDate") {
            const {startDate, endDate} = referrerFilter.date || {};
            const startOfStartDate = moment(startDate || moment('1900-01-01'));
            const endOfStartDate = moment(endDate || moment());
            filter.date = {
                startDate: startOfStartDate.toDate(),
                endDate: endOfStartDate.toDate(),
            }
        }
        const {shares, last} = yield fetchSharesLogic(companyId, filter);
        yield fork(fetchSharesReferrers, shares);
        yield fork(fetchSharesJobs, shares, companyId);
        yield put(Actions.FETCH_SHARES_SUCCESS({shares, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_SHARES_FAILURE(err));
    }
}

function* fetchReferrerShares(action) {
    const {referrer, shareId} = action.payload;
    const {id: referrerId} = referrer;
    const filter = {referrerId, shareId};
    yield fork(fetchShares, filter);

}

function* fetchJobShares(action) {
    const {job, shareId} = action.payload;
    const {id: jobId} = job;
    const filter = {jobId, shareId};
    yield fork(fetchShares, filter);
}

function* fetchMoreShares() {
    yield put(Actions.FETCH_MORE_SHARES_REQUEST());
    try {
        const filter = yield select(Selectors.queryDataSelector);
        const lastSnap = yield select(Selectors.lastShareSelector);
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const paginatedFilter = lastSnap ? {...filter, startAfter: lastSnap} : filter;
        const {shares, last} = yield fetchSharesLogic(companyId, paginatedFilter);
        yield fork(fetchSharesReferrers, shares);
        yield fork(fetchSharesJobs, shares, companyId);
        yield put(Actions.FETCH_MORE_SHARES_SUCCESS({shares, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_MORE_SHARES_FAILURE(err));
    }
}

export default [
    takeLatest(ActionTypes.FETCH_JOB_SHARES_ACTION, fetchJobShares),
    takeLatest(ActionTypes.FETCH_REFERRER_SHARES_ACTION, fetchReferrerShares),
    takeLatest(ActionTypes.FETCH_MORE_SHARES_ACTION, fetchMoreShares),
];
