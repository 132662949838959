import axios from 'services/axios';
import {delay} from 'redux-saga';
import {takeLeading} from 'utils/reduxUtils';
import {put, call, select, takeLatest, take, cancel, fork} from 'redux-saga/effects';
import {ActionTypes as ReferrerActionTypes, Actions as ReferrerActions, Selectors as ReferrerSelectors} from 'modules/referrer';
import {Selectors as CompaniesSelectors, ActionTypes as CmpActionTypes} from 'modules/companies';
import {Selectors as JobsSelectors} from 'modules/companyJobs';
import {Selectors as NavigationSelectors} from 'modules/navigation';
import {Selectors as NotifSelectors} from 'modules/notifications';
import {ActionTypes as ReferrerListAT} from 'containers/ReferrersList/referrerListActions';
import firebase from 'services/firebase';
import config from 'config';
import fileSaver from 'file-saver';
import { createCSV, prepareObjectToCsv } from 'utils/transformUtils';
import moment from 'moment';

const formattedDate = (key, date) => {
    if (key === 'birthday') {
        return date.format('YYYY-MM-DD');
    }
    return date.toDate();
};

const fetchReferrersLogic = async (companyId, {sorting, startAfter, fetchAll, filter = {}} = {}) => {
    const limit = fetchAll ? 9999 : config.referrerPagination;
    const offset = startAfter || 0;
    
    let analyticsDateRange = null;
    let orderBy = ['dateRangeViews', 'dateRangeShares', 'dateRangeApplicants'].find(key => sorting.id === key) ? `aggr.${sorting.id}` : `referrer.${sorting.id}`;
    const order = sorting.desc ? 'desc' : 'asc';

    if (filter.id) {
        const response = await axios.get(`referrers/${filter.id}`)
        return {referrers: [response.data]}
    }

    const simpleFilters = ['internalId', 'name', 'identifier', 'email', 'comment', 'comment2', 'comment3', 'section'];
    const query = simpleFilters.reduce((builder, key) => {
        if (filter[key]) {
            builder[key] = {op: 'like', value: `%${filter[key]}%`}
        }
        return builder;
    }, {companyId});

    if ('gdpr' in filter) {
        query.gdpr = {op: '=', value: filter.gdpr}
    }

    const key = filter.dateField && ['createdAt', 'birthday', 'activityDate'].find(key => filter.dateField === key);
    if (key) {
        const {startDate, endDate} = filter.date || {};
        const startOfStartDate = moment(startDate || moment('1900-01-01'));
        const endOfStartDate = moment(endDate || moment());
        const formattedStartDate = formattedDate(key, startOfStartDate);
        const formattedEndDate = formattedDate(key, endOfStartDate);
        if (key === 'activityDate') {
            analyticsDateRange = {startDate: formattedStartDate, endDate: formattedEndDate}
        } else {
            query[key] = {
                op: 'between',
                startVal: formattedStartDate,
                endVal: formattedEndDate,
            }
        }
        // orderBy = `referrer.${key}`;
    }

    const response = await axios.post(`referrers/query?offset=${offset}&limit=${limit}&orderBy=${orderBy}&order=${order}`, {query, analyticsDateRange})
    const referrers = response.data.items;
    const last = offset + response.data.items.length;
    return {referrers, last};
}

export const fetchReferrersByIdsLogic = async (referrerIds) => {
    if (!referrerIds || !referrerIds.length) {return []}
    const query = {
        id: {op: 'in', value: referrerIds}
    }
    const response = await axios.post(`referrers/query?offset=0&limit=${referrerIds.length}`, {query})
    const jobs = response.data.items;
    return jobs;
}

export function* fetchReferrersByIds(referrerId) {
    yield put(ReferrerActions.FETCH_REFERRERS_BY_IDS_REQUEST(referrerId));
    try {
        const referrer = yield fetchReferrersByIdsLogic(referrerId);
        yield put(ReferrerActions.FETCH_REFERRERS_BY_IDS_SUCCESS(referrer));
    } catch (err) {
        console.log(err);
        yield put(ReferrerActions.FETCH_REFERRERS_BY_IDS_FAILURE(err));
    }
}

function* fetchReferrers(action) {
    yield put(ReferrerActions.FETCH_REFERRERS_REQUEST(action.payload));
    try {
        const filter = yield select(ReferrerSelectors.filterSelector);
        const sorting = yield select(ReferrerSelectors.sortingSelector);
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const {referrers, last} = yield fetchReferrersLogic(companyId, {sorting, filter});
        yield put(ReferrerActions.FETCH_REFERRERS_SUCCESS({referrers, last}));
    } catch (err) {
        console.log(err);
        yield put(ReferrerActions.FETCH_REFERRERS_FAILURE(err));
    }
}

function* fetchMoreReferrers(action) {
    yield put(ReferrerActions.FETCH_MORE_REFERRERS_REQUEST());
    try {
        const sorting = yield select(ReferrerSelectors.sortingSelector);
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const startAfter = yield select(ReferrerSelectors.lastReferrerSelector);
        const filter = yield select(ReferrerSelectors.filterSelector);
        const {referrers, last} = yield fetchReferrersLogic(companyId, {sorting, filter, startAfter});
        yield put(ReferrerActions.FETCH_MORE_REFERRERS_SUCCESS({referrers, last}));
    } catch (err) {
        console.log(err);
        yield put(ReferrerActions.FETCH_MORE_REFERRERS_FAILURE(err));
    }
}

export function* generateLink(action) {
    yield put(ReferrerActions.GENERATE_LINK_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const jobId = yield select(JobsSelectors.matchingJobIdSelector);
        const isNotifPermissionGranted = yield select(NotifSelectors.permissionGranted);
        let token = null;
        if (isNotifPermissionGranted) {
            token = yield firebase.messaging().getToken();
        }
        const url = `companies/${companyId}/referrers/generateLink`;
        const payload = {...action.payload, jobId, token}
        const result = yield call(axios.post, url, payload);
        
        yield put(ReferrerActions.GENERATE_LINK_SUCCESS({companyId, ...result.data}));
        window.location.reload();
    } catch (err) {
        console.error(err);
        yield put(ReferrerActions.GENERATE_LINK_FAILURE(err));
    }
};

export function* logoutReferrer(action) {
    try {
        yield put(ReferrerActions.FETCH_REFERRER_STATS_REQUEST());
        const permissionGranted = yield select(NotifSelectors.permissionGranted);
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        if (permissionGranted) {
            const token = yield firebase.messaging().getToken();
            yield firebase.messaging().deleteToken(token)
        }
        const rawLinks = localStorage.getItem(config.referrerOwnLinks) || '{}';
        const links = JSON.parse(rawLinks);
        delete links[companyId];
        localStorage.setItem(config.referrerOwnLinks, JSON.stringify(links));
        window.location.href = "/r" + window.location.pathname;
    } catch (err) {
        console.error(err);
        yield put(ReferrerActions.FETCH_REFERRER_STATS_FAILURE(err));
    }
}

export function* updateGdpr(action) {
    try {
        yield call(axios.post, `referrers/${action.meta.id}/gdpr`, {'gdpr': action.meta.gdpr});
        yield put(ReferrerActions.REFERRER_UPDATE_GDPR_SUCCESS(action.meta.gdpr));
    } catch (err) {
        console.log(err);
    }
}

export function* unsubscribe(action) {
    try {
        yield call(axios.get, `referrers/${action.payload.id}/unsubscribe`);
        yield put(ReferrerActions.REFERRER_UNSUBSCRIBE_SUCCESS());
    } catch (err) {
        console.log(err);
    }
}

export function* generateReferrersReport(action) {
    yield put(ReferrerActions.GENERATE_REFERRERS_REPORT_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const sorting = yield select(ReferrerSelectors.sortingSelector);
        const filter = yield select(ReferrerSelectors.filterSelector);

        const columns = {
            createdAtFormatted: 'Created', name: 'name', identifier: 'identifier',
            internalId: 'Id', email: 'email', section: 'section', birthday: 'birthday', gdpr: 'Accept Terms',
            comment: 'field 1', comment2: 'field 2', comment3: 'field 3', lastActiveFormatted: 'Last Active', 
            dateRangeApplicants: 'Total Applicants', dateRangeViews: 'Total Views', waDateRangeShares: 'Whatsapp Shares', 
            fbDateRangeShares: 'Facebook Shares', lnDateRangeShares: 'Linkedin Shares', urlDateRangeShares: 'Copy Shares', dateRangeShares: 'Total Shares'
        };
        const {referrers} = yield fetchReferrersLogic(companyId, {sorting, filter, fetchAll: true});
        
        const preparedReferrers = referrers.map(prepareObjectToCsv);
        const csv = yield createCSV(preparedReferrers, {columns});
        const currentDate = new Date().toJSON().slice(0,10).split('-').reverse().join('/');
        yield put(ReferrerActions.GENERATE_REFERRERS_REPORT_SUCCESS());
        fileSaver.saveAs(new Blob([csv], {type: 'text/csv;charset=utf-8'}), `referrers-${currentDate}.csv`);
    } catch (err) {
        console.error(err);
        yield put(ReferrerActions.GENERATE_REFERRERS_REPORT_FAILURE(err));
    }
}

export function* logReferrerShare(action) {
    // if (window.Notification) {
    //     const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
    //     console.log(companyId);
    //     var result = yield call(window.Notification.requestPermission);
    //     console.log(result);
    //     window.firebase.messaging().getToken().then(async token => {
    //         console.log(token);
    //         await axios.post(`notifications/register`, {token});

    //     });
    //     // var token = yield call();
    //     // console.log(token);
    //     return;
    // }
    
    const isReferrerView = yield select(NavigationSelectors.isReferrerViewSelector);
    const referrerSelector = isReferrerView ? ReferrerSelectors.referrerOwnLinkSelector : ReferrerSelectors.matchingReferrerLinkSelector;
    const referrerLink = yield select(referrerSelector);
    if (!referrerLink) {
        return;
    }
    const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
    const jobId = yield select(JobsSelectors.matchingJobIdSelector);
    try {
        const payload = {jobId, referrerLink, isReshare: !isReferrerView, type: action.payload};
        yield call(axios.post, `companies/${companyId}/shares`, payload);
        yield delay(1000);
    } catch (err) {
        console.log(err);
    }
}


function* updateOnImportReferrersStatusChange() {
    const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);

    while(true) {
        const {data} = yield call(axios.get, `companies/${companyId}`, {params: {includes: 'importReferrers'}})
        yield put(ReferrerActions.IMPORT_REFERRERS_STATUS_UPDATED(data.importReferrers, {polling: true}))
        yield delay(5000)
    }
}

function* monitorImportReferrersStatus() {
    const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
    if (!companyId) {
        yield take(CmpActionTypes.FETCH_MATCHING_COMPANY_REQUEST);
    }
    const task = yield fork(updateOnImportReferrersStatusChange);
    yield take(ReferrerActionTypes.IMPORT_REFERRERS_FROM_FILE_UNMOUNTED);
    yield cancel(task);
}

export function* importReferrersFromFile(action) {
    yield put(ReferrerActions.IMPORT_REFERRERS_FROM_FILE_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        yield call(axios.post, `companies/${companyId}/referrers/import`, action.payload);
        yield put(ReferrerActions.IMPORT_REFERRERS_FROM_FILE_SUCCESS());
    } catch (err) {
        console.log(err);
        yield put(ReferrerActions.IMPORT_REFERRERS_FROM_FILE_FAILURE(err));
    }
}

function* createNewReferrer(action) {
    yield put(ReferrerActions.CREATE_REFERRER_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const result = yield call(axios.post, `companies/${companyId}/referrers`, action.payload);
        yield put(ReferrerActions.CREATE_REFERRER_SUCCESS(result.data));
    } catch (err) {
        console.log(err);
        yield put(ReferrerActions.CREATE_REFERRER_FAILURE(err));
    }
}

function* revokeReferrerTokens(action) {
    yield put(ReferrerActions.REFERRER_REVOKE_NOTIF_REQUEST());
    try {
        const {id: referrerId} = action.payload;
        const result = yield call(axios.post, `referrers/${referrerId}/revokeNotif`);
        yield put(ReferrerActions.REFERRER_REVOKE_NOTIF_SUCCESS(result.data));
    } catch (err) {
        console.log(err);
        yield put(ReferrerActions.REFERRER_REVOKE_NOTIF_FAILURE(err));
    }
}

function* fetchReferrerStats(action) {
    yield put(ReferrerActions.FETCH_REFERRER_STATS_REQUEST());
    try {
        const {referrer, period} = action.payload;
        console.log(action.payload);
        const result = yield call(axios.get, `referrers/${referrer.id}/stats?period=${period}`);
        yield put(ReferrerActions.FETCH_REFERRER_STATS_SUCCESS(result.data));
    } catch (err) {
        console.log(err);
        yield put(ReferrerActions.FETCH_REFERRER_STATS_FAILURE(err));
    }
}

export default [
    takeLeading(ReferrerActionTypes.LINK_IS_SHARED, logReferrerShare),
    takeLatest(ReferrerActionTypes.GENERATE_LINK_ACTION, generateLink),
    takeLatest([
        ReferrerActionTypes.FETCH_REFERRERS_ACTION,
        ReferrerActionTypes.FILTER_REFERRER_LIST,
        ReferrerListAT.REF_LIST_SORT_CHANGED,
    ], fetchReferrers),
    takeLatest(ReferrerActionTypes.FETCH_MORE_REFERRERS_ACTION, fetchMoreReferrers),
    takeLatest(ReferrerActionTypes.GENERATE_REFERRERS_REPORT, generateReferrersReport),
    takeLatest(ReferrerActionTypes.IMPORT_REFERRERS_FROM_FILE_ACTION, importReferrersFromFile),
    takeLatest(ReferrerActionTypes.IMPORT_REFERRERS_FROM_FILE_MOUNTED, monitorImportReferrersStatus),
    takeLatest(ReferrerActionTypes.CREATE_REFERRER_ACTION, createNewReferrer),
    takeLatest(ReferrerActionTypes.REFERRER_STATS_FILTER_CHANGED, fetchReferrerStats),
    takeLatest(ReferrerActionTypes.REFERRER_LOGOUT_ACTION, logoutReferrer),
    takeLatest(ReferrerActionTypes.REFERRER_UPDATE_GDPR_ACTION, updateGdpr),
    takeLatest(ReferrerActionTypes.REFERRER_UNSUBSCRIBE_ACTION, unsubscribe),
    takeLatest(ReferrerListAT.REF_LIST_REVOKE_NOTIF_CLICK, revokeReferrerTokens),
];
