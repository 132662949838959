import React, { Component } from 'react';
import BasicTextInput from 'components/Form/BasicTextInput';
import BasicTextArea from 'components/Form/BasicTextArea';
import InputWrapper from 'components/Form/InputWrapper';
import Flex from 'components/Flex';
import classnames from 'classnames';

export default class TemplateBuilder extends Component {
    getValidProps() {
        const {actions, multiline, ...validProps} = InputWrapper.getUnusedProps(this.props);
        return validProps;
    }
    addTemplate = (e, template) => {
        e.preventDefault();
        const {inputRef} = this;
        if (!inputRef) {
            return;
        }
        var caretPos = inputRef.state.active ? inputRef.ref.selectionStart : inputRef.state.value.length;
    
        var front = (inputRef.ref.value).substring(0, caretPos);
        var back = (inputRef.ref.value).substring(inputRef.ref.selectionEnd, inputRef.ref.value.length);
        const newValue = `${front}{{${template}}}${back}`;
        this.inputRef.handleChange({target: {value: newValue}});
    }

    renderActions = () => {
        const {actions} = this.props;
        if (!actions || !actions.length) {
            return null;
        }
        const actionElements = actions.map(action => (
            <button
                key={action.template}
                className="TemplateBuilder__action"
                type="button"
                onMouseDown={e => e.preventDefault()}
                onClick={(e) => this.addTemplate(e, action.template)}>
                    {action.label}
            </button>
        ))
        return (
            <Flex horizontal className="TemplateBuilder__actions">
                {actionElements}
            </Flex>
        );
    }

    render() {
        const {className, multiline} = this.props;
        const actions = this.renderActions();
        const Input = multiline ? BasicTextArea : BasicTextInput;
        const classes = classnames('TemplateBuilder', className);
        const inputProps = this.getValidProps()
        return (
            <InputWrapper {...this.props} className={classes} active={this.inputRef?.active} hideBorder column>
                {actions}
                <Input ref={ref => this.inputRef = ref} {...inputProps} />
            </InputWrapper>
        );
    }
}
