import React from 'react';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import InputWrapper from 'components/Form/InputWrapper';
import AbstractInput from 'components/Form/AbstractInput';
import config from 'config';
import defaultMessages from 'i18n/defaultMessages';
import { FormattedMessage } from 'react-intl';
import { formatBytes } from 'utils/transformUtils';

export default class FileUpload extends AbstractInput {
    static defaultProps = {
        accept: config.SUPPORTED_FILES,
    }

    getValidProps() {
        const superProps = super.getValidProps();
        const validProps = InputWrapper.getUnusedProps(superProps);
        const {showCancelButton, preferedDimentions, renderContent, 
            replaceText, cover, renderPlaceholder, ...restProps
        } = validProps; // eslint-disable-line no-unused-vars
        return restProps;
    }

    handleFileChange = (acceptedFiles, rejectedFiles) => {
        const acceptedFile = acceptedFiles[0];
        if (!acceptedFile) {
            const rejectedFile = rejectedFiles[0];
            return this.props.onSelect(acceptedFile, acceptedFile, rejectedFile);
        }
        const reader = new FileReader();
        reader.onloadend = () => {
            this.props.onSelect(reader.result, acceptedFile)
        };
        reader.readAsDataURL(acceptedFile);
    }

    onCancelClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.props.onSelect(null);
    }

    renderContent = (value) => {
        const name = value.name || <FormattedMessage {...defaultMessages.fileUpload_fileSelected} />;
        const size = value.size && formatBytes(value.size);
        return (
            <div className="file-upload__preview-content">
                <span className="file-upload__preview-text">
                    <i className="fa fa-file-alt file-upload__preview-icon" />
                    <span className="file-upload__preview-name" title={value.name}>{name}</span>
                </span>
                {size && <span className="file-upload__preview-size" title={value.size}>{size}</span>}
            </div>
        );
    }

    renderCancelButton = () => {
        if (!this.props.showCancelButton) {
            return null;
        }
        return (
            <button className="file-upload__remove" onClick={this.onCancelClick}>
                <i className="fa fa-minus-circle"/>
            </button>
        );
    }

    renderPlaceholder = () => {
        const {description} = this.props;        
        return (
            <React.Fragment>
                <div className="file-upload__text">
                    {description}
                </div>
            </React.Fragment>
        );
    }

    getDropzoneContent() {
        const {value, renderContent = this.renderContent, replaceText, renderPlaceholder = this.renderPlaceholder} = this.props;
        if (value) {
            return (
                <div className="file-upload__preview-wrapper">
                    {renderContent(value)}
                    <div className="file-upload__preview-hover">
                        {replaceText}
                        {this.renderCancelButton()}
                    </div>
                </div>
            )
        }
        return (
            <div className="file-upload__placeholder-wrapper">
                {renderPlaceholder()}
            </div>
        );
    }

    render() {
        const {error, value} = this.props;
        const validProps = this.getValidProps();
        const dropZoneClasses = classnames('file-upload', {error, 'file-upload--withValue': value});
        return (
            <InputWrapper {...this.props} active={this.state.active} hideBorder labelAsDiv>
                <Dropzone
                    {...validProps}
                    className={dropZoneClasses}
                    activeClassName="active"
                    rejectClassName="reject"
                    onDrop={this.handleFileChange}
                    multiple={false}
                >
                    {this.getDropzoneContent()}
                </Dropzone>
            </InputWrapper>
        );
    }
}
