import _ from 'lodash';

export default class GA4Tracker {
    constructor(config) {
        this.trackingId = config.id;
        this.config = config;
        this.name = this.constructor.name;

        if (!this.trackingId) {
            throw new Error(`${this.name}: Missing id`);
        }
    }

    track(actionKey) {
        if (!window.gtag) {
            return console.log(`${this.name}: Missing window.gtag`);
        }
        console.log(`${this.name}: Tracking ${actionKey}`)
        const {category, action, label} = _.get(this.config, `actions.${actionKey}.event`, {});
        switch (actionKey) {
        case 'init':
            window.gtag('config', this.trackingId, { 'send_page_view': false });
            break;
        case 'employee_pageload':
        case 'candidate_pageload':
            window.gtag('event', action || 'page_view', {send_to: this.trackingId})
            break;
        case 'cv_submitted':
            window.gtag('event', action || 'cv_submitted', {
                send_to: this.trackingId,
                event_category: category || 'application_form',
                event_label: label || 'linkatch',
            })
            break;
        default:
            console.error(`${this.name}: Invalid actionKey '${actionKey}'`)
        }
    }
}