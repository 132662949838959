import React, {Component} from 'react';
import Messages from 'i18n/defaultMessages';
import SimpleTable from 'components/SimpleTable';
import Loader from 'components/Loader';
import ElevatedBox from 'components/ElevatedBox';
import ConfirmationModal from 'components/ConfirmationModal';
import classnames from 'classnames';
import { formatDate } from 'utils/transformUtils';
import Helmet from 'react-helmet';

export default class UsersList extends Component {
    state = {showDeleteModal: false}
    componentDidMount() {
        this.props.onMounting();
    }

    getColumnHeader = (name) => {
        const {intl} = this.props;
        const title = intl.formatMessage(Messages[`userList_header_${name}`])
        return {title, id: name};
    }

    closeModal = () => {
        this.setState({showDeleteModal: false, user: null});        
    }

    openModal = (user) => {
        if(user.role === "admin") {
            return;
        }
        this.setState({showDeleteModal: true, user})
    }

    handleUserDelete = () => {
        const {user} = this.state;
        this.props.onDeleteUser(user);
        this.closeModal();
    }

    renderMfaColumn = (data) => {
        return data.mfaStatus ? <i className="fas fa-key mfa-icon" /> : '';
    }
    
    renderActive = (data) => {
        const className = data.active ? 'fas fa-check-circle' : 'fas fa-times-circle';
        const classes = classnames('admin-jobs-list__active', className);
        return (<i className={classes} />)
    }

    renderCompanyName = (data) => {
        return data.companyName || '';
    }

    renderRole = (data) => {
        if (!data.role) {
            return '';
        }
        const {formatMessage} = this.props.intl;
        return formatMessage(Messages[`user_role_${data.role}`]);
    }

    renderDate = (data) => {
        return formatDate(data.updatedAt);
    }

    renderTable = () => {
        const {onUserClick, hasMore, loadMore} = this.props;
        const columns = [
            {...this.getColumnHeader('mfaStatus'), accessor: this.renderMfaColumn, width: 70},
            this.getColumnHeader('displayName'),
            this.getColumnHeader('username'),
            this.getColumnHeader('phone'),
            {...this.getColumnHeader('companyId'), accessor: this.renderCompanyName},
            {...this.getColumnHeader('role'), accessor: this.renderRole},
            {...this.getColumnHeader('updatedAt'), accessor: this.renderDate, width: 90},
            {...this.getColumnHeader('active'), accessor: this.renderActive, width: 70},
        ];

    return (
        <SimpleTable
            sortable
            onSortedChange={this.props.onSortedChange}
            onRowClick={onUserClick}
            showDelete
            onRowDelete={user => this.openModal(user)}
            columns={columns}
            rows={this.props.users}
            hasMore={hasMore}
            loadMore={loadMore}
        />
        );
    }
    render = () => {
        const {loading, intl} = this.props;
        const {showDeleteModal, user} = this.state;
        const usersTable = this.renderTable();
        return (
            <ElevatedBox className="users-list">
                <Helmet><title>{intl.formatMessage(Messages.menu_menuItem_users)}</title></Helmet>
                <Loader loading={loading} inline />
                {usersTable}
                {showDeleteModal && <ConfirmationModal
                    title={intl.formatMessage(Messages.userList_deleteTItle)}
                    contentBold={intl.formatMessage(Messages.userList_deleteConfirmationBold, {name: user.displayName})}
                    contentReg={intl.formatMessage(Messages.userList_deleteConfirmationReg)}
                    primaryLabel={intl.formatMessage(Messages.general_delete)}
                    secondaryLabel={intl.formatMessage(Messages.general_cancel)}
                    onPrimaryClick={this.handleUserDelete}
                    onSecondaryClick={this.closeModal}
                />}
            </ElevatedBox>
        );
    }
}
