import {ActionTypes} from './statsActions';

export default (state = {}, action) => {
    let nextState;
    const {type, payload} = action;
    switch (type) {
    case ActionTypes.FETCH_STATS_REQUEST:
    case ActionTypes.FETCH_COMPANY_STATS_REQUEST:
        nextState = {...state, stats: undefined, loading: true};
        break;
    case ActionTypes.FETCH_STATS_SUCCESS:
    case ActionTypes.FETCH_COMPANY_STATS_SUCCESS:
        nextState = {...state, loading: false, stats: payload};
        break;
    case ActionTypes.FETCH_STATS_FAILURE:
    case ActionTypes.FETCH_COMPANY_STATS_FAILURE:
        nextState = {...state, loading: false};
        break;
    default:
        nextState = state;
    }
    return nextState;
};
