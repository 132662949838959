import React from 'react';
import classnames from 'classnames';

export default function Button({primary, danger, link, disabled, text, children, className, hint, ...restProps}) {
    const classes = classnames('button', className, {
        'button--primary': primary,
        'button--danger': danger,
        'button--link': link,
    })
    const hintProps = hint ? {'data-gen': true, 'data-gen-title': hint} : {};
    return (
        <button className={classes} {...restProps} disabled={disabled} {...hintProps}>
            {text || children}
        </button>
    )
}
