import React from 'react';
import BasicTextArea from 'components/Form/BasicTextArea';
import InputWrapper from 'components/Form/InputWrapper';

export default class TextArea extends BasicTextArea {
    getValidProps() {
        const superProps = super.getValidProps();
        const validProps = InputWrapper.getUnusedProps(superProps);
        return validProps;
    }

    render() {
        const input = super.render();
        return (
            <InputWrapper {...this.props} active={this.state.active}>
                {input}
            </InputWrapper>
        );
    }
}
