import {injectIntl} from 'react-intl';
import { connect as reduxConnect } from 'react-redux';
import { fork, take, call } from 'redux-saga/effects';

export function createActionCreator(type) {
    return (payload, meta) => {
        const action = {type};
        if (typeof payload !== 'undefined') {
            action.payload = payload;
        }

        if (typeof meta !== 'undefined') {
            action.meta = meta;
        }

        if (payload instanceof Error) {
            action.error = true;
        }

        return action;
    };
}

export function createActionCreators(typesArray) {
    return typesArray.reduce((result, type) => {
        result[type] = createActionCreator(type);
        return result;
    }, {})
}

export const createActionTypes = (actionTypes) => (
    actionTypes.reduce((prev, current) => {
        const next = prev;
        next[current] = current;
        return next;
    }, {})
);


export const connect = (...args) => {
    return Component => {
        return reduxConnect(...args)(injectIntl(Component));
    };
};

export const simpleAsyncAction = (asyncAction, requestAction, successAction, failureAction) => {
    return dispatch => {
        requestAction && dispatch(requestAction());
        asyncAction()
        .then(res => {
            successAction && dispatch(successAction(res.data));
        })
        .catch(err => {
            console.log(err);
            failureAction && dispatch(failureAction(err));
        })
    }
}

export const takeLeading = (pattern, saga, ...args) => fork(function* () {
    while(true) {
        const action = yield take(pattern)
        yield call(saga, ...args.concat(action))
    }
});
