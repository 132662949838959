import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'SUBMIT_APPLICATION_FORM_ACTION',
    'SUBMIT_APPLICATION_FORM_REQUEST',
    'SUBMIT_APPLICATION_FORM_SUCCESS',
    'SUBMIT_APPLICATION_FORM_FAILURE',

    'FETCH_APPLICANTS_ACTION',
    'FETCH_APPLICANTS_REQUEST',
    'FETCH_APPLICANTS_SUCCESS',
    'FETCH_APPLICANTS_FAILURE',

    'FETCH_MORE_APPLICANTS_ACTION',
    'FETCH_MORE_APPLICANTS_REQUEST',
    'FETCH_MORE_APPLICANTS_SUCCESS',
    'FETCH_MORE_APPLICANTS_FAILURE',

    'FILTER_APPLICANTS_RESULTS',
    'APPLICANTS_LIST_SORT_CHANGED',

    'APPLICANTS_UPDATE_APPLICANT',
    'APPLICANTS_UPDATE_APPLICANT_REQUEST',
    'APPLICANTS_UPDATE_APPLICANT_SUCCESS',
    'APPLICANTS_UPDATE_APPLICANT_FAILURE',

    'GENERATE_APPLICANTS_REPORT_ACTION',
    'GENERATE_APPLICANTS_REPORT_REQUEST',
    'GENERATE_APPLICANTS_REPORT_SUCCESS',
    'GENERATE_APPLICANTS_REPORT_FAILURE',

    'APPLICANTS_MODAL_CLOSING',
    'APPLICANTS_MODAL_MOUNTED',

    'REFERRER_APPLICANTS_CLICK',
    'JOB_APPLICANTS_CLICK',
    'REFERRER_REVOKE_NOTIF_CLICK',

    'APPLICANT_DOWNLOAD_CV_FILE_ACTION',
    'APPLICANT_DOWNLOAD_CV_FILE_REQUEST',
    'APPLICANT_DOWNLOAD_CV_FILE_SUCCESS',
    'APPLICANT_DOWNLOAD_CV_FILE_FAILURE',

    'FETCH_MATCHING_APPLICANT_REQUEST',
    'FETCH_MATCHING_APPLICANT_SUCCESS',
    'FETCH_MATCHING_APPLICANT_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
