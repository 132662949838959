import {createSelector} from 'reselect';
import queryString from 'query-string';

export const pathnameSelector = state => {
    const statePathname = state.routing.location && state.routing.location.pathname;
    return statePathname || window.location.pathname;
}

export const fullPathSelector = createSelector(pathnameSelector,
(path) => {
    return `${window.location.origin}${path}`;
})

export const publicUrlSelector = createSelector(fullPathSelector, (path) => path.replace(/\/r\//, '/'));

export const isReferrerViewSelector = createSelector(pathnameSelector,
    (path) => {
        return path.startsWith('/r/');
    }
)

export const sliceSelector = state => state.routing;
export const locationSelector = createSelector(sliceSelector, slice => slice.location || {})
export const querySelector = createSelector(locationSelector, location => {
    const {search} = location;
    const parsedQuery = queryString.parse(search);
    return parsedQuery;
})

export const referrerQuerySelector = createSelector(querySelector,
    (query) => {
        return query.r;
    }
)

export const baseSelector = () => {
    return window.location.origin;
}

export const resetPassQuerySelector = state => {
    const {search} = state.routing.location && state.routing.location;
    const {token, username, reason} = queryString.parse(search);
    if (token && username) {
        return {token, username, reason}
    }
}