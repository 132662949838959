import {useState} from 'react';
import classnames from 'classnames';
import DraggableList from 'components/DraggableList';
import {TextInput} from 'components/Form';
import {injectIntl} from 'react-intl';
import { getIntlMessage } from 'i18n/defaultMessages';
import Button from 'components/Button';
import Flex from 'components/Flex';

export default injectIntl(function ValuesList({onChange, className, value, intl}) {
    const classes = classnames('vlist', className);
    const [addInput, setAddInput] = useState('');
    const [error, setError] = useState(null);


    function renderItemContent(item) {
        return <span>{item.value || item.id}</span>;
    }

    function handleItemDelete(item) {
        const newItems = value.filter(v => v !== item);
        onChange(newItems);
    }

    function handleItemAdd() {
        if (value.find(v => v.value === addInput.trim())) {
            setError(intl.formatMessage(getIntlMessage(`form_values_list_duplicate_error`)))
        } else {
            setError(null)
            setAddInput('')
            onChange([{value: addInput, id: Date.now()}, ...value])
        }
    }
    return (
        <Flex className={classes} align="top">    
            <Flex horizontal align="start">
                <TextInput
                    error={error}
                    label={intl.formatMessage(getIntlMessage(`form_values_list_new_placeholder`))}
                    value={addInput}
                    onChange={(e, val) => setAddInput(val)}
                />
                <Button
                    type="button"
                    className="vlist__add-btn"
                    primary
                    disabled={!addInput}
                    onClick={handleItemAdd}
                    text={intl.formatMessage(getIntlMessage(`form_values_list_new_button`))}
                />
            </Flex>
            
            <DraggableList
                onChange={onChange}
                items={value}
                itemActions={[{
                    label: intl.formatMessage(getIntlMessage(`general_delete`)),
                    icon: 'fas fa-trash',
                    onClick: handleItemDelete,
                    className: 'btn-del'
                }]}
                renderItemContent={renderItemContent}
                itemClass="vlist__item"
            />
        </Flex>
    );
})