//import liraries
import React, { Component } from 'react';
import {connect} from 'react-redux';
import hebrewMessages from 'i18n/he';
import {IntlProvider, addLocaleData} from 'react-intl';
import he from 'react-intl/locale-data/he';
import {Selectors as I18nSelectors} from 'modules/i18n';
import moment from 'moment';
import 'moment/locale/he';
import Loader from 'components/Loader';

addLocaleData(he);

// create a component
class HotLoader extends Component {
    state = {loading: false}
    constructor(props) {
        super(props);
        this.updateLocale(props);
    }

    componentDidUpdate(prevProps) {
        const {locale} = prevProps;
        if (this.props.locale !== locale) {
            this.updateLocale(this.props);
        }
        if (locale && locale !== this.props.locale) {
            this.setState({loading: true}, () => setTimeout(() => this.setState({loading: false}), 100));
        }
    }

    updateLocale = (props = this.props) => {
        switch (props.locale) {
            case 'he':
                document.body.dir = 'rtl';
                break;
            default:
                document.body.dir = 'ltr';
            }
            moment.locale(props.locale);
    }

    getMessages = (locale) => {
        switch (locale) {
            case 'he':
                return hebrewMessages;
            default:
                return undefined;
        }
    }

    renderChildren() {
        if (this.state.loading) {
            return <Loader loading />
        }
        return this.props.children;
    }
    render() {
        const {locale = 'en'} = this.props;
        const messages = this.getMessages(locale);
        return (
            <IntlProvider locale={locale} messages={messages} textComponent={React.Fragment}>
                {this.renderChildren()}
            </IntlProvider>
        );
    }
}

const mapStateToProps = state => {
    return {
        locale: I18nSelectors.localeSelector(state),
    };
}
export default connect(mapStateToProps)(HotLoader);
