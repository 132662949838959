import _ from 'lodash';

export default class FQBTracker {
    constructor(config) {
        this.trackingId = config.id;
        this.config = config;
        this.name = this.constructor.name;

        if (!this.trackingId) {
            throw new Error(`${this.name}: Missing id`);
        }
    }

    track(actionKey) {
        if (!window.fbq) {
            return console.log(`${this.name}: Missing window.fbq`);
        }
        console.log(`${this.name}: Tracking ${actionKey}`)

        const eventOverride = _.get(this.config, `actions.${actionKey}.event.name`);
        switch (actionKey) {
        case 'init':
            window.fbq('init', this.trackingId);
            break;
        case 'employee_pageload':
        case 'candidate_pageload':
            window.fbq('trackSingleCustom', this.trackingId, eventOverride || 'PageView');
            break;
        case 'cv_submitted':
            window.fbq('trackSingleCustom', this.trackingId, eventOverride || 'CVSubmitted');
            break;
        default:
            console.error(`${this.name}: Invalid actionKey '${actionKey}'`)
        }
    }
}