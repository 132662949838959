import { createSelector } from 'reselect';
import { pathnameSelector } from 'modules/navigation/navigationSelectors';

export const sliceSelector = (state) => state.i18n;
export const localeSelector = createSelector(sliceSelector, pathnameSelector, (slice, path) => {
    if (path.startsWith('/admin')) {
        return slice.adminLocale;
    }
    return slice.locale;
});

export const isRtlSelector = createSelector(localeSelector, locale => locale === 'he');
