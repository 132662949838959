import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'GENERATE_LINK_ACTION',
    'GENERATE_LINK_REQUEST',
    'GENERATE_LINK_SUCCESS',
    'GENERATE_LINK_FAILURE',

    'FETCH_REFERRERS_ACTION',
    'FETCH_REFERRERS_REQUEST',
    'FETCH_REFERRERS_SUCCESS',
    'FETCH_REFERRERS_FAILURE',

    'FETCH_MORE_REFERRERS_ACTION',
    'FETCH_MORE_REFERRERS_REQUEST',
    'FETCH_MORE_REFERRERS_SUCCESS',
    'FETCH_MORE_REFERRERS_FAILURE',

    'GENERATE_REFERRERS_REPORT',
    'GENERATE_REFERRERS_REPORT_REQUEST',
    'GENERATE_REFERRERS_REPORT_SUCCESS',
    'GENERATE_REFERRERS_REPORT_FAILURE',

    'FETCH_REFERRERS_BY_IDS_REQUEST',
    'FETCH_REFERRERS_BY_IDS_SUCCESS',
    'FETCH_REFERRERS_BY_IDS_FAILURE',

    'REFERRER_REVOKE_NOTIF_REQUEST',
    'REFERRER_REVOKE_NOTIF_SUCCESS',
    'REFERRER_REVOKE_NOTIF_FAILURE',

    'LINK_IS_SHARED',
    
    'IMPORT_REFERRERS_FROM_FILE_ACTION',
    'IMPORT_REFERRERS_FROM_FILE_REQUEST',
    'IMPORT_REFERRERS_FROM_FILE_SUCCESS',
    'IMPORT_REFERRERS_FROM_FILE_FAILURE',
    
    'IMPORT_REFERRERS_FROM_FILE_MOUNTED',
    'IMPORT_REFERRERS_FROM_FILE_UNMOUNTED',
    
    'IMPORT_REFERRERS_STATUS_UPDATED',
    
    'FILTER_REFERRER_LIST',
    
    'CREATE_EDIT_REEFERRER_MODAL_CLOSING',
    
    'CREATE_REFERRER_ACTION',
    'CREATE_REFERRER_REQUEST',
    'CREATE_REFERRER_SUCCESS',
    'CREATE_REFERRER_FAILURE',

    'REFERRER_STATS_FILTER_CHANGED',
    'FETCH_REFERRER_STATS_REQUEST',
    'FETCH_REFERRER_STATS_SUCCESS',
    'FETCH_REFERRER_STATS_FAILURE',

    'REFERRER_LOGOUT_ACTION',
    'FETCH_REFERRER_STATS_REQUEST',
    'FETCH_REFERRER_STATS_SUCCESS',
    'FETCH_REFERRER_STATS_FAILURE',

    'REFERRER_UPDATE_GDPR_ACTION',
    'REFERRER_UPDATE_GDPR_SUCCESS',

    'REFERRER_UNSUBSCRIBE_ACTION',
    'REFERRER_UNSUBSCRIBE_SUCCESS'
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
