import React from 'react';
import {connect} from 'react-redux';
import AbstractInput from 'components/Form/AbstractInput';
import Select from 'react-select';
import {Selectors as I18nSelectors} from 'modules/i18n';

class BasicSelect extends AbstractInput {

    getSelectComponent = () => {
        const {createable, async} = this.props;
        if (createable) {
            if (async) {
                return Select.AsyncCreatable;
            }
            return Select.Creatable;
        }
        if (async) {
            return Select.Async;
        }
        return Select;
    }

    render() {
        const {value, multi, maxSelectedItems} = this.props;
        const validProps = this.getValidProps();

        const maxReached = multi && maxSelectedItems && value.length >= maxSelectedItems;
        const SelectComponent = this.getSelectComponent();
        return (
            <SelectComponent
                {...validProps}
                value={value}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                searchable={!maxReached}
            />
        );
    }
}

export default connect(state => ({rtl: I18nSelectors.isRtlSelector(state)}))(BasicSelect);
