import axios from 'services/axios';
import {put, call, takeLatest, select} from 'redux-saga/effects';
import {Actions, ActionTypes as UsersActionTypes, Selectors as UsersSelectors} from 'modules/users';
import config from 'config';

function* addUser(action) {
    yield put(Actions.ADD_USER_REQUEST());
    try {
        const result = yield call(axios.post, 'users', action.payload);
        yield put(Actions.ADD_USER_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.ADD_USER_FAILURE(err));
    }
}

function* updateUser(action) {
    yield put(Actions.EDIT_USER_REQUEST());
    const id = yield select(UsersSelectors.matchingUserIdSelector);
    try {
        const {displayName, username, password, mfaStatus, phone, active} = action.payload;

        const editUserPayload = {displayName, username, phone, active};
        if (password) {
            editUserPayload.password = password;
        }
        if (mfaStatus !== undefined) {
            editUserPayload.mfaStatus = mfaStatus;
        }
        const result = yield call(axios.patch, `users/${id}`, editUserPayload);
        yield put(Actions.EDIT_USER_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.EDIT_USER_FAILURE(err));
    }
}

function* deleteUser(action) {
    yield put(Actions.DELETE_USER_REQUEST());
    const {id} = action.payload;
    try {
        yield call(axios.delete, `users/${id}`);
        yield put(Actions.DELETE_USER_SUCCESS(id));
    } catch (err) {
        yield put(Actions.DELETE_USER_FAILURE(err));
    }
}


const fetchUsersLogic = async ({startAfter = 0, sorting} = {}) => {
    const orderBy = `user.${sorting.id}`;
    const order = sorting.desc ? 'desc' : 'asc';

    const response = await axios.get(`/users?offset=${startAfter}&limit=${config.usersPagination}&orderBy=${orderBy}&order=${order}`);
    return {users: response.data.items, last: startAfter + response.data.items.length};
}

function* fetchUsers() {
    yield put(Actions.FETCH_USERS_REQUEST());
    try {
        const sorting = yield select(UsersSelectors.sortingSelector);
        const {users, last} = yield call(fetchUsersLogic, {sorting});
        yield put(Actions.FETCH_USERS_SUCCESS({users, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_USERS_FAILURE(err));
    }
}

function* fetchMoreUsers() {
    yield put(Actions.FETCH_MORE_USERS_REQUEST());
    try {
        const sorting = yield select(UsersSelectors.sortingSelector);
        const startAfter = yield select(UsersSelectors.lastUserSelector);
        const {users, last} = yield call(fetchUsersLogic, {startAfter, sorting});
        yield put(Actions.FETCH_MORE_USERS_SUCCESS({users, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_MORE_USERS_FAILURE(err));
    }
}

export default [
    takeLatest([
        UsersActionTypes.FETCH_USERS_ACTION,
        UsersActionTypes.ADD_USER_SUCCESS,
        UsersActionTypes.EDIT_USER_SUCCESS,
        UsersActionTypes.USERS_LIST_SORT_CHANGED,
    ], fetchUsers),
    takeLatest(UsersActionTypes.FETCH_MORE_USERS_ACTION, fetchMoreUsers),
    takeLatest(UsersActionTypes.ADD_USER_ACTION, addUser),
    takeLatest(UsersActionTypes.EDIT_USER_ACTION, updateUser),
    takeLatest(UsersActionTypes.DELETE_USER_ACTION, deleteUser),
]