import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'FETCH_INTEGRATIONS_ACTION',
    'FETCH_INTEGRATIONS_REQUEST',
    'FETCH_INTEGRATIONS_SUCCESS',
    'FETCH_INTEGRATIONS_FAILURE',
    
    'INTEGRATION_FORM_SUBMITTED',
    'SUBMIT_INTEGRATION_FORM_REQUEST',
    'SUBMIT_INTEGRATION_FORM_SUCCESS',
    'SUBMIT_INTEGRATION_FORM_FAILURE',

    'EDIT_INTEGRATION_REQUEST',
    'EDIT_INTEGRATION_SUCCESS',
    'EDIT_INTEGRATION_FAILURE',

    'FETCH_MATCHING_INTEGRATION_ACTION',
    'FETCH_MATCHING_INTEGRATION_REQUEST',
    'FETCH_MATCHING_INTEGRATION_SUCCESS',
    'FETCH_MATCHING_INTEGRATION_FAILURE',

    'DELETE_INTEGRATION_ACTION',
    'DELETE_INTEGRATION_REQUEST',
    'DELETE_INTEGRATION_SUCCESS',
    'DELETE_INTEGRATION_FAILURE',

    'INTEGRATIONS_PAGE_MOUNTED',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
