import axios from 'services/axios';
import {put, call, takeLatest, select} from 'redux-saga/effects';
import {Actions, ActionTypes as ApiKeysActionTypes, Selectors as ApiKeysSelectors} from 'modules/apiKeys';
import config from 'config';

function* addApiKey(action) {
    yield put(Actions.ADD_API_KEY_REQUEST());
    try {
        const {name, enabled, companyId} = action.payload;
        const result = yield call(axios.post, 'apiKeys', {name, enabled, companyId});
        yield put(Actions.ADD_API_KEY_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.ADD_API_KEY_FAILURE(err));
    }
}

function* updateApiKey(action) {
    yield put(Actions.EDIT_API_KEY_REQUEST());
    const apiKey = yield select(ApiKeysSelectors.matchingApiKeySelector);
    try {
        const {name, enabled} = action.payload;
        const editApiKeyPayload = {};
        if (name) {editApiKeyPayload.name = name};
        if (enabled !== undefined) {editApiKeyPayload.enabled = enabled};
        yield call(axios.patch, `apiKeys/${apiKey.id}`, editApiKeyPayload);
        yield put(Actions.EDIT_API_KEY_SUCCESS({...apiKey, ...editApiKeyPayload}));
    } catch (err) {
        yield put(Actions.EDIT_API_KEY_FAILURE(err));
    }
}

function* deleteApiKey(action) {
    yield put(Actions.DELETE_API_KEY_REQUEST());
    const {id} = action.payload;
    try {
        yield call(axios.delete, `apiKeys/${id}`);
        yield put(Actions.DELETE_API_KEY_SUCCESS(id));
    } catch (err) {
        yield put(Actions.DELETE_API_KEY_FAILURE(err));
    }
}


const fetchApiKeysLogic = async ({startAfter = 0, sorting} = {}) => {
    const orderBy = `apiKey.${sorting.id}`;
    const order = sorting.desc ? 'desc' : 'asc';
    const response = await axios.get(`/apiKeys?offset=${startAfter}&limit=${config.apiKeysPagination}&orderBy=${orderBy}&order=${order}`);
    return {apiKeys: response.data.items, last: startAfter + response.data.items.length};
}

function* fetchApiKeys(preventRequestAction) {
    if (!preventRequestAction) {
        yield put(Actions.FETCH_API_KEYS_REQUEST());
    }
    try {
        const sorting = yield select(ApiKeysSelectors.sortingSelector);
        const {apiKeys, last} = yield call(fetchApiKeysLogic, {sorting});
        yield put(Actions.FETCH_API_KEYS_SUCCESS({apiKeys, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_API_KEYS_FAILURE(err));
    }
}

function* fetchMoreApiKeys() {
    yield put(Actions.FETCH_MORE_API_KEYS_REQUEST());
    try {
        const startAfter = yield select(ApiKeysSelectors.lastApiKeySelector);
        const sorting = yield select(ApiKeysSelectors.sortingSelector);
        const {apiKeys, last} = yield call(fetchApiKeysLogic, {startAfter, sorting});
        yield put(Actions.FETCH_MORE_API_KEYS_SUCCESS({apiKeys, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_MORE_API_KEYS_FAILURE(err));
    }
}

function* fetchMatchingApiKey() {
    yield put(Actions.FETCH_MATCHING_API_KEY_REQUEST());
    try {
        const keyId = yield select(ApiKeysSelectors.matchingApiKeyIdSelector);
        const {data} = yield call(axios.get, `apiKeys/${keyId}`)
        yield put(Actions.FETCH_MATCHING_API_KEY_SUCCESS(data));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_MATCHING_API_KEY_FAILURE(err));
    }
}

export default [
    takeLatest([
        ApiKeysActionTypes.FETCH_API_KEYS_ACTION,
        ApiKeysActionTypes.API_KEYS_LIST_SORT_CHANGED,
    ], fetchApiKeys, false),
    takeLatest(ApiKeysActionTypes.ADD_API_KEY_SUCCESS, fetchApiKeys, true),
    takeLatest(ApiKeysActionTypes.FETCH_MORE_API_KEYS_ACTION, fetchMoreApiKeys),
    takeLatest(ApiKeysActionTypes.ADD_API_KEY_ACTION, addApiKey),
    takeLatest(ApiKeysActionTypes.EDIT_API_KEY_ACTION, updateApiKey),
    takeLatest(ApiKeysActionTypes.DELETE_API_KEY_ACTION, deleteApiKey),
    takeLatest(ApiKeysActionTypes.FETCH_MATCHING_API_KEY_ACTION, fetchMatchingApiKey),
]