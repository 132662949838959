import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'CREATE_NOTIF_MODAL_MOUNT',
    'CREATE_NOTIF_MODAL_CLOSED',
    'CREATE_NOTIF_MODAL_SUBMIT',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
