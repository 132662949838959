import React from 'react';
import BasicSelect from 'components/Form/BasicSelect';
import InputWrapper from 'components/Form/InputWrapper';

export default class SelectField extends BasicSelect {
    getValidProps() {
        const superProps = super.getValidProps();
        const validProps = InputWrapper.getUnusedProps(superProps);
        return validProps;
    }

    render() {
        const select = super.render();
        return (
            <InputWrapper {...this.props} className="select" active={this.state.active}>
                {select}
            </InputWrapper>
        );
    }
}
