import React from 'react';
import classnames from 'classnames';
import FileUpload from 'components/Form/FileUpload';
import config from 'config';
import { FormattedMessage } from 'react-intl';
import defaultMessages from 'i18n/defaultMessages';
import {formatBytes} from 'utils/transformUtils';

export default class ImageUploadField extends React.Component {
    static defaultProps = {
        maxSize: config.maxUploadSize.defaultImage,
        preferedDimentions: '1600x300'
    }

    getDropzoneContent = (value) => {
        let preview;
        if (value.startsWith('data:video') || value.endsWith('.mp4') || value.includes('.mp4')) {
            preview = (
                <video
                    ref={ref => this.video = ref}
                    muted
                    autoPlay
                    playsinline
                    className="image-upload__preview"
                    alt=""
                    src={value}
                />
            );
        } else {
            preview = (
                <img className="image-upload__preview" alt="" src={value} />
            );
        }
        return preview;
    }

    renderPlaceholder = () => {
        const {description, maxSize: rawMaxSize, preferedDimentions} = this.props;
        const maxSize = formatBytes(rawMaxSize);
        const recommendation = (
            <span>*<FormattedMessage {...defaultMessages.imageUpload_prefered} values={{maxSize, preferedDimentions}} /></span>
        );
        return (
            <React.Fragment>
                <div className="image-upload__placeholder" />
                <div className="image-upload__text">
                    {description}
                </div>
                {recommendation &&<div className="image-upload__text__recommendation">
                    {recommendation}
                </div>}
            </React.Fragment>
        );
    }

    render() {
        const {error, preferedDimentions, cover, noDimentions, className, includeVideo, ...restProps} = this.props;
        const classes = classnames('image-upload', className, {'image-upload--cover': cover, 'image-upload--no-dim': noDimentions});

        let accept = config.SUPPORTED_IMAGES;
        if (includeVideo) {
            accept = `${accept},${config.SUPPORTED_VIDEOS}`;
        }
        return (
            <FileUpload
                accept={accept}
                {...restProps}
                className={classes}
                renderContent={this.getDropzoneContent}
                renderPlaceholder={this.renderPlaceholder}
                replaceText={<FormattedMessage {...defaultMessages.imageUpload_replace} />}
                cover={cover}
                error={error}
            />
        );
    }
}
