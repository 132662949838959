import _ from 'lodash';
import axios from 'services/axios';
import {put, call, fork, select, takeLatest} from 'redux-saga/effects';
import {ActionTypes as ApplicantActionTypes, Actions as ApplicantActions, Selectors as ApplicantSelectors} from 'modules/applicant';
import {Selectors as CompanyJobsSelectors} from 'modules/companyJobs';
import {Selectors as ReferrerSelectors} from 'modules/referrer';
import {Selectors as AuthSelectors} from 'modules/auth';
import {Selectors as CompaniesSelectors} from 'modules/companies';
import config from 'config';
import { createCSV, prepareObjectToCsv } from 'utils/transformUtils';
import fileSaver from 'file-saver';
import { fetchJobsByIds, fetchJobsByIdsLogic } from 'modules/companyJobs/companyJobsSagas';
import { fetchReferrersByIds, fetchReferrersByIdsLogic } from 'modules/referrer/referrerSagas';
import {Selectors as NavigationSelectors} from 'modules/navigation';
import queryString from 'querystring';
import moment from 'moment';

export function* submitApplicationForm(action) {
    yield put(ApplicantActions.SUBMIT_APPLICATION_FORM_REQUEST());
    try {
        const jobId = yield select(CompanyJobsSelectors.matchingJobIdSelector);
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector)
        const isRefView = yield select(NavigationSelectors.isReferrerViewSelector);
        const referrerLink = isRefView ? yield select(ReferrerSelectors.matchingReferrerOwnLinkSelector) : yield select(ReferrerSelectors.matchingReferrerLinkSelector);

        const url = `jobs/${jobId}/applicants`;
        const payload = {...action.payload, referrerLink, companyId}
        const res = yield call(axios.post, url, payload);
        
        window.lnLastApplied = res.data;

        yield put(ApplicantActions.SUBMIT_APPLICATION_FORM_SUCCESS({jobId, applicant: res.data}));
    } catch (err) {
        yield put(ApplicantActions.SUBMIT_APPLICATION_FORM_FAILURE(err));
    }
};

export function* editApplicant(action) {
    const {payload} = action;
    yield put(ApplicantActions.APPLICANTS_UPDATE_APPLICANT_REQUEST());
    try {
        const applicant = yield select(ApplicantSelectors.matchingApplicantSelector);
        
        yield call(axios.patch, `applicants/${applicant.id}`, payload);
        
        yield put(ApplicantActions.APPLICANTS_UPDATE_APPLICANT_SUCCESS({...applicant, ...payload}));
    } catch (err) {
        yield put(ApplicantActions.APPLICANTS_UPDATE_APPLICANT_FAILURE(err));
    }
};

const fetchApplicantsLogic = async (filter, sorting, limitingCompanyId, fetchAll, enhancements = {}) => {
    const {referrerIdentifier, name, date, startAfter} = filter;
    const limit = fetchAll ? 9999 : config.applicantsPagination;
    const offset = startAfter || 0;
    
    const orderBy = `applicant.${sorting.id}`;
    const order = sorting.desc ? 'desc' : 'asc';

    const companyIdFilter = limitingCompanyId || filter.companyId;
    const query = {};
    if (referrerIdentifier) {
        query.referrerIdentifier = {op: 'like', value: `%${referrerIdentifier}%`}
    }
    if (name) {
        query.name = {op: 'like', value: `%${name}%`}
    }
    ['jobId', 'status'].forEach(key => {
        if (filter[key]) {
            if(filter[key] === "-1") {
                query[key] = {op: 'is', value: null}
            } else {
                query[key] = {op: '=', value: filter[key]}
            }
        }
    })
    if (companyIdFilter) {
        query.companyId = {op: '=', value: companyIdFilter}
    }

    if (date && (date.startDate || date.endData)) {
        const startDate = date.startDate ? moment(date.startDate).toDate() : null;
        const endDate = date.endDate ? moment(date.endDate).toDate() : null;
        query.createdAt = {
            op: 'between',
            startVal: startDate,
            endVal: endDate,
        }
    }

    const {cvDownloadUrl} = enhancements;
    const queryStr = queryString.stringify({cvDownloadUrl})
    const response = await axios.post(`applicants/query?offset=${offset}&limit=${limit}&orderBy=${orderBy}&order=${order}&${queryStr}`, {query})
    const applicants = response.data.items;
    const last = offset + response.data.items.length;
    return {applicants, last};
}

const fetchApplicantByIdLogic = async (id, enhancements = {}) => {
    const {cvDownloadUrl} = enhancements;
    const queryStr = queryString.stringify({cvDownloadUrl})
    const response = await axios.get(`applicants/${id}?${queryStr}`)
    const applicant = response.data;
    return applicant;
}

function* fetchApplicantJobs(applicants) {
    const jobs = yield select(CompanyJobsSelectors.companyJobsSelector);
    const unique = applicants.filter((a) => {
        const firstApplicantWithJobId = applicants.find(ia => ia.jobId === a.jobId);
        return firstApplicantWithJobId === a && !jobs.find(j => j.id === a.jobId);
    }).map(a => a.jobId);

    if (unique.length) {
        yield fork(fetchJobsByIds, unique);
    }
}

function* fetchApplicantReferrers(applicants) {
    const referrers = yield select(ReferrerSelectors.referrersSelector);
    const unique = applicants.filter((a) => {
        const firstApplicantWithReferrerId = applicants.find(ia => ia.referrerId === a.referrerId);
        return firstApplicantWithReferrerId === a && a.referrerId && !referrers.find(j => j.id === a.referrerId);
    }).map(a => a.referrerId);

    if (unique.length) {
        yield fork(fetchReferrersByIds, unique);
    }
}

function* fetchApplicants() {
    yield put(ApplicantActions.FETCH_APPLICANTS_REQUEST());
    try {
        const limitingCompanyId = yield select(AuthSelectors.limitedToCompanySelector);
        const filter = yield select(ApplicantSelectors.applicantsFilterSelector);
        const sorting = yield select(ApplicantSelectors.sortingSelector);

        const {applicants, last} = yield fetchApplicantsLogic(filter, sorting, limitingCompanyId);
        yield fork(fetchApplicantJobs, applicants);
        yield fork(fetchApplicantReferrers, applicants);

        yield put(ApplicantActions.FETCH_APPLICANTS_SUCCESS({applicants, last}));
    } catch (err) {
        console.log(err);
        yield put(ApplicantActions.FETCH_APPLICANTS_FAILURE(err));
    }
}

function* fetchMatchingApplicant() {
    yield put(ApplicantActions.FETCH_MATCHING_APPLICANT_REQUEST());
    try {
        const applicantId = yield select(ApplicantSelectors.matchingApplicantIdSelector);
        const applicant = yield fetchApplicantByIdLogic(applicantId)
        yield fork(fetchApplicantReferrers, [applicant]);
        yield put(ApplicantActions.FETCH_MATCHING_APPLICANT_SUCCESS({applicant}));
    } catch (err) {
        console.log(err);
        yield put(ApplicantActions.FETCH_MATCHING_APPLICANT_FAILURE(err));
    }
}

function* fetchMoreApplicants() {
    yield put(ApplicantActions.FETCH_MORE_APPLICANTS_REQUEST());
    try {
        const limitingCompanyId = yield select(AuthSelectors.limitedToCompanySelector);
        const filter = yield select(ApplicantSelectors.applicantsFilterSelector);
        const sorting = yield select(ApplicantSelectors.sortingSelector);
        const lastSnap = yield select(ApplicantSelectors.lastApplicantSelector);
        const paginatedFilter = lastSnap ? {...filter, startAfter: lastSnap} : filter;
        const {applicants, last} = yield fetchApplicantsLogic(paginatedFilter, sorting, limitingCompanyId);
        yield fork(fetchApplicantJobs, applicants);
        yield fork(fetchApplicantReferrers, applicants);

        yield put(ApplicantActions.FETCH_MORE_APPLICANTS_SUCCESS({applicants, last}));
    } catch (err) {
        console.log(err);
        yield put(ApplicantActions.FETCH_MORE_APPLICANTS_FAILURE(err));
    }
}
const addJobData = async (applicants, companies) => {
    const applicantsWithUniqueJobs = applicants.filter((a) => {
        const firstApplicantWithJobId = applicants.find(ia => ia.jobId === a.jobId);
        return firstApplicantWithJobId === a;
    });
    const applicantsWithUniqueReferrers = applicants.filter((a) => {
        if (!a.referrerId) {
            return false;
        }
        const firstApplicantWithReferrerId = applicants.find(ia => ia.referrerId === a.referrerId);
        return firstApplicantWithReferrerId === a;
    });
    const jobIds = applicantsWithUniqueJobs.map(a => a.jobId);
    const referrerIds = applicantsWithUniqueReferrers.map(a => a.referrerId);
    const jobsPromises = fetchJobsByIdsLogic(jobIds);
    const referrersPromises = fetchReferrersByIdsLogic(referrerIds);
    const [jobs, referrers] = await Promise.all([jobsPromises, referrersPromises]);

    const companyById = _.keyBy(companies, 'id');
    const jobById = _.keyBy(jobs, 'id');
    const referrerById = _.keyBy(referrers, 'id');

    const mappedApplicants = applicants.map(a => ({
        ...a,
        job: jobById[a.jobId] ?? {'title': (companyById[a.companyId].locale === 'he' ? "משרה כללית" : "General Position")},
        referrer: referrerById[a.referrerId],
        company: companyById[a.companyId],
    })).map(prepareObjectToCsv);
    return mappedApplicants;
}

export function* generateApplicantsReport(action) {
    yield put(ApplicantActions.GENERATE_APPLICANTS_REPORT_REQUEST());
    try {
        const limitingCompanyId = yield select(AuthSelectors.limitedToCompanySelector);
        const filter = yield select(ApplicantSelectors.applicantsFilterSelector);
        const sorting = yield select(ApplicantSelectors.sortingSelector);
        const {applicants} = yield fetchApplicantsLogic(filter, sorting, limitingCompanyId, true, {cvDownloadUrl: 'long'});
        const companies = yield select (CompaniesSelectors.companiesSelector);
        const applicantsWithJobs = yield addJobData(applicants, companies);
        const columns = {
            'company.alias': 'Company Id',
            'job.alias': 'Job Id',
            'job.title': 'Job Title',
            name: 'Name',
            email: 'Email',
            phone: 'Phone',
            'referrer.name': 'Referrer name',
            'referrer.identifier': 'Referrer Identifier',
            status: 'status',
            createdAtFormatted: 'date',
            cvDownloadUrl: 'CV',
            site: 'LinkedIn',
            aboutMe: 'About Me',
            botIntegrations: 'Bot Integrations',
            recruiterSummary: 'Recruiter Summary',
            aiSummary: "AI Summary"
        };
        const csv = yield createCSV(applicantsWithJobs, {columns});
        const currentDate = new Date().toJSON().slice(0,10).split('-').reverse().join('/');
        yield put(ApplicantActions.GENERATE_APPLICANTS_REPORT_SUCCESS());
        fileSaver.saveAs(new Blob([csv], {type: 'text/csv;charset=utf-8'}), `applicants-${currentDate}.csv`);
    } catch (err) {
        console.error(err);
        yield put(ApplicantActions.GENERATE_APPLICANTS_REPORT_FAILURE(err));
    }
}

function* downloadApplicantCV(action) {
    yield put(ApplicantActions.APPLICANT_DOWNLOAD_CV_FILE_REQUEST());
    try {
        const applicant = yield fetchApplicantByIdLogic(action.payload.id, {cvDownloadUrl: 'short'});
        if (!applicant.cvDownloadUrl) {
            throw new Error('Missing cvDownloadUrl');
        }
        window.open(applicant.cvDownloadUrl, '_blank');
        yield put(ApplicantActions.APPLICANT_DOWNLOAD_CV_FILE_SUCCESS());
    } catch (err) {
        console.log(err);
        yield put(ApplicantActions.APPLICANT_DOWNLOAD_CV_FILE_FAILURE(err));
    }
}

export default [
    takeLatest(ApplicantActionTypes.SUBMIT_APPLICATION_FORM_ACTION, submitApplicationForm),
    takeLatest(ApplicantActionTypes.APPLICANTS_UPDATE_APPLICANT, editApplicant),
    takeLatest([
        ApplicantActionTypes.FETCH_APPLICANTS_ACTION,
        ApplicantActionTypes.FILTER_APPLICANTS_RESULTS,
        ApplicantActionTypes.APPLICANTS_LIST_SORT_CHANGED,
    ], fetchApplicants),
    takeLatest([ApplicantActionTypes.FETCH_MORE_APPLICANTS_ACTION], fetchMoreApplicants),
    takeLatest(ApplicantActionTypes.GENERATE_APPLICANTS_REPORT_ACTION, generateApplicantsReport),
    takeLatest(ApplicantActionTypes.APPLICANT_DOWNLOAD_CV_FILE_ACTION, downloadApplicantCV),
    takeLatest(ApplicantActionTypes.APPLICANTS_MODAL_MOUNTED, fetchMatchingApplicant),
];
