import React from 'react';
import {connect} from 'react-redux';
import AbstractInput from 'components/Form/AbstractInput';
import InputWrapper from 'components/Form/InputWrapper';
import {Selectors as I18nSelectors} from 'modules/i18n';
import 'react-dates/initialize';
import {DateRangePicker, SingleDatePicker} from 'react-dates';
import classnames from 'classnames';
import defaultMessages from 'i18n/defaultMessages';
import {injectIntl} from 'react-intl';
import moment from 'moment';
import 'moment/locale/he';

export class DatePickerField extends AbstractInput {
    handleFocusChanged = (focusedInput) => {
        if (focusedInput) {
            this.handleFocus();
        } else {
            this.handleBlur();
        }
        this.setState({focusedInput});
    }

    getValidProps = () => {
        const {value = null, onChange, intl, single, isRTL} = this.props;
        if (single) {
            return {
                showClearDate: true,
                onDateChange: onChange,
                focused: this.state.focused,
                numberOfMonths: 1,
                onFocusChange: ({ focused }) => this.setState({ focused }),
                placeholder: '',
                date: value,
                isRTL,
            };
        }
        const safeVal = value || {};
        return {
            showClearDates: true,
            startDate: safeVal.startDate || null,
            endDate: safeVal.endDate || null,
            focusedInput: this.state.focusedInput,
            startDateId: 'startDate',
            endDateId: 'endDate',
            onDatesChange: onChange,
            startDatePlaceholderText: intl.formatMessage(defaultMessages.date_startDate),
            endDatePlaceholderText: intl.formatMessage(defaultMessages.date_endDate),
            isRTL,
        }
    }
    renderMonthElement = ({month, onYearSelect, onMonthSelect}) => {
        const yearElements = [];
        const currentYear = moment().year();
        for (let i = currentYear; i > currentYear - 100; i--) {
            yearElements.push(<option key={i} value={i}>{i}</option>)
        }
        const monthElements = moment.months().map((label, value) => (
            <option key={value} value={value}>{label}</option>
        ))
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                    <select
                        value={month.month()}
                        onChange={(e) => { onMonthSelect(month, e.target.value); }}
                    >
                        {monthElements}
                    </select>
                </div>
                <div>
                    <select
                        value={month.year()}
                        onChange={(e) => { onYearSelect(month, e.target.value); }}
                    >
                        {yearElements}
                    </select>
                </div>
            </div>
        );
    }
    render() {
        const {disabled, single, field} = this.props;
        const Component = single ? SingleDatePicker : DateRangePicker;
        const validProps = this.getValidProps();
        const classes = classnames('date-picker', {field});
        return (
            <InputWrapper {...this.props} className={classes} active={this.state.active}>
                <Component
                    noBorder
                    minimumNights={0}
                    isOutsideRange={d => d.isAfter(moment().endOf('day'))}
                    initialVisibleMonth={() => moment().subtract(1, 'months')}
                    onFocusChange={this.handleFocusChanged}
                    hideKeyboardShortcutsPanel
                    disabled={disabled}
                    renderMonthElement={this.renderMonthElement}
                    block
                    {...validProps}
                />
            </InputWrapper>
        );
    }
}

export default connect(state => ({isRTL: I18nSelectors.isRtlSelector(state)}))(injectIntl(DatePickerField));
