import React, {Component, cloneElement} from 'react';
import {createPortal} from 'react-dom';
import Flex from 'components/Flex';
import Clipboard from 'clipboard';
import ReactHint from 'components/Hint';
import Messages from 'i18n/defaultMessages';
import {FormattedMessage} from 'react-intl';

export default class ClickToCopy extends Component {
    constructor(props) {
        super(props);
        this.state = {success: true};
    }
    componentDidMount() {
        this.clipboard = new Clipboard(`#trigger-${this.props.id.toLowerCase()}`);
        this.clipboard.on('success', () => this.setState({success: true}))
        this.clipboard.on('error', () => this.setState({success: false}))
    }

    componentWillUnmount() {
        this.clipboard.destroy();
    }

    renderErrorMessage = () => {
        return (
            <div className="react-hint__hint">
                    <FormattedMessage {...Messages.clickToCopy_not_copied} />
            </div>
        );
    }

    renderCopyMessage = () => {
        if (this.state.success) {
            return (
                <div className="react-hint__hint">
                    <FormattedMessage {...Messages.clickToCopy_copied} />
                </div>
            );
        }
        const {renderErrorMessage = this.renderErrorMessage} = this.props;
        return renderErrorMessage();
    }
    render = () => {
        const {children, id, stretch, textToCopy, noHint} = this.props;
        const lowercaseId = id.toLowerCase()
        return (
            <Flex inline justify="start" horizontal stretch={stretch} style={{overflow: 'hidden'}}>
                {cloneElement(children, {
                    id: `trigger-${lowercaseId}`, 
                    'data-clipboard-target': `#cpy-${lowercaseId}`,
                    [`data-rh-${lowercaseId}`]: 'Top',
                })}
                <input readOnly type="text" value={textToCopy} id={`cpy-${lowercaseId}`} style={{width: 5, height: 5, border: 'none', background: 'none', opacity: 0.1, pointerEvents: 'none', position: 'absolute', top: -1000}} />
                {!noHint && createPortal(<ReactHint
                    attribute={`data-rh-${lowercaseId}`}
                    events={{click: true}}
                    persist
                    onRenderContent={this.renderCopyMessage}
                />, document.body)}
            </Flex>
        );
    }
}
