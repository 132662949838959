import {put, takeEvery, select, takeLatest, call, take, fork} from 'redux-saga/effects';
import {Actions, ActionTypes, Selectors as CustomerSelectors} from 'modules/customer';
import axios from 'services/axios';

function* fetchCustomer() {
    const loading = yield select(CustomerSelectors.isLoadingSelector);
    const currentTenantId = yield select(CustomerSelectors.customerIdSelector);
    const wantedTenantId = yield select(CustomerSelectors.matchingCustomerIdSelector);
    const failure = yield select(CustomerSelectors.customerFailureSelector);
    if (failure || loading || !wantedTenantId || wantedTenantId === currentTenantId) {
        return;
    }
    yield put(Actions.FETCH_CUSTOMER_REQUEST());
    try {
        const response = yield call(axios.get, `tenants/${wantedTenantId}`);
        yield put(Actions.FETCH_CUSTOMER_SUCCESS(response.data));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_CUSTOMER_FAILURE(err));
    }
}

function* editSettings(action) {
    yield put(Actions.EDIT_CUSTOMER_SETTINGS_REQUEST());
    try {
        let customerId = yield select(CustomerSelectors.customerIdSelector);
        const settings = yield select(CustomerSelectors.customerSettingsSelector);
        const newSettings = {...action.payload};
        yield call(axios.patch, `tenants/${customerId}`, newSettings);
        
        yield put(Actions.EDIT_CUSTOMER_SETTINGS_SUCCESS({...settings, ...newSettings}));
    } catch (err) {
        console.log(err);
        yield put(Actions.EDIT_CUSTOMER_SETTINGS_FAILURE(err));
    }
}

function* fetchCustomerSettings(fetchPrivate) {
    yield put(Actions.FETCH_CUSTOMER_SETTINGS_REQUEST());
    try {
        let customerId = yield select(CustomerSelectors.customerIdSelector);
        if (!customerId) {
            const action = yield take(ActionTypes.FETCH_CUSTOMER_SUCCESS);
            customerId = action.payload.id;
        }
        const response = yield call(axios.get, `tenants/${customerId}`);
        yield put(Actions.FETCH_CUSTOMER_SETTINGS_SUCCESS(response.data));
        yield fork(fetchCustomerTerms);
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_CUSTOMER_SETTINGS_FAILURE(err));
    }
}

function* fetchCustomerTerms() {
    try {
        let settings = yield select(CustomerSelectors.customerSettingsSelector);
        if (!settings.termsUrl) {
            return;
        }
        yield put(Actions.FETCH_CUSTOMER_TERMS_REQUEST());
        const response = yield fetch(settings.termsUrl);
        const terms = yield response.text();
        yield put(Actions.FETCH_CUSTOMER_TERMS_SUCCESS({terms}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_CUSTOMER_TERMS_FAILURE(err));
    }
}

export default [
    takeEvery('*', fetchCustomer),
    takeLatest(ActionTypes.EDIT_CUSTOMER_SETTINGS_ACTION, editSettings),
    takeLatest(ActionTypes.FETCH_CUSTOMER_SETTINGS_ACTION, fetchCustomerSettings, true),
    takeLatest(ActionTypes.PUBLIC_CUSTOMER_TERMS_MOUNTED, fetchCustomerSettings, false),
]