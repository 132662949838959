import React from 'react';
import classnames from 'classnames';
import {Prompt} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import defaultMessages from 'i18n/defaultMessages';


class Form extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.hasUpdates && !prevProps.hasUpdates) {
            window.onbeforeunload = this.getFormattedMessage;
        } else if (!this.props.hasUpdates && prevProps.hasUpdates) {
            window.onbeforeunload = undefined;
        }
    }
    componentWillUnmount() {
        window.onbeforeunload = undefined;
    }
    getFormattedMessage = () => {
        return this.props.intl.formatMessage(defaultMessages.form_unsavedChanges);
    }
    render() {
        const {children, horizontal, className, marginChildren, noWrap, hasUpdates, ...restProps} = this.props;
        const classes = classnames('form', className, {
                'form--row': horizontal,
                'form--noWrap': noWrap,
                'form--margin-children': marginChildren
        });
        return (
            <form className={classes} {...restProps}>
                <Prompt when={!!hasUpdates} message={this.getFormattedMessage} />
                {children}
            </form>
        );
    }
}

const InjectedForm = injectIntl(Form);

InjectedForm.Column = ({children, className, flex, ...rest}) => {
    return (
        <div className={classnames('form__column', className, {'form__column--flex': flex})} {...rest}>
            {children}
        </div>
    );
}

InjectedForm.Row = ({children, className, reverse, flex, justify, ...rest}) => (
    <div
        className={classnames('form__row', className, {
            'form__row--reverse': reverse,
            'form__row--flex': flex,
            'form__row--justifyStart': justify === 'start',
        })}
        {...rest}
    >
        {children}
    </div>
);

export default InjectedForm;