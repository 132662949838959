import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'ADMIN_COMPANY_JOBS_MOUNTING',
    'COMPANY_JOBS_MOUNTING',
    'PUBLIC_JOB_MOUNTING',
    'PUBLIC_LOAD_MORE_JOBS',
    'ADMIN_COMPANY_JOBS_COMPANY_CHANGED',

    'FETCH_COMPANY_JOBS_REQUEST',
    'FETCH_COMPANY_JOBS_SUCCESS',
    'FETCH_COMPANY_JOBS_FAILURE',

    'FETCH_MORE_JOBS_REQUEST',
    'FETCH_MORE_JOBS_SUCCESS',
    'FETCH_MORE_JOBS_FAILURE',

    'FETCH_MATCHING_JOB_REQUEST',
    'FETCH_MATCHING_JOB_SUCCESS',
    'FETCH_MATCHING_JOB_FAILURE',
    
    'CREATE_JOB_ACTION',
    'CREATE_JOB_REQUEST',
    'CREATE_JOB_SUCCESS',
    'CREATE_JOB_FAILURE',

    'JOB_PUSH_REQUEST_ACTION',
    'JOB_SEND_WA_REQUEST_ACTION',
    'JOB_PUSH_REQUEST_REQUEST',
    'JOB_PUSH_REQUEST_SUCCESS',
    'JOB_PUSH_REQUEST_FAILURE',
    
    'EDIT_JOB_ACTION',
    'EDIT_JOB_REQUEST',
    'EDIT_JOB_SUCCESS',
    'EDIT_JOB_FAILURE',
    
    'FETCH_JOBS_BY_IDS_REQUEST',
    'FETCH_JOBS_BY_IDS_SUCCESS',
    'FETCH_JOBS_BY_IDS_FAILURE',
    
    'CREATE_JOB_MOUNTING',
    'EDIT_JOB_MOUNTING',
    'LOAD_MORE_JOBS',
    'PRIVATE_JOB_FETCHED',
    'ADMIN_DUPLICATE_JOB_ACTION',
    
    'FILTER_ADMIN_JOB_LIST',
    'FILTER_APPLICANTS_BY_JOB',
    'ADMIN_JOB_LIST_SORT_CHANGED',
    
    'GENERATE_JOBS_REPORT_ACTION',
    'GENERATE_JOBS_REPORT_REQUEST',
    'GENERATE_JOBS_REPORT_SUCCESS',
    'GENERATE_JOBS_REPORT_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
