import {ActionTypes} from './groupActions';
import config from 'config';

const appendGroups = (state, groupsToMerge) => {
    const groups = (state.groups || []).concat(groupsToMerge || []);
    return groups;
}

const mergeMultipleGroups = (state, groupsToMerge, onTop) => {
    const groups = (state.groups || []).slice();
    groupsToMerge.forEach(group => {
        const position = groups.findIndex(c => c.id === group.id);
        if (position >= 0) {
            groups[position] = group;
        } else if (onTop) {
            groups.unshift(group);
        } else {
            groups.push(group);
        }
    })
    return groups;
}

const appendReferrer = (state, ref) => {
    const refs = (state.groupReferrers || []).concat(ref || []);
    return refs;
}

const markAvailable = (state, ref) => {
    const new_available = (state.available || []).concat(ref || []);
    return new_available;
}

const removeReferrer = (state, ref_id) => {
    const refs = (state.groupReferrers || []).slice();
    return refs.filter(r => r.id != ref_id);
}

const removeGroup = (state, id) => {
    const groups = (state.groups || []).slice();
    return groups.filter(g => g.id != id);
}

const mergeGroup = (state, group, onTop) => {
    return mergeMultipleGroups(state, [group], onTop);
}

const markUnavailable = (state, refId) => {
    const available = (state.available || []).slice();
    return available.filter(r => r.id != refId);
}

export default (state = {}, action) => {
    let nextState;
    const {type, payload = {}} = action;
    switch (type) {
    case ActionTypes.CREATE_GROUP_REQUEST:
        nextState = {...state, loadingGroup: true, justCreated: false, error: null};
        break;
    case ActionTypes.UPDATE_GROUP_REQUEST:
        nextState = {...state, loadingGroup: true, error: null};
        break;
    case ActionTypes.UPDATE_GROUP_FAILURE:
    case ActionTypes.CREATE_GROUP_FAILURE:
    case ActionTypes.DELETE_GROUP_FAILURE:
    case ActionTypes.DELETE_GROUP_REFERRER_FAILURE:
        nextState = {...state, loadingGroup: false};
        break;
    case ActionTypes.UPDATE_GROUP_SUCCESS:
        nextState = {
            ...state, loadingGroup: false, groups: mergeGroup(state, action.payload, true),
        };
        break;
    case ActionTypes.CREATE_GROUP_SUCCESS:
        nextState = {
            ...state, loadingGroup: false,
            justCreated: true, groups: mergeGroup(state, action.payload, true),
        };
        break;
    case ActionTypes.FETCH_GROUPS_ACTION:
        nextState = {...state, filter: null, sorting: null};
        break;
    case ActionTypes.FETCH_GROUPS_REQUEST:
        nextState = {...state, referrers: null, loading: true, sorting: payload[0]};
        break;
    case ActionTypes.FETCH_GROUPS_FAILURE:
        nextState = {...state, referrers: null, loading: false, fetchError: true};
        break;
    case ActionTypes.FETCH_GROUPS_SUCCESS: {
        const {groups = [], last} = payload;
        nextState = {
            ...state,
            groups, last,
            hasMore: groups.length === config.groupsPagination,
            loading: false
        };
        break;
    }
    case ActionTypes.FETCH_GROUP_REFERRERS_ACTION:
        nextState = {...state, filter: null, sorting: null};
        break;
    case ActionTypes.FETCH_GROUP_REFERRERS_REQUEST:
        nextState = {...state, groupReferrers: null, loadingGroupReferrers: true, sorting: payload[0]};
        break;
    case ActionTypes.FETCH_GROUP_REFERRERS_FAILURE:
        nextState = {...state, groupReferrers: null, loadingGroupReferrers: false, fetchError: true};
        break;
    case ActionTypes.FETCH_GROUP_REFERRERS_SUCCESS: {
        const {groupReferrers = [], available = [], last} = payload;
        nextState = {
            ...state,
            groupReferrers, available, last,
            loadingGroupReferrers: false
        };
        break;
    }
    case ActionTypes.FETCH_MORE_GROUPS_SUCCESS: {
        const {groups = [], last} = payload;
        nextState = {
            ...state,
            fetching: false,
            groups: appendGroups(state, groups),
            last,
            hasMore: groups.length === config.groupsPagination,
        };
        break;
    }
    case ActionTypes.ASSIGN_REFERRER_REQUEST: {
        nextState = {...state, error: null};
        break;
    }
    case ActionTypes.ASSIGN_REFERRER_SUCCESS: {
        const {group_referrer} = payload.data;
        nextState = {...state, error: null, groupReferrers: appendReferrer(state, group_referrer), available: markUnavailable(state, group_referrer.id)};
        break;
    }
    case ActionTypes.ASSIGN_REFERRER_FAILURE: {
        nextState = {...state, fetchError: true};
        break;
    }
    case ActionTypes.DELETE_GROUP_REFERRER_REQUEST: {
        nextState = {...state, error: null};
        break;
    }
    case ActionTypes.DELETE_GROUP_REFERRER_SUCCESS: {
        const {referrer} = payload.data;
        nextState = {...state, error: null, groupReferrers: removeReferrer(state, referrer.id), available: markAvailable(state, referrer)};
        break;
    }
    case ActionTypes.DELETE_GROUP_REQUEST: {
        nextState = {...state, error: null};
        break;
    }
    case ActionTypes.DELETE_GROUP_SUCCESS: {
        const {id} = payload;
        nextState = {...state, error: null, groups: removeGroup(state, id)};
        break;
    }
    default:
    nextState = state;
    }
    return nextState;
};
