import React from 'react';
import Toggle from 'react-toggle';
import classnames from 'classnames';

export default class ToggleField extends React.Component{
    render() {
        const {label, leftLabel, className, value, ...restProps} = this.props;
        const twoSided = label && leftLabel;
        const classes = classnames('Toggle', className, {twoSided});
        return (
            <label className={classes}>
                {label && <span className="Toggle__labelRight">{label}</span>}
                <Toggle
                    {...restProps}
                    checked={!!value}
                    />
                {leftLabel && <span className="Toggle__labelLeft">{leftLabel}</span>}
            </label>
        );
    }
}
