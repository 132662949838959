import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'FETCH_GROUPS_ACTION',
    'FETCH_GROUPS_REQUEST',
    'FETCH_GROUPS_SUCCESS',
    'FETCH_GROUPS_FAILURE',

    'FETCH_MORE_GROUPS_ACTION',
    'FETCH_MORE_GROUPS_REQUEST',
    'FETCH_MORE_GROUPS_SUCCESS',
    'FETCH_MORE_GROUPS_FAILURE',
    
    'CREATE_GROUP_ACTION',
    'CREATE_GROUP_REQUEST',
    'CREATE_GROUP_SUCCESS',
    'CREATE_GROUP_FAILURE',

    'UPDATE_GROUP_ACTION',
    'UPDATE_GROUP_REQUEST',
    'UPDATE_GROUP_SUCCESS',
    'UPDATE_GROUP_FAILURE',

    'DELETE_GROUP_ACTION',
    'DELETE_GROUP_REQUEST',
    'DELETE_GROUP_SUCCESS',
    'DELETE_GROUP_FAILURE',

    'FETCH_GROUP_REFERRERS_ACTION',
    'FETCH_GROUP_REFERRERS_REQUEST',
    'FETCH_GROUP_REFERRERS_SUCCESS',
    'FETCH_GROUP_REFERRERS_FAILURE',

    'ASSIGN_REFERRER_ACTION',
    'ASSIGN_REFERRER_REQUEST',
    'ASSIGN_REFERRER_SUCCESS',
    'ASSIGN_REFERRER_FAILURE',

    'DELETE_GROUP_REFERRER_ACTION',
    'DELETE_GROUP_REFERRER_REQUEST',
    'DELETE_GROUP_REFERRER_SUCCESS',
    'DELETE_GROUP_REFERRER_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
