import React, {Component} from 'react';
import classnames from 'classnames';

export default class Title extends Component {
    static defaultProps = {
        component: 'span',
    }
    render = () => {
        const {children, page, main, regular, spaced, stretch, center, className, component: TitleComponent, ...rest} = this.props;
        const classes = {
            'title--spaced': spaced,
            'title--stretch': stretch,
            'title--center': center,
            'title--main': main,
            'title--regular': regular,
            'title--page': page,
        }
        return (
            <TitleComponent className={classnames("title", className, classes)} {...rest}>
                {children}
            </TitleComponent>
        );
    }
}
