import React, {Component} from 'react';
import classnames from 'classnames';
import Flex from 'components/Flex';

export default class Main extends Component {
    render = () => {
        const {className , ...rest} = this.props;
        return (
            <Flex component="main" stretch center className={classnames('main', className)} {...rest} />
        );
    }
}
