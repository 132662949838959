import _ from 'lodash';

export default class LinkedinTracker {
    constructor(config) {
        this.trackingId = config.id;
        this.config = config;
        this.name = this.constructor.name;
        if (!this.trackingId) {
            throw new Error(`${this.name}: Missing id`);
        }
    }

    track(actionKey) {
        console.log(`${this.name}: Tracking ${actionKey}`)
        switch (actionKey) {
        case 'init':
            break;
        case 'employee_pageload':
        case 'candidate_pageload':
            let page_view = this.config.page_view_id;
            if(page_view) {
                window.initializeLinkedInPixel(this.trackingId, function () {
                    if (typeof lintrk === "function") {
                        window.lintrk("track", { conversion_id: page_view });
                    } else {
                        console.error("lintrk function is not available.");
                    }
                });
            }
            break;
        case 'cv_submitted':
            let cv_submitted = this.config.cv_submitted_id;
            if(cv_submitted) {
                window.initializeLinkedInPixel(this.trackingId, function () {
                    if (typeof lintrk === "function") {
                        window.lintrk("track", { conversion_id: cv_submitted });
                    } else {
                        console.error("lintrk function is not available.");
                    }
                });
            }
            break;
        default:
            console.error(`${this.name}: Invalid actionKey '${actionKey}'`)
        }
    }
}