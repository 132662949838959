import {ActionTypes as RefListActionTypes} from 'containers/ReferrersList/referrerListActions';
import {ActionTypes} from './referrerActions';
import config from 'config';
import defaultMessages from 'i18n/defaultMessages';
import _ from 'lodash';

const appendReferrers = (state, referrersToMerge) => {
    const referrers = (state.referrers || []).concat(referrersToMerge || []);
    return referrers;
}

const getLinks = () => {
    const rawLinks = localStorage.getItem(config.referrerOwnLinks) || '{}';
    const parsedLinks = JSON.parse(rawLinks);
    const validLinks = _.reduce(parsedLinks, function(result, value, key) {
        if (value.id) {
            result[key] = value
        }
        return result;
      }, {});
    if (!_.isEqual(validLinks, parsedLinks)) {
        localStorage.setItem(config.referrerOwnLinks, JSON.stringify(validLinks));
    }
    return parsedLinks;
}
const mergeLink = (state, payload) => {
    const {companyId, link, name, id} = payload;
    const links = {...state.links};
    links[companyId] = {link, name, id};
    localStorage.setItem(config.referrerOwnLinks, JSON.stringify(links));
    return links;
}

const mergeMultipleReferrers = (state, referrersToMerge, onTop) => {
    const referrers = (state.referrers || []).slice();
    referrersToMerge.forEach(referrer => {
        const position = referrers.findIndex(c => c.id === referrer.id);
        if (position >= 0) {
            referrers[position] = referrer;
        } else if (onTop) {
            referrers.unshift(referrer);
        } else {
            referrers.push(referrer);
        }
    })
    return referrers;
}

const mergeReferrer = (state, job, onTop) => {
    return mergeMultipleReferrers(state, [job], onTop);
}

export default (state = {links: getLinks()}, action) => {
    let nextState;
    const {type, payload = {}} = action;
    switch (type) {
    case ActionTypes.CREATE_REFERRER_REQUEST:
        nextState = {...state, loadingReferrer: true, justCreated: false, error: null};
        break;
    case ActionTypes.CREATE_REFERRER_FAILURE:
        nextState = {...state, loadingReferrer: false};
        if (action.payload.response.status === 409) {
            nextState.error = defaultMessages.error_referrerAlreadyExists;
        }
        break;
    case ActionTypes.CREATE_REFERRER_SUCCESS:
        nextState = {
            ...state, loadingReferrer: false,
            justCreated: true, referrers: mergeReferrer(state, action.payload, true),
        };
        break;
    case ActionTypes.REFERRER_REVOKE_NOTIF_REQUEST:
        nextState = {...state, loading: true};
        break;
    case ActionTypes.REFERRER_REVOKE_NOTIF_SUCCESS:
    case ActionTypes.REFERRER_REVOKE_NOTIF_FAILURE:
        nextState = {...state, loading: false};
        break;
    case ActionTypes.GENERATE_LINK_REQUEST:
        nextState = {...state, loadingLink: true};
        break;
    case ActionTypes.GENERATE_LINK_FAILURE:
        nextState = {...state, loadingLink: false};
        break;
    case ActionTypes.GENERATE_LINK_SUCCESS:
        nextState = {...state, loadingLink: false, links: mergeLink(state, action.payload)};
        break;
    case ActionTypes.FETCH_REFERRERS_BY_IDS_SUCCESS:
        nextState = {...state, referrers: mergeMultipleReferrers(state, action.payload)};
        break;
    case ActionTypes.GENERATE_REFERRERS_REPORT_REQUEST:
        nextState = {...state, loadingFile: true};
        break;
    case ActionTypes.GENERATE_REFERRERS_REPORT_SUCCESS:
    case ActionTypes.GENERATE_REFERRERS_REPORT_FAILURE:
        nextState = {...state, loadingFile: false};
        break;
    case ActionTypes.FETCH_REFERRERS_ACTION:
        nextState = {...state, filter: null, sorting: null};
        break;
    case ActionTypes.FETCH_REFERRERS_REQUEST:
        nextState = {...state, referrers: null, loading: true, sorting: payload[0]};
        break;
    case ActionTypes.FETCH_REFERRERS_FAILURE:
        nextState = {...state, referrers: null, loading: false, fetchError: true};
        break;
    case ActionTypes.FETCH_REFERRERS_SUCCESS: {
        const {referrers = [], last} = payload;
        nextState = {
            ...state,
            referrers, last,
            hasMore: referrers.length === config.referrerPagination,
            loading: false
        };
        break;
    }
    case ActionTypes.FETCH_MORE_REFERRERS_SUCCESS: {
        const {referrers = [], last} = payload;
        nextState = {
            ...state,
            fetching: false,
            referrers: appendReferrers(state, referrers),
            last,
            hasMore: referrers.length === config.referrerPagination,
        };
        break;
    }
    case ActionTypes.IMPORT_REFERRERS_FROM_FILE_REQUEST:
        nextState = {...state, loadingImportFile: true};
        break;
    case ActionTypes.IMPORT_REFERRERS_FROM_FILE_SUCCESS:
        nextState = {...state, loadingImportFile: false};
        break;
    case ActionTypes.IMPORT_REFERRERS_FROM_FILE_FAILURE:
        nextState = {...state, loadingImportFile: false};
        break;
    case ActionTypes.FETCH_REFERRER_STATS_REQUEST:
        nextState = {...state, loadingStats: true};
        break;
    case ActionTypes.FETCH_REFERRER_STATS_SUCCESS:
        nextState = {...state, loadingStats: false, referrerStats: payload};
        break;
    case ActionTypes.FETCH_REFERRER_STATS_FAILURE:
        nextState = {...state, loadingStats: false, referrerStats: null};
        break;
    case ActionTypes.REFERRER_UPDATE_GDPR_SUCCESS:
        nextState = {...state, loadingStats: false, referrerStats: {...state.referrerStats, gdpr: payload}};
        break;
    case ActionTypes.IMPORT_REFERRERS_STATUS_UPDATED:
        if (!_.isEqual(state.importReferrersStatus, payload)) {
            nextState = {...state, importReferrersStatus: payload};
        } else {
            nextState = state;
        }
        break;
    case ActionTypes.FILTER_REFERRER_LIST:
        nextState = {...state, filter: payload};
        break;
    case RefListActionTypes.REF_LIST_SORT_CHANGED:
        nextState = {...state, sort: payload};
        break;
    default:
    nextState = state;
    }
    return nextState;
};
