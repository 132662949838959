import { createSelector } from 'reselect';
import {Selectors as NavigationSelectors} from 'modules/navigation';
import {Selectors as CompaniesSelectors} from 'modules/companies';

export const sliceSelector = state => state.companyJobs;
export const companyJobsSelector = createSelector(sliceSelector, slice => slice.jobs || []);
export const isLoadingSelector = createSelector(sliceSelector, slice => slice.loading);
export const lastJobSelector = createSelector(sliceSelector, slice => slice.last);
export const hasMoreJobsSelector = createSelector(sliceSelector, slice => !!slice.hasMore);
export const wasJobJustCreatedSelector = createSelector(sliceSelector, slice => !!slice.created);
export const filterSelector = createSelector(sliceSelector, slice => slice.filter || {});
export const isLoadingFileSelector = createSelector(sliceSelector, slice => slice.loadingFile);
export const sortingSelector = createSelector(sliceSelector, slice => slice.sorting || {id: 'order', desc: true});

const getPropWithCompanyFallback = (jobAlias, job, company, prop) => {
    if (jobAlias && !job) {
        return null;
    }
    if (job && job[prop]) {
        return job[prop];
    }
    return company && company[prop];
}

export const matchingJobAliasSelector = createSelector(
    NavigationSelectors.pathnameSelector, companyJobsSelector,
    (pathname, jobs) => {
        const matchedRegex = (pathname || '').match(/\/jobs\/([^/?]*)/) || [];
        const jobAlias = matchedRegex[1];
        return jobAlias;
    }
);

export const matchingJobSelector = createSelector(
    companyJobsSelector, matchingJobAliasSelector,
    (jobs, alias) => jobs.find(j => j.alias === alias),
);

export const matchingJobIdSelector = createSelector(
    matchingJobSelector,
(matchingJob) => {
        return matchingJob && matchingJob.id;
    }
);

export const jobCoverSelector = createSelector(
    matchingJobAliasSelector, matchingJobSelector, CompaniesSelectors.matchingCompanySelector, NavigationSelectors.pathnameSelector,
    (jobAlias, job, company, pathname) => {
        if(pathname.endsWith('/terms') && company['termsPageCover']) {
            return company['termsPageCover']
        }
        return getPropWithCompanyFallback(jobAlias, job, company, 'cover');
    }
)

export const jobMobileCoverSelector = createSelector(
    matchingJobAliasSelector, matchingJobSelector, CompaniesSelectors.matchingCompanySelector, NavigationSelectors.pathnameSelector,
    (jobAlias, job, company, pathname) => {
        if(pathname.endsWith('/terms') && company['termsPageCoverMobile']) {
            return company['termsPageCoverMobile']
        }
        return getPropWithCompanyFallback(jobAlias, job, company, 'coverMobile');
    }
)

export const jobIncentiveSelector = createSelector(
    matchingJobAliasSelector, matchingJobSelector,
    CompaniesSelectors.matchingCompanySelector,
    NavigationSelectors.isReferrerViewSelector,
    (jobAlias, job, company, isReferrerView) => {

        const incentiveProp = isReferrerView ? 'rIncentive' : 'aIncentive';
        return getPropWithCompanyFallback(jobAlias, job, company, incentiveProp);
    }
);

export const referrerWorthSelector = createSelector(
    matchingJobAliasSelector, matchingJobSelector, CompaniesSelectors.matchingCompanySelector, 
    (jobAlias, job, company) => {
        return getPropWithCompanyFallback(jobAlias, job, company, 'referrerWorth');
    }
);

export const referrerJobUrlSelector = createSelector(matchingJobAliasSelector, CompaniesSelectors.matchingCompanyAliasSelector,
    (jobAlias, companyAlias) => {
        return `/r/companies/${companyAlias}/jobs/${jobAlias}`;
    }
);

export const referrerJobFullUrlSelector = createSelector(
    NavigationSelectors.baseSelector,
    referrerJobUrlSelector,
    (baseUrl, referrerJobUrl) => {
        return `${baseUrl}${referrerJobUrl}`;
    }
);

export const referrerShareJobsBaseUrlSelector = createSelector(
    NavigationSelectors.baseSelector,
    CompaniesSelectors.matchingCompanyAliasSelector,
    (baseUrl, companyAlias) => {
        return `${baseUrl}/r/companies/${companyAlias}/jobs/`;
    }
);
