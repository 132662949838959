import {ActionTypes} from './i18nActions';

export default (state = {}, action) => {
    let nextState;
    const {type, payload} = action;
    switch (type) {
    case ActionTypes.ADMIN_LOCALE_CHANGED:
    case ActionTypes.ADMIN_LOCALE_AUTO_SELECTED:
        nextState = {...state, adminLocale: payload};
        break;
    case ActionTypes.PUBLIC_LOCALE_AUTO_SELECTED:
        nextState = {...state, locale: payload};
        break;
    default:
        nextState = state;
    }
    return nextState;
};
