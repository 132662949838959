import {put, takeLatest, select, call} from 'redux-saga/effects';
import {Selectors as CompaniesSelectors} from 'modules/companies';
import {Actions, ActionTypes as StatsActionTypes} from 'modules/stats';
import * as AuthSelectors from 'modules/auth/authSelectors';
import axios from 'services/axios';

function* fetchStats() {
    try {
        const isAdmin = !(yield select(AuthSelectors.limitedToCompanySelector))
        if (!isAdmin) {return}
        
        yield put(Actions.FETCH_STATS_REQUEST());
        const {data: stats} = yield call(axios.get, 'stats')
        yield put(Actions.FETCH_STATS_SUCCESS(stats));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_STATS_FAILURE(err));
    }
}

function* fetchCompanyStats() {
    yield put(Actions.FETCH_COMPANY_STATS_REQUEST());
    try {
        const company = yield select(CompaniesSelectors.matchingCompanySelector);
        const {data: stats} = yield call(axios.get, `companies/${company.id}/stats`)
        yield put(Actions.FETCH_COMPANY_STATS_SUCCESS(stats));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_COMPANY_STATS_FAILURE(err));
    }
}

export default [
    takeLatest(StatsActionTypes.FETCH_STATS_ACTION, fetchStats),
    takeLatest(StatsActionTypes.FETCH_COMPANY_STATS_ACTION, fetchCompanyStats),
]