import React from 'react';
import {connect} from 'react-redux';
import AbstractInput from 'components/Form/AbstractInput';
import InputWrapper from 'components/Form/InputWrapper';
import {Selectors as I18nSelectors} from 'modules/i18n';
import 'react-dates/initialize';
import {DayPickerRangeController} from 'react-dates';
import classnames from 'classnames';
import defaultMessages, {getIntlMessage} from 'i18n/defaultMessages';
import {injectIntl} from 'react-intl';
import moment from 'moment';
import 'moment/locale/he';
import Flex from 'components/Flex';
import TextInput from '../TextInput';
import Button from 'components/Button';
import { Popover } from 'react-tiny-popover'

export class DateRangePickerField extends AbstractInput {

    hasHourError(value) {
        return !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
    }

    onRangeDatesChange = (newRange) => {
        const toUpdate = {value: {...newRange}};
        if (toUpdate.value.startDate) {
            const dateTime = this.getDateWithTime(moment(toUpdate.value.startDate).startOf('day'));
            toUpdate.value.startDate = dateTime.date
            toUpdate.startHour = dateTime.time || '00:00';
        }
        if (toUpdate.value.endDate) {
            const dateTime = this.getDateWithTime(moment(toUpdate.value.endDate).endOf('day'))
            toUpdate.value.endDate = dateTime.date;
            toUpdate.endHour = dateTime.time || '23:59';
        }
        this.setState({...toUpdate, focusedInput: this.state.focusedInput === 'startDate' ? 'endDate' : 'startDate'})
    }

    getDateWithTime(date, timeStr) {
        if (!date || !timeStr) {return {date, timeStr};}

        const [hourStr, minStr] = timeStr.split(':');
        const hour = parseInt(hourStr)
        const minute = parseInt(minStr)

        if (Number.isInteger(hour) && Number.isInteger(minute) && hour < 24 && minute < 60) {
            const newDate = moment(date).set({hour, minute});
            return {date: newDate, time: `${String(hour).padLeft(2, '0')}:${String(minute).padLeft(2, '0')}`}
        }
        
        return {date};
    }
    onTimeChange(name, timeStr) {
        const stateDate = `${name}Date`
        const stateHour = `${name}Hour`
        const update = this.getDateWithTime(this.state.value?.[stateDate], timeStr)
        if (update && update.date) {
            this.setState({value: {...this.state.value, [stateDate]: update.date}, [stateHour]: timeStr})
        }
    }

    onRangeDatesApply = () => {
        this.props.onChange(this.state.value)
        this.setState({isOpen: false})
    }
    getValidProps = () => {
        const {value = null, intl, isRTL} = this.props;

        return {
            showClearDates: true,
            startDate: this.state.value?.startDate || null,
            endDate: this.state.value?.endDate || null,
            focusedInput: this.state.focusedInput,
            startDateId: 'startDate',
            endDateId: 'endDate',
            onDatesChange: this.onRangeDatesChange,
            onClose: () => this.setState({value}),
            keepOpenOnDateSelect: true,
            startDatePlaceholderText: intl.formatMessage(defaultMessages.date_startDate),
            endDatePlaceholderText: intl.formatMessage(defaultMessages.date_endDate),
            isRTL,
        }
    }
    renderMonthElement = ({month, onYearSelect, onMonthSelect}) => {
        const yearElements = [];
        const currentYear = moment().year();
        for (let i = currentYear; i > currentYear - 100; i--) {
            yearElements.push(<option key={i} value={i}>{i}</option>)
        }
        const monthElements = moment.months().map((label, value) => (
            <option key={value} value={value}>{label}</option>
        ))
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                    <select
                        value={month.month()}
                        onChange={(e) => { onMonthSelect(month, e.target.value); }}
                    >
                        {monthElements}
                    </select>
                </div>
                <div>
                    <select
                        value={month.year()}
                        onChange={(e) => { onYearSelect(month, e.target.value); }}
                    >
                        {yearElements}
                    </select>
                </div>
            </div>
        );
    }

    onOpen() {
        if (!this.props.disabled) {
            this.setState({isOpen: true, focusedInput: 'startDate'})
        }
    }

    renderHours = () => {
        const {intl, noHours} = this.props;
        
        if (noHours) {return null}

        return (
            <Flex className="time-wrapper" horizontal noWrap centerChildren>
                <TextInput
                    label={getIntlMessage('date_startTime', intl)}
                    placeholder="00:00"
                    enforce="hourmin"
                    small
                    value={this.state.startHour || ''}
                    onChange={(e, val) => this.onTimeChange('start', val)}
                    maxLength={5}
                    />
                <TextInput
                    label={getIntlMessage('date_endTime', intl)}
                    placeholder="00:00"
                    enforce="hourmin"
                    small
                    value={this.state.endHour || ''}
                    onChange={(e, val) => this.onTimeChange('end', val)}
                    maxLength={5}
                />
                <Button
                    primary
                    type="button"
                    text={getIntlMessage('general_save', intl)}
                    onClick={this.onRangeDatesApply}
                    disabled={this.hasHourError(this.state.startHour) || this.hasHourError(this.state.endHour) || this.state.value.startDate.isSameOrAfter(this.state.value.endDate)} />
            </Flex>
        );
    }

    renderPicker = () => {
        const {disabled} = this.props;
        const validProps = this.getValidProps();
        return (
            <DayPickerRangeController
                ref={ref => this.ref = ref}
                noBorder
                readOnly
                minimumNights={0}
                numberOfMonths={2}
                isOutsideRange={d => d.isAfter(moment().endOf('day'))}
                initialVisibleMonth={() => moment().subtract(1, 'months')}
                hideKeyboardShortcutsPanel
                disabled={disabled}
                renderMonthElement={this.renderMonthElement}
                renderCalendarInfo={this.renderHours}
                block
                {...validProps}
                focused
            />
        );
    }

    render() {
        const {field, intl, disabled, noHours} = this.props;
        const classes = classnames('date-picker', {field, disabled});
        const startDateDefaultLabel = getIntlMessage('date_startDate', intl)
        const endDateDefaultLabel = getIntlMessage('date_endDate', intl)
        const displayFormat = noHours ? 'DD-MM-YYYY' : 'DD-MM-YYYY HH:mm'
        const startDate = this.props.value?.startDate ? moment(this.props.value?.startDate).format(displayFormat) : startDateDefaultLabel;
        const endDate = this.props.value?.endDate ? moment(this.props.value?.endDate).format(displayFormat) : endDateDefaultLabel;

        return (
            <InputWrapper {...this.props} className={classes} active={this.state.isOpen}>
                <Popover
                    containerClassName='input-wrapper date-picker'
                    isOpen={this.state.isOpen}
                    positions={['bottom', 'center']}
                    padding={10}
                    // reposition={false}
                    onClickOutside={() => this.setState({isOpen: false})}
                    content={({ position, nudgedLeft, nudgedTop }) => this.renderPicker()}
                    >
                    <div className="picker-trigger" onClick={() => this.onOpen()}>
                        <div class="picker-label">{startDate}</div>
                        <svg className="arrow" focusable="false" viewBox="0 0 1000 1000">
                            <path d="M336.2 274.5l-210.1 210h805.4c13 0 23 10 23 23s-10 23-23 23H126.1l210.1 210.1c11 11 11 21 0 32-5 5-10 7-16 7s-11-2-16-7l-249.1-249c-11-11-11-21 0-32l249.1-249.1c21-21.1 53 10.9 32 32z"></path>
                        </svg>
                        <div class="picker-label">{endDate}</div>
                    </div>
                </Popover>
            </InputWrapper>
        );
    }
}

export default connect(state => ({isRTL: I18nSelectors.isRtlSelector(state)}))(injectIntl(DateRangePickerField));
