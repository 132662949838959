import React, {Component} from 'react';
import classnames from 'classnames';

export default class Flex extends Component {
    static defaultProps = {
        component: 'div',
    }
    render = () => {
        const {children, horizontal, stretch, center, centerChildren, inline, justify, alignSelf,
            className, component: FlexComponent,  noWrap, mobileCol, gap, align, style, ...rest} = this.props;
        const classes = {
            'flex--horizontal': horizontal,
            'flex--stretch': stretch,
            'flex--center': center,
            'flex--noWrap': noWrap,
            'flex--inline': inline,
            'flex--mobile-col': mobileCol,
            'flex--centerChildren': centerChildren,
            [`flex--justify-${justify}`]: justify,
            [`flex--align-${align}`]: align,
            'flex--justify-space-between': justify === 'spaceBetween',
            'flex--justify-space-around': justify === 'spaceAround',
            'flex--self-stretch': alignSelf === 'stretch',
        }
        return (
            <FlexComponent {...rest} className={classnames("flex", className, classes)} style={{gap, ...style}}>
                {children}
            </FlexComponent>
        );
    }
}
