import axios from 'services/axios';
import {delay} from 'redux-saga';
import {put, takeLatest, select, call, take} from 'redux-saga/effects';
import {Selectors as CompaniesSelectors} from 'modules/companies';
import {Actions, ActionTypes as CompanyJobsActionTypes, Selectors as JobsSelectors} from 'modules/companyJobs';
import {Selectors as AuthSelectors} from 'modules/auth';
import config from 'config';
import {Selectors as ReferrerSelectors} from 'modules/referrer';
import {Selectors as NavigationSelectors} from 'modules/navigation';
import {createCSV, prepareObjectToCsv} from 'utils/transformUtils';
import {ActionTypes as CreateNotifModalAT} from 'containers/createNotifModal/createNotifModalActions';
import fileSaver from 'file-saver';

export const fetchJobsLogic = async (companyId, filter, sorting, pagination = {}, fetchAll) => {
    const limit = fetchAll ? 9999 : config.jobsPagination;
    const offset = pagination.startAfter || 0;

    const orderBy = `job.${sorting.id}`;
    const order = sorting.desc ? 'desc' : 'asc';

    const filterKeys = ['title', 'internalId', 'innerRecruiter', 'innerRecruiterManager', 'section'];
    const query = filterKeys.reduce((builder, key) => {
        if (filter[key]) {
            builder[key] = {op: 'ilike', value: `%${filter[key]}%`}
        }
        return builder;
    }, {companyId});
    
    if (filter.activeOnly) {
        query.active = true;
    }
    
    // if (pagination.endAt) {
    //     jobsQueryBuilder = jobsQueryBuilder.endAt(pagination.endAt);
    // }
    
    const response = await axios.post(`jobs/query?offset=${offset}&limit=${limit}&orderBy=${orderBy}&order=${order}`, {query})
    const jobs = response.data.items;
    const last = offset + response.data.items.length;
   return {jobs, last};
}

export const fetchPublicJobsLogic = async (companyId, filter, pagination = {}) => {
    const limit = -1;
    const offset = pagination.startAfter || 0;
    const orderBy = `user.${pagination.id}`;
    const order = pagination.desc ? 'desc' : 'asc';

    const query = {};
    if (filter.activeOnly) {
        query.active = true;
    }

    const response = await axios.post(`companies/${companyId}/jobs/query?offset=${offset}&limit=${limit}&orderBy=${orderBy}&order=${order}`, {query})
    const jobs = response.data.items;
    const last = offset + response.data.items.length;
   return {jobs, last};
}

function* requestPush(action) {
    yield put(Actions.JOB_PUSH_REQUEST_REQUEST());
    try {
        const {id} = action.payload;
        yield call(axios.post, `jobs/${id}/push`);
        yield put(Actions.JOB_PUSH_REQUEST_SUCCESS(id));
    } catch (err) {
        console.log(err);
        yield put(Actions.JOB_PUSH_REQUEST_FAILURE(err));
    }
}

function* requestWaSend(action) {
    yield put(Actions.JOB_PUSH_REQUEST_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const query = {companyId, ...action.payload};        
        yield call(axios.post, `companies/whatsapp`, query);
        yield put(Actions.JOB_PUSH_REQUEST_SUCCESS());
    } catch (err) {
        console.log(err);
        yield put(Actions.JOB_PUSH_REQUEST_FAILURE(err));
    }
}

function* fetchCompanyJobs({activeOnly}) {
    try {
        const lastSnap = yield select(JobsSelectors.lastJobSelector);
        const filter = yield select(JobsSelectors.filterSelector);
        const sorting = yield select(JobsSelectors.sortingSelector);
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        
        yield put(Actions.FETCH_COMPANY_JOBS_REQUEST({companyId}));
        const extendedFilter = {activeOnly, ...filter};
        const pagination = {endAt: lastSnap};
        const {jobs, last} = yield fetchJobsLogic(companyId, extendedFilter, sorting, pagination);
        yield put(Actions.FETCH_COMPANY_JOBS_SUCCESS({jobs, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_COMPANY_JOBS_FAILURE(err));
    }
}

function* fetchPublicCompanyJobs() {
    try {
        const lastSnap = yield select(JobsSelectors.lastJobSelector);
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const sorting = yield select(JobsSelectors.sortingSelector);

        yield put(Actions.FETCH_COMPANY_JOBS_REQUEST({companyId}));
        const extendedFilter = {activeOnly: true};
        const pagination = {endAt: lastSnap, ...sorting};
        const {jobs, last} = yield fetchPublicJobsLogic(companyId, extendedFilter, pagination);
        yield put(Actions.FETCH_COMPANY_JOBS_SUCCESS({jobs, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_COMPANY_JOBS_FAILURE(err));
    }
}

function* fetchMoreJobs(action, activeOnly) {
    yield put(Actions.FETCH_MORE_JOBS_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const lastSnap = yield select(JobsSelectors.lastJobSelector);
        const filter = yield select(JobsSelectors.filterSelector);
        const sorting = yield select(JobsSelectors.sortingSelector);
        const extendedFilter = {activeOnly, ...filter};
        const pagination = {startAfter: lastSnap};
        const {jobs, last} = yield fetchJobsLogic(companyId, extendedFilter, sorting, pagination);
        yield put(Actions.FETCH_MORE_JOBS_SUCCESS({jobs, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_MORE_JOBS_FAILURE(err));
    }
}

function* fetchMorePublicCompanyJobs() {
    yield put(Actions.FETCH_MORE_JOBS_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const lastSnap = yield select(JobsSelectors.lastJobSelector);
        const sorting = yield select(JobsSelectors.sortingSelector);
        const filter = {activeOnly: true};
        const pagination = {startAfter: lastSnap, ...sorting};
        const {jobs, last} = yield fetchPublicJobsLogic(companyId, filter, pagination);
        yield put(Actions.FETCH_MORE_JOBS_SUCCESS({jobs, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_MORE_JOBS_FAILURE(err));
    }
}

export const fetchJobsByIdsLogic = async (jobIds) => {
    if (!jobIds || !jobIds.length) {return []}
    const query = {
        id: {op: 'in', value: jobIds}
    }
    const response = await axios.post(`jobs/query?offset=0&limit=${jobIds.length}`, {query})
    const jobs = response.data.items;
    return jobs;
}

export function* fetchJobsByIds(jobIds) {
    yield put(Actions.FETCH_JOBS_BY_IDS_REQUEST());
    try {
        
        const jobs = yield fetchJobsByIdsLogic(jobIds);
        yield put(Actions.FETCH_JOBS_BY_IDS_SUCCESS(jobs));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_JOBS_BY_IDS_FAILURE(err));
    }
}

function* fetchMatchingJob() {
    yield put(Actions.FETCH_MATCHING_JOB_REQUEST());
    try {
        const jobAlias = yield select(JobsSelectors.matchingJobAliasSelector);
        const resp = yield call(axios.get, `jobs/byAlias?alias=${jobAlias}`)

        yield put(Actions.FETCH_MATCHING_JOB_SUCCESS(resp.data));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_MATCHING_JOB_FAILURE(err));
    }
}

function* createNewJob(action) {
    yield put(Actions.CREATE_JOB_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const {sendNotif, ...newJob} = action.payload;
        const result = yield call(axios.post, `jobs`, {...newJob, companyId});
        
        yield put(Actions.CREATE_JOB_SUCCESS(result.data, {sendNotif}));
    } catch (err) {
        console.log(err);
        yield put(Actions.CREATE_JOB_FAILURE(err));
    }
}

function* duplicateJob(action) {
    yield put(Actions.CREATE_JOB_REQUEST());
    try {
        const {jobId, sendNotif, ...newJob} = action.payload;
        const result = yield call(axios.post, `jobs/${jobId}/duplicate`, newJob);
        
        yield put(Actions.CREATE_JOB_SUCCESS(result.data, {sendNotif}));
    } catch (err) {
        console.log(err);
        yield put(Actions.CREATE_JOB_FAILURE(err));
    }
}

function* editJob(action) {
    yield put(Actions.EDIT_JOB_REQUEST());
    try {
        const job = yield select(JobsSelectors.matchingJobSelector);
        const response = yield call(axios.patch, `jobs/${job.id}`, action.payload);
        
        yield put(Actions.EDIT_JOB_SUCCESS(response.data));
    } catch (err) {
        console.log(err);
        yield put(Actions.EDIT_JOB_FAILURE(err));
    }
}

function* logJobView() {
    const isLoggedIn = yield select(AuthSelectors.isLoggedInSelector);
    const isReferrerView = yield select(NavigationSelectors.isReferrerViewSelector);
    if (isLoggedIn || isReferrerView) {
        return;
    }
    yield take(CompanyJobsActionTypes.FETCH_MATCHING_JOB_SUCCESS)
    const beforeJobId = yield select(JobsSelectors.matchingJobIdSelector);
    yield delay(config.viewedJobDelay);
    const afterJobId = yield select(JobsSelectors.matchingJobIdSelector);
    if (beforeJobId !== afterJobId) {
        return;
    }
    const viewedJobs = JSON.parse(localStorage.getItem(config.viewedJobsKey) || '[]');
    const currentTime = Date.now();
    const timeout = config.viewedJobsTimeout;
    const newJobs = viewedJobs.filter(j =>  currentTime - j.time < timeout);
    if (newJobs.find(j => j.id === afterJobId)) {
        return;
    }
    newJobs.push({id: afterJobId, time: currentTime});
    localStorage.setItem(config.viewedJobsKey, JSON.stringify(newJobs));
    const referrerLink = yield select(ReferrerSelectors.matchingReferrerLinkSelector);
    try {
        yield call(axios.post, `jobs/${afterJobId}/view`, {referrerLink});
    } catch (err) {
        console.log(err);
    }
}

function* generateJobsReport() {
    yield put(Actions.GENERATE_JOBS_REPORT_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const filter = yield select(JobsSelectors.filterSelector);
        const sorting = yield select(JobsSelectors.sortingSelector);
        const columns = {
            internalId: 'Internal Id',
            title: 'Job Title',
            active: 'Is Active',
            section: 'section',
            createdAtFormatted: 'Created At',
            applicants: 'Total Applicants',
            views: 'Total Views',
            shares: 'Total Shares',
            url: 'Url Shares',
            fb: 'Facebook Shares',
            ln: 'LinkedIn Shares',
            wa: 'Whatsapp Shares',
            innerRecruiter: 'Recruiter',
            innerRecruiterManager: 'Recruiter Manager',
        };
        const {jobs} = yield fetchJobsLogic(companyId, filter, sorting, {}, true);
        const formattedJobs = jobs.map(prepareObjectToCsv)
        const csv = yield createCSV(formattedJobs, {columns});
        const currentDate = new Date().toJSON().slice(0,10).split('-').reverse().join('/');
        yield put(Actions.GENERATE_JOBS_REPORT_SUCCESS());
        fileSaver.saveAs(new Blob([csv], {type: 'text/csv;charset=utf-8'}), `jobs-${currentDate}.csv`);
    } catch (err) {
        console.error(err);
        yield put(Actions.GENERATE_JOBS_REPORT_FAILURE(err));
    }
}

export default [
    takeLatest([
        CompanyJobsActionTypes.ADMIN_COMPANY_JOBS_MOUNTING,
        CompanyJobsActionTypes.ADMIN_COMPANY_JOBS_COMPANY_CHANGED,
        CompanyJobsActionTypes.FILTER_ADMIN_JOB_LIST,
        CompanyJobsActionTypes.ADMIN_JOB_LIST_SORT_CHANGED,
    ], fetchCompanyJobs),
    takeLatest(CompanyJobsActionTypes.COMPANY_JOBS_MOUNTING, fetchPublicCompanyJobs),
    takeLatest(CompanyJobsActionTypes.PUBLIC_LOAD_MORE_JOBS, fetchMorePublicCompanyJobs),
    takeLatest(CompanyJobsActionTypes.CREATE_JOB_ACTION, createNewJob),
    takeLatest(CompanyJobsActionTypes.ADMIN_DUPLICATE_JOB_ACTION, duplicateJob),
    takeLatest(CompanyJobsActionTypes.EDIT_JOB_ACTION, editJob),
    takeLatest([
        CompanyJobsActionTypes.EDIT_JOB_MOUNTING,
        CreateNotifModalAT.CREATE_NOTIF_MODAL_MOUNT,
    ], fetchMatchingJob, true),
    takeLatest(CompanyJobsActionTypes.PUBLIC_JOB_MOUNTING, fetchMatchingJob, false),
    takeLatest(CompanyJobsActionTypes.LOAD_MORE_JOBS, fetchMoreJobs),
    takeLatest(CompanyJobsActionTypes.PUBLIC_JOB_MOUNTING, logJobView),
    takeLatest(CompanyJobsActionTypes.JOB_PUSH_REQUEST_ACTION, requestPush),
    takeLatest(CompanyJobsActionTypes.JOB_SEND_WA_REQUEST_ACTION, requestWaSend),
    takeLatest(CompanyJobsActionTypes.GENERATE_JOBS_REPORT_ACTION, generateJobsReport),
]