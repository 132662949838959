import {put, select, takeLatest, take} from 'redux-saga/effects';
import {Actions, Selectors} from 'modules/i18n';
import {ActionTypes as CompaniesActionTypes, Selectors as CompanySelectors} from 'modules/companies';
import {ActionTypes as CompanyRouteActionTypes} from 'containers/CompanyRoute/companyRouteActions';
import {Selectors as AuthSelectors} from 'modules/auth';
import config from 'config';


function* handleAdminCompaniesFetched() {
    const limitingCompany = yield select(AuthSelectors.limitedToCompanySelector);
    if (!limitingCompany) {
        const currentLocale = yield select(Selectors.localeSelector);
        if (!currentLocale) {
            yield put(Actions.ADMIN_LOCALE_AUTO_SELECTED(config.defaultLocale));
        }
        return;
    }
    const companies = yield select(CompanySelectors.companiesSelector);
    if (!companies.length) {
        throw new Error('companies not found');
    }
    const {locale} = companies[0];
    yield put(Actions.ADMIN_LOCALE_AUTO_SELECTED(locale));
}

function* handlePublicCompanyMounted() {
    const {payload} = yield take(CompaniesActionTypes.FETCH_MATCHING_COMPANY_SUCCESS);
    yield put(Actions.PUBLIC_LOCALE_AUTO_SELECTED(payload.locale));
}

export default [
    takeLatest(CompaniesActionTypes.FETCH_COMPANIES_SUCCESS, handleAdminCompaniesFetched),
    takeLatest(CompanyRouteActionTypes.PUBLIC_COMPANY_ROUTE_MOUNTING, handlePublicCompanyMounted),
]