import axios from 'services/axios';
import {put, call, takeLatest, select} from 'redux-saga/effects';
import {Actions, ActionTypes as IntegrationsActionTypes} from 'modules/integrations';
import * as CompaniesSelectors from 'modules/companies/companiesSelectors';


function* submitIntegrationForm(action) {
	yield put(Actions.SUBMIT_INTEGRATION_FORM_REQUEST());
	try {
		const {payload} = action;
		const {id: integrationId, ...fields} = payload;
		
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
		const method = integrationId ? axios.patch : axios.post;
		const url = integrationId ? `integrations/${integrationId}` : `integrations`;
		const result = yield call(method, url, {...fields, companyId});

		yield put(Actions.SUBMIT_INTEGRATION_FORM_SUCCESS(result.data));
	} catch (err) {
		yield put(Actions.SUBMIT_INTEGRATION_FORM_FAILURE(err));
	}
}

function* deleteIntegration(action) {
    yield put(Actions.DELETE_INTEGRATION_REQUEST());
    const {id} = action.payload;
    try {
        yield call(axios.delete, `integrations/${id}`);
        yield put(Actions.DELETE_INTEGRATION_SUCCESS(id));
    } catch (err) {
        yield put(Actions.DELETE_INTEGRATION_FAILURE(err));
    }
}


const fetchIntegrationsLogic = async ({companyId}) => {
    const query = {companyId}
    const response = await axios.post(`/integrations/query?orderBy=integration.createdAt&order=desc`, {query});
    return {integrations: response.data.items};
}

function* fetchIntegrations() {
    yield put(Actions.FETCH_INTEGRATIONS_REQUEST());
    try {
        const companyId = yield select(CompaniesSelectors.matchingCompanyIdSelector);
        const {integrations, last} = yield call(fetchIntegrationsLogic, {companyId});
        yield put(Actions.FETCH_INTEGRATIONS_SUCCESS({integrations, last}));
    } catch (err) {
        console.log(err);
        yield put(Actions.FETCH_INTEGRATIONS_FAILURE(err));
    }
}

export default [
    takeLatest([
        IntegrationsActionTypes.INTEGRATIONS_PAGE_MOUNTED,
    ], fetchIntegrations),
    takeLatest(IntegrationsActionTypes.INTEGRATION_FORM_SUBMITTED, submitIntegrationForm),
    takeLatest(IntegrationsActionTypes.DELETE_INTEGRATION_ACTION, deleteIntegration),
]